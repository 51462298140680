<!--<div class='scritta'>Under Construction</div>-->


<div class='game' @fade>

  <div>Solitario della Bastiglia</div>
  <br>
  <div class='punteggio'>Mosse rimaste: {{mosse_rimaste}} - Pedine rimaste: {{pedine_rimaste}}</div>
  <div id='canvas_dama' (click)="onClick($event)" (touchstart)="onClick($event)">

  </div>

  <div class='pulsanti'>

     <div class="butt"><p-button #btb class='p-button-rounded'  (onClick)='nuova_partita()'>Nuova partita</p-button></div>
     <div class="butt"><p-button class='p-button-rounded'  (onClick)='openDialog()'>Istruzioni</p-button></div>

   <div class='check' *ngIf='controlli_audio'>
    <p-checkbox
      (onChange)="music($event)"
      label="Music"
      [binary]="true"
      name="fieldMusic"
      ngDefaultControl
      [(ngModel)]="checked"
      ></p-checkbox>
   </div>

  </div>

</div>


