export const levels = '\
0,0,1,1,1,1,0,0,1,1,1,1,0,0,0,0,0,0,1,4,2,1,3,0,0,0,0,1,0,0,0,0,0,1,1,1,_\
1,0,0,0,0,1,1,3,0,2,0,1,0,0,4,0,0,1,0,3,1,2,0,1,0,0,1,1,1,1,1,1,1,1,1,1,_\
2,0,1,1,1,1,2,0,1,1,1,1,4,3,0,0,1,1,0,3,0,0,1,1,0,0,1,1,1,1,1,1,1,1,1,1,_\
2,0,4,0,0,1,0,0,3,3,0,1,1,1,0,2,1,1,1,1,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,_\
1,1,0,0,1,1,0,0,5,4,1,1,0,0,3,2,1,1,1,1,0,0,0,1,1,1,0,0,0,1,1,1,1,1,1,1,_\
0,0,1,1,1,1,0,4,1,1,1,1,2,3,1,1,1,1,0,0,0,0,1,1,2,3,1,0,1,1,1,0,0,0,1,1,_\
1,0,0,0,0,1,0,0,1,1,0,1,0,3,2,3,2,0,1,0,1,0,0,0,1,0,4,0,1,1,1,1,1,1,1,1,_\
1,1,0,0,1,1,1,1,3,2,4,0,0,0,0,1,0,0,0,0,3,2,0,1,0,0,1,0,0,1,1,1,1,0,0,1,_\
1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,1,2,1,0,0,3,3,2,1,1,0,0,0,4,1,1,1,1,1,1,1,_\
1,0,0,0,1,1,1,3,1,2,1,1,0,0,0,0,1,1,0,3,1,2,0,1,1,0,4,0,0,1,1,1,1,0,0,1,_\
0,0,0,1,0,0,0,0,2,0,0,0,1,3,1,1,0,0,0,4,2,1,0,0,0,3,0,0,0,0,1,1,1,1,0,0,_\
2,2,6,1,1,1,0,3,3,1,1,1,1,3,0,0,1,1,0,0,0,0,1,1,0,0,0,0,1,1,1,1,1,1,1,1,_\
1,0,0,0,1,1,1,0,0,0,1,1,1,1,0,0,1,1,0,3,3,3,0,1,0,2,6,2,0,1,1,1,1,1,1,1,_\
0,0,1,1,1,1,0,0,0,0,1,1,0,3,2,0,1,1,1,0,5,0,1,1,1,3,2,0,1,1,1,0,4,1,1,1,_\
1,0,0,0,0,1,1,0,1,0,0,1,2,5,1,0,0,1,2,0,1,0,1,1,4,3,3,0,0,0,0,0,1,0,0,0,_\
1,0,0,0,0,1,1,0,2,2,2,1,0,3,3,3,0,1,0,4,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,_\
0,2,2,0,0,1,0,2,4,3,0,1,1,0,3,3,0,1,1,1,1,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,_\
0,4,1,1,1,1,0,0,1,1,1,1,3,3,3,0,0,1,2,2,2,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,_\
1,1,0,6,0,1,1,1,0,5,0,1,0,0,3,5,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,_\
0,0,1,1,1,1,0,0,1,1,1,1,0,0,3,0,0,1,1,3,5,2,0,1,0,0,2,0,1,1,0,0,4,0,1,1,_\
0,0,0,0,0,1,2,1,0,1,0,1,0,3,3,2,0,1,2,0,0,1,1,1,0,1,3,1,1,1,0,0,4,1,1,1,_\
1,0,0,0,0,1,1,0,2,2,2,1,0,3,3,0,3,1,4,0,1,0,0,1,0,0,1,0,0,1,1,1,1,1,1,1,_\
1,1,0,0,1,1,2,0,3,0,3,0,4,1,0,0,1,0,0,1,3,2,2,0,0,0,0,1,1,1,1,1,1,1,1,1,_\
1,1,0,0,1,1,1,2,3,0,1,1,0,3,2,4,1,1,0,2,3,0,1,1,0,0,1,1,1,1,0,0,1,1,1,1,_\
0,0,0,1,1,1,0,0,0,1,1,1,1,3,3,0,3,0,1,2,2,0,2,0,1,0,0,1,1,1,1,4,0,1,1,1,_\
1,0,0,1,1,1,0,0,0,1,1,1,0,5,2,4,0,1,1,3,5,0,0,1,0,0,0,1,1,1,0,0,0,1,1,1,_\
1,1,0,0,0,1,1,0,0,1,0,1,1,6,3,5,5,1,0,0,0,1,0,1,0,0,0,0,0,1,1,1,1,1,1,1,_\
1,1,0,0,1,1,0,0,0,0,1,1,0,3,0,3,0,1,0,0,1,3,4,1,2,2,2,0,1,1,1,1,1,1,1,1,_\
1,0,0,1,1,1,1,0,0,0,0,0,1,3,2,2,0,2,0,0,1,1,4,1,0,3,3,0,0,1,0,0,0,0,0,1,_\
0,2,1,1,1,1,0,2,1,1,1,1,3,0,0,3,4,0,0,0,0,3,0,0,2,0,1,1,1,1,1,1,1,1,1,1,_\
1,1,1,1,4,0,1,1,0,0,0,0,0,3,3,1,2,0,2,0,0,0,0,1,0,3,0,1,0,1,1,1,0,0,2,1,_\
0,0,0,1,1,1,0,1,0,1,1,1,0,0,0,1,1,1,5,6,3,5,0,1,0,0,0,0,0,1,1,1,1,0,0,1,_\
1,1,1,0,0,1,0,0,2,3,0,1,0,0,2,3,4,1,1,1,2,3,1,1,1,1,0,0,1,1,1,1,0,0,1,1,_\
0,0,1,1,1,1,0,3,2,0,1,1,0,0,3,2,1,1,1,3,2,0,1,1,1,4,0,1,1,1,1,0,0,1,1,1,_\
1,1,1,0,0,1,0,0,1,0,0,1,0,0,3,3,3,0,4,2,2,0,2,0,1,1,1,0,0,1,1,1,1,1,1,1,_\
0,0,1,1,1,1,0,2,2,0,4,0,0,3,0,1,1,0,1,0,0,1,1,0,1,3,2,0,3,0,1,0,0,1,1,1,_\
1,1,0,0,1,1,1,1,0,3,1,1,0,0,3,2,3,0,0,0,2,4,2,0,1,0,0,0,1,1,1,1,1,1,1,1,_\
0,0,0,0,1,1,0,0,2,0,0,1,1,3,5,3,0,1,1,0,2,4,1,1,1,1,0,0,1,1,1,1,1,1,1,1,_\
1,1,0,2,0,1,1,1,3,2,0,1,0,0,0,2,0,1,0,3,3,0,0,1,1,0,4,1,1,1,1,1,1,1,1,1,_\
0,0,0,0,1,1,4,3,3,0,1,1,1,3,2,2,1,1,1,0,2,0,1,1,1,1,0,0,1,1,1,1,1,1,1,1,_\
0,0,0,0,0,1,0,1,3,2,0,1,0,0,3,2,1,1,1,1,3,2,1,1,1,1,4,0,1,1,1,1,0,0,1,1,_\
1,1,0,4,1,1,0,0,5,0,1,1,0,0,5,2,0,1,0,0,5,3,0,1,0,0,0,1,1,1,1,1,1,1,1,1,_\
1,1,1,0,0,0,1,1,1,4,1,0,0,0,0,0,3,0,0,1,2,2,2,1,0,3,0,1,3,1,1,1,0,0,0,1,_\
1,0,0,1,1,1,0,0,0,1,1,1,0,0,5,3,4,0,0,0,2,5,0,0,1,1,0,0,1,1,1,1,1,1,1,1,_\
1,1,0,0,1,1,1,1,4,0,1,1,1,1,3,5,1,1,0,0,5,2,0,0,0,0,0,0,0,0,0,0,1,1,1,1,_\
0,0,1,1,1,1,0,2,3,2,1,1,0,0,3,0,0,0,1,1,0,0,4,2,1,1,3,0,1,1,1,1,0,0,1,1,_\
1,2,0,0,2,1,0,2,0,1,0,1,0,0,0,3,0,1,0,3,1,3,1,1,1,0,4,0,1,1,1,1,1,1,1,1,_\
0,0,2,0,0,1,0,1,5,1,0,1,5,0,3,0,0,1,0,0,4,0,1,1,0,0,1,1,1,1,1,1,1,1,1,1,_\
2,0,0,1,1,1,2,1,0,1,1,1,0,0,0,3,0,1,0,3,3,2,0,1,0,0,4,1,1,1,1,1,1,1,1,1,_\
0,0,0,1,1,1,0,1,0,1,1,1,0,1,6,0,1,1,0,3,5,3,0,1,0,0,2,0,0,1,1,1,1,0,0,1,_\
1,1,0,0,1,1,1,1,0,2,1,1,1,1,3,0,3,0,0,4,0,3,1,0,0,1,2,2,0,0,0,0,0,1,1,1,_\
1,1,0,0,1,1,0,0,0,5,1,1,0,1,0,2,1,1,0,0,4,3,1,1,1,1,0,5,0,1,1,1,0,0,0,1,_\
1,1,1,0,0,1,0,0,1,3,2,1,0,0,0,3,2,1,0,0,1,3,2,1,1,0,1,0,0,1,1,0,0,4,0,1,_\
1,0,0,1,1,1,1,3,0,0,0,1,1,4,3,2,0,1,1,3,1,2,1,1,0,0,0,2,1,1,0,0,0,0,1,1,_\
1,1,0,0,1,1,1,0,3,0,1,1,0,2,3,2,1,1,0,0,3,2,0,1,1,1,4,0,0,1,1,1,1,0,0,1,_\
1,1,1,1,0,0,1,0,4,0,0,0,0,5,5,5,2,0,0,0,3,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,_\
1,1,0,0,1,1,1,1,2,4,2,0,0,0,3,2,1,0,0,0,0,3,0,0,1,1,1,0,3,1,1,1,1,0,0,1,_\
1,0,2,0,1,1,1,4,0,3,1,1,0,0,5,0,0,0,0,5,0,5,0,0,1,0,0,0,1,1,1,1,1,1,1,1,_\
1,0,0,0,1,1,1,3,1,0,1,1,0,4,0,0,1,1,0,1,0,2,1,1,2,3,3,2,0,1,1,1,0,0,0,1,_\
0,0,0,0,0,1,0,1,0,1,0,1,0,2,0,3,2,0,1,1,3,5,3,0,1,1,0,2,0,1,1,1,1,4,0,1,_\
1,0,0,0,0,1,1,3,1,1,2,1,1,0,1,1,2,1,0,0,0,3,2,0,0,3,0,0,4,0,1,1,0,0,1,1';
