



<div class='game' @fade>

  <div>Sokoban</div>

  <div>Liv. {{currentLevel}}</div>

    <div id='canvas_sokoban' (click)="onClick($event)" (touchstart)="onClick($event)">
</div>

<div class="buttonsBox">

<div>
  Level code
</div>

  <div class="butt">
    <span class="p-float-label">
      <!--<input id="float-input" type="text" (ngModelChange)="controlla(val)" [(ngModel)]="val" pInputText>-->
      <input id="float-input" type="text" [(ngModel)]="val" pInputText>
   </span>
  </div>

    <p-button class='p-button-rounded butt' (click)="caricaLivelloDaCodice()">GO</p-button>

    <p-button class='p-button-rounded butt' (click)="reset()">RESET</p-button>

</div>



