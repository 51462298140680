
<div class="testata">
  <!--<div><p style='font-weight: bold; font-size:large;margin-bottom: 10px;'>{{titolo}}</p></div>-->

  <div>
    <p-splitButton
    #ap
    (onClick)="ap.onDropdownButtonClick($event)"
    [label]="title_menu"
    [model]="button_items"
    styleClass="p-button-raised p-mr-2 p-mb-2"
  ></p-splitButton>
  </div>
</div>


<div style="background-color: azure;">
  <p-chart [type]="typeChart" [data]="dataSet" ></p-chart>
</div>

<div *ngIf="vis_istruzioni" class="istruzioni-legenda">Clicca sulle legende per nascondere o mostrare i grafici - Click on legends for hide or unhide the charts</div>

