import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpService } from '../services/http.service';





interface risparmio {
  settimana: number;
  cifra_1?: number;
  cifra_2?: number;
}


@Component({
  selector: 'app-risparmio',
  templateUrl: './risparmio.component.html',
  styleUrls: ['./risparmio.component.scss']
})


export class RisparmioComponent implements OnInit{

  BUSTE = {
    "chiave1": 49,
    "chiave2": 22,
    "chiave3": 5,
    "chiave4": 18,
    "chiave5": 7,
    "chiave6": 22,
    "chiave7": 10,
    "chiave8": 9,
    "chiave9": 4,
    "chiave10": 47,
    "chiave11": 26,
    "chiave12": 30,
    "chiave13": 35,
    "chiave14": 39,
    "chiave15": 33,
    "chiave16": 38,
    "chiave17": 19,
    "chiave18": 33,
    "chiave19": 19,
    "chiave20": 41,
    "chiave21": 13,
    "chiave22": 49,
    "chiave23": 30,
    "chiave24": 42,
    "chiave25": 20,
    "chiave26": 35,
    "chiave27": 34,
    "chiave28": 14,
    "chiave29": 26,
    "chiave30": 37,
    "chiave31": 16,
    "chiave32": 1,
    "chiave33": 13,
    "chiave34": 44,
    "chiave35": 3,
    "chiave36": 9,
    "chiave37": 3,
    "chiave38": 38,
    "chiave39": 41,
    "chiave40": 49
  }

  datiRisparmio!: Subscription;
  dialog = false;
  titolo='';
  testo_messaggio = ''
  rimanenza = 0;
  somma!:number;

  settimane = 20
  estrazioni_sett = 2;
  showSpinner = false;

  arrayTot:number[] = [];
  busteTot:any;

  arraySett:risparmio[] = [];


  objectWithRandomValues: any = {};

  constructor(public http : HttpService) {}

  ngOnInit(): void {

    // 80 => 3240 - 90 => 4095 - 100 => 5050
    console.log('mesi: ' + (this.settimane / 4) / this.estrazioni_sett);

    this.showSpinner = true;
    this.busteTot = this.BUSTE;

   this.caricaDatiNew();

   //this.initializeObject();
   //this.generateRandomValues();

  /* this.datiRisparmio = this.http.riceviDatoSetting().subscribe((data:any)=>{
      if (data){
       console.log(data);
      }
     },(err:any) => {
      console.log(err);
     console.log("Error occured");
     this.openAlert('Problema di connessione. Ricarica la pagina');
  })
  }*/

}

private initializeObject(): void {
  for (let i = 1; i <= 40; i++) {
    this.objectWithRandomValues[`chiave${i}`] = 0;
  }
}

private generateRandomValues(): void {
  let total = 0;
  while (total < 1500) {
    for (let key in this.objectWithRandomValues) {
      let randomValue = Math.floor(Math.random() * 50) + 1;
      if (total + randomValue <= 1500) {
        this.objectWithRandomValues[key] = randomValue;
        total += randomValue;
      }
    }
  }

  console.log(this.objectWithRandomValues);
}


  openAlert(testo:string, titolo:string='') {
    this.testo_messaggio = testo;
    this.dialog = true;
  }

  /*caricaDati(){
    this.datiRisparmio = this.http.riceviDatoRisparmio().subscribe((data:any)=>{
      if (data){
       console.log(data);
       this.arraySett = data;
       this.arraySett.sort((a, b) => a.settimana - b.settimana);
       this.arrayTot = [];
       this.riempiArraySettimane(data);
       this.showSpinner = false;
      }
    },(err:any) => {
    console.log(err);
    console.log("Error occured");
    this.openAlert('Problema di connessione. Ricarica la pagina');
    })
  }*/

  caricaDatiNew(flag = false){
    this.datiRisparmio = this.http.riceviDatoRisparmio().subscribe((data:any)=>{
      if (data){
       console.log(data);
       this.arraySett = data;
       this.arraySett.sort((a, b) => a.settimana - b.settimana);
       this.riempiArraySettimane(data, flag);
       this.showSpinner = false;
      }
    },(err:any) => {
    console.log(err);
    console.log("Error occured");
    this.openAlert('Problema di connessione. Ricarica la pagina');
    })
  }


  removeFirstValue(obj:any, value:any) {
    const newObj:any = {};
    let removed = false;
    for (const key in obj) {
       if (obj[key] === value && !removed) {
         removed = true;
       } else {
         newObj[key] = obj[key];
       }
    }
    return newObj;
   }


  riempiArraySettimane(arSet:any, flag: boolean){
    this.somma = 0;
    this.rimanenza = 0;

    if (!flag){
      for (let i = 0; i < arSet.length; i++) {
        let cifra_1 = +arSet[i].cifra_1;
        let cifra_2 = +arSet[i].cifra_2;
        const updatedBUSTE = this.removeFirstValue(this.busteTot, cifra_1);
        this.busteTot = this.removeFirstValue(updatedBUSTE, cifra_2)
      }
    } else {

        let cifra_1 = +arSet[arSet.length - 1].cifra_1;
        let cifra_2 = +arSet[arSet.length - 1].cifra_2;
        const updatedBUSTE = this.removeFirstValue(this.busteTot, cifra_1);
        this.busteTot = this.removeFirstValue(updatedBUSTE, cifra_2)
    }

    for (let i = 0; i < arSet.length; i++) {
      let cifra_1 = +arSet[i].cifra_1;
      let cifra_2 = +arSet[i].cifra_2;
      this.somma = this.somma + cifra_1 + cifra_2
    }


    console.log(this.busteTot);
    console.log(Object.keys(this.busteTot).length);
    /*for (let i = 0; i < this.settimane; i++) {
       this.somma += (i+1);
       let found:any;
       if (arSet.length > 0){
         found = arSet.find((x:risparmio) => {
          return x.cifra_1 === (i+1) || x.cifra_2 === (i+1);
         })
       }
       if (!found){
        this.rimanenza += (i+1);
        this.arrayTot.push(i+1);
       }
    }*/
    //10console.log(this.arrayTot);
    console.log(this.somma);
    console.log(this.rimanenza);
  }

  getBusteTot(): number{
    return Object.keys(this.busteTot).length;
  }

  aggiungiNew(){
        // trovo in numero attuale delle buste
    let settimana: number;
    // prendo il numero dell'ultima settimana
    const lastweeknumber = this.arraySett[this.arraySett?.length - 1]?.settimana;
    if (lastweeknumber){
       settimana = lastweeknumber + 1;
    } else {
      settimana = 1;
    }

    let valore_busta_1!: number;
    let valore_busta_2!: number;

    const chiavi = Object.keys(this.busteTot);
    const index1 = Math.floor(Math.random() * chiavi.length);
    let chiave1 = chiavi[index1];
    valore_busta_1 = this.busteTot[chiave1];
    //delete this.busteTot[chiave1];

    const index2 = Math.floor(Math.random() * (chiavi.length - 1));
    let chiave2 = chiavi.filter((chiave, index) => index !== index1)[index2];
    valore_busta_2 = this.busteTot[chiave2];
    //delete this.busteTot[chiave2];

    this.http.aggiungiDatoRisparmio(valore_busta_1, valore_busta_2, settimana);
    this.showSpinner = true;
    this.caricaDatiNew(true);

  }


 /* aggiungi(){
    // trovo in numero attuale delle buste
    let settimana: number;
    // prendo il numero dell'ultima settimana
    const lastweeknumber = this.arraySett[this.arraySett.length - 1]?.settimana;
    if (lastweeknumber){
       settimana = lastweeknumber + 1;
    } else {
      settimana = 1;
    }

    let valore_busta_1!: number;
    let valore_busta_2!: number;

    if (this.arrayTot.length > 2){
      let dimArray = this.arrayTot.length;
      const primaBusta = Math.floor(Math.random() * ((dimArray-1))) + 1;
      valore_busta_1 = this.arrayTot[primaBusta];
      let index = this.arrayTot.indexOf(valore_busta_1);
      this.arrayTot.splice(index, 1);
      dimArray = this.arrayTot.length;
      const secondaBusta = Math.floor(Math.random() * ((dimArray-1))) + 1;
      valore_busta_2 = this.arrayTot[secondaBusta]
      index = this.arrayTot.indexOf(valore_busta_2);
      this.arrayTot.splice(index, 1);
    } else {
      valore_busta_1 = this.arrayTot[0];
      valore_busta_2 = this.arrayTot[1];
      this.arrayTot.splice(0, 2);
    }

    this.http.aggiungiDatoRisparmio(valore_busta_1, valore_busta_2, settimana);
    this.datiRisparmio.unsubscribe();
    this.showSpinner = true;
    this.caricaDati();
  }*/

}
