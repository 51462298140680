import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CovidSitComponent } from './covid-sit/covid-sit.component';
import { DropletgameComponent } from './dropletgame/dropletgame.component';
import { GalleryComponent } from './gallery/gallery.component';
import { Gallery3dComponent } from './gallery3d/gallery3d.component';
import { HanoitowerComponent } from './hanoitower/hanoitower.component';
import { NewsComponent } from './news/news.component';
import { RisparmioComponent } from './risparmio/risparmio.component';
import { SokobanComponent } from './sokoban/sokoban.component';
import { SolitarioComponent } from './solitario/solitario.component';
import { StazioneMeteoComponent } from './stazione-meteo/stazione-meteo.component';
import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
  {path: '', component: WelcomeComponent},
  {path: 'image_gallery', component: GalleryComponent },
  {path: 'gallery_3d', component: Gallery3dComponent },
  {path: 'meteo', component: StazioneMeteoComponent },
  {path: 'covid', component: CovidSitComponent },
  {path: 'news', component: NewsComponent},
  {path: 'torrehanoi', component:HanoitowerComponent},
  {path: 'dropletgame', component: DropletgameComponent},
  {path: 'dama_cinese', component: SolitarioComponent},
  {path: 'piano_di_risparmio', component: RisparmioComponent},
  {path: 'sokoban', component: SokobanComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
