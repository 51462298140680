import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  animations: [
    // animation triggers go here
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({opacity: 1}))
      ])
    ])
  ]
})
export class GalleryComponent implements OnInit {

  imageObject: Array<object> = [];

  constructor() { }

  ngOnInit(): void {

    this.imageObject = [
       {
        image: 'assets/img/anello.jpg',
        thumbImage: 'assets/img/anello.jpg',
        title: 'Render anello'
      },
      {
        image: 'assets/img/espositore.jpg',
        thumbImage: 'assets/img/espositore.jpg',
        title: 'Render expo da banco'
      },
      {
        image: 'assets/img/render_test.jpg',
        thumbImage: 'assets/img/render_test.jpg',
        title: 'Render expo da banco con prodotti'
      },
      {
        image: 'assets/img/expo-render.jpg',
        thumbImage: 'assets/img/expo-render.jpg',
        title: 'Render expo cosmetica'
      },
      {
        image: 'assets/img/expo-cosm.jpg',
        thumbImage: 'assets/img/expo-cosm.jpg',
        title: 'Render expo cosmetica banco'
      },
      {
        image: 'assets/img/ambiente-2.jpg',
        thumbImage: 'assets/img/ambiente-2.jpg',
        title: 'Render interni-1'
      },
      {
        image: 'assets/img/ambiente-3.jpg',
        thumbImage: 'assets/img/ambiente-3.jpg',
        title: 'Render interni-2'
      },
      {
        image: 'assets/img/ambiente-4.jpg',
        thumbImage: 'assets/img/ambiente-4.jpg',
        title: 'Render interni-3'
      },
      {
        video: 'assets/video/video.mp4', // MP4 Video url
        title: 'Simulazione fisica 3D'
      }
    ]
  }


}
