import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { Globals } from 'src/app/globals';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  trigger,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { CovidChartComponent } from '../covid-chart/covid-chart.component';
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'app-covid-sit',
  templateUrl: './covid-sit.component.html',
  styleUrls: ['./covid-sit.component.scss'],
  animations: [
    // animation triggers go here
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({opacity: 1}))
      ])
    ])
  ]
})

export class CovidSitComponent implements OnInit, OnDestroy {

  public richiestaDati:any;
  public casiTotali: number = 0;
  public data: any;
  public dataIeri: any;
  public totalePositivi: number = 0;
  public differenzaPositivi: string = "";
  public differenza_nc: string = "";
  public terapiaIntensiva: number = 0;
  public differenza_terapiaIntensiva: string = "";
  public guariti: number = 0;
  public differenza_guariti:  string = "";
  public morti: number = 0;
  public differenza_morti:  string = "";
  public nuoviPositivi: number = 0;
  public isolamento_domestico: number = 0;
  public differenza_id:  string = "";
  public tamponi: number = 0;
  public differenza_tamponi:  string = "";
  public osped_con_sintomi: number = 0;
  public differenza_ocs:  string = "";
  public differrenza_ct:  string = "";
  public totale_osp: number = 0;
  public differenza_to:  string = "";
  public giorno:Date = new Date();
  public data_filtered:any[] = []

  public dataSource_italia:any[] = [];

  // per grafico
  public dataSet_nuovipositivi:any[] = [];
  public dataSet_deceduti:any[] = [];
  public dataSet_terapiaintensiva:any[] = [];
  public dataSet_totalepositivi:any[] = [];
  public dataset_tamponi:any[] = [];
  public dataset_decessi_giorn:any[] = [];
  public dataset_terapia_giorn:any[] = [];
  public dataset_ospconsintomi:any[] = [];
  public dataset_guariti:any[] = [];
  public dataset_isolamentodomestico:any[] = [];
  public dataset_totaleosp:any[] = [];
  public dataSet_data:any[] = [];

  dialog = false;
  titolo='';
  testo_messaggio = ''


  ref!: DynamicDialogRef;

  constructor(public global:Globals, public http: HttpService, public dialogService: DialogService) { }

  ngOnInit(): void {
    this.riceviDati2();
   //console.log('Dati covid');
   this.global.hide_covid = false;
  }

  openAlert(testo:string, titolo:string='') {
    this.testo_messaggio = testo;
    this.dialog = true
  }

  openGrafico(nome: string){

    var th = this;
    var dati:any[] = [];
    var colore : any;

    switch(nome) {
      case 'Nuovi positivi':
          dati = th.dataSet_nuovipositivi;
          colore = 'red';
        break;
      case 'Deceduti':
          dati = th.dataSet_deceduti;
          colore = 'rgba(150, 148, 141, 0.5)';
        break;
      case 'Terapia intensiva':
          dati = th.dataSet_terapiaintensiva;
          colore = 'rgba(240, 240, 19, 0.5)';
        break;
      case 'Totale positivi':
          dati = th.dataSet_totalepositivi;
          colore = 'orange';
        break;
      case 'Tamponi':
          dati = th.dataSet_nuovipositivi;
          colore = 'red';
       break;
      case 'Ospedalizzati con sintomi':
          dati = th.dataset_ospconsintomi;
          colore = 'cyan';
       break;
       case 'Guariti':
          dati = th.dataset_guariti;
          colore = 'green';
       break;
       case 'Isolamento domestico':
          dati = th.dataset_isolamentodomestico;
          colore = 'brown';
       break;
       case 'Totale ospedalizzati':
          dati = th.dataset_totaleosp;
          colore = 'blue';
       break;
      default:
        // code block
    }


    var dialogConfig = {
      width: '90%',
      header: nome,
      data: {
        dato: nome,
        chartData: dati,
        chartLabels: th.dataSet_data,
        tamponi: th.dataset_tamponi,
        dec_giorn: th.dataset_decessi_giorn,
        ter_giorn: th.dataset_terapia_giorn,
        chartColor: colore
      }
    }

    this.ref = this.dialogService.open(CovidChartComponent , dialogConfig);

  }

  riceviDati2(){
    var th = this;
    this.richiestaDati = this.http.getData2('https://raw.githubusercontent.com/pcm-dpc/COVID-19/master/dati-json/dpc-covid19-ita-andamento-nazionale.json').subscribe(  data => {

        // console.log(data);
        //console.log(data[data.length - 2]);
        //console.log(data[data.length - 1]);
        //console.log(data);

          //
         var porzione = data.slice((data.length - 365),data.length); // gli ultimi 100 giorni
        // var porzione = data;
        var tamp_tot:any[] = [];
        var data_precedente = ""

          porzione.forEach(function(nuovi: any) {

            if (nuovi.data != data_precedente){

              var myDate = new Date(nuovi.data);
              var data_ins = myDate.getDate() + '/' + (myDate.getMonth() + 1);
              //th.dataSet_data.push(myDate.getDate() + '/' + (myDate.getMonth() + 1));

              if (th.dataSet_data.indexOf(data_ins) === -1) {
              //console.log(nuovi.nuovi_positivi);
                th.dataSet_data.push(data_ins);
                th.dataSet_nuovipositivi.push(nuovi.nuovi_positivi);
                th.dataSet_deceduti.push(nuovi.deceduti);
                th.dataSet_terapiaintensiva.push(nuovi.terapia_intensiva);
                th.dataSet_totalepositivi.push(nuovi.totale_positivi);
                th.dataset_ospconsintomi.push(nuovi.ricoverati_con_sintomi);
                th.dataset_guariti.push(nuovi.dimessi_guariti);
                th.dataset_isolamentodomestico.push(nuovi.isolamento_domiciliare);
                th.dataset_totaleosp.push(nuovi.totale_ospedalizzati);
                tamp_tot.push(nuovi.tamponi);
              }
              th.data_filtered.push(nuovi);
              data_precedente = nuovi.data
            }
           });

           //console.log(this.dataSet_data);

           // preparo i tamponi
           tamp_tot.forEach(function(numero, indice) {
             if (indice == 0){
               th.dataset_tamponi.push(numero);
             } else {
               var tamp_rel = numero - tamp_tot[indice - 1];
               th.dataset_tamponi.push(tamp_rel);
             }
           });

            //preparo i decessi
            th.dataSet_deceduti.forEach(function(numero, indice) {
              if (indice == 0){
                th.dataset_decessi_giorn.push(numero);
              } else {
                var tamp_rel = numero - th.dataSet_deceduti[indice - 1];
                th.dataset_decessi_giorn.push(tamp_rel);
              }
            });

            //preparo le terapie intensive
            th.dataSet_terapiaintensiva.forEach(function(numero, indice) {
              if (indice == 0){
                th.dataset_terapia_giorn.push(numero);
              } else {
                var terap_rel = numero - th.dataSet_terapiaintensiva[indice - 1];
                th.dataset_terapia_giorn.push(terap_rel);
              }
            });

           //console.log(th.dataset_tamponi);

          //

         // this.data = data[data.length - 1];
          this.data = this.data_filtered[this.data_filtered.length - 1];
          this.giorno = this.data_filtered[this.data_filtered.length - 1]['data'];
          this.dataIeri = this.data_filtered[this.data_filtered.length - 2];

          // totale positivi ===
          this.totalePositivi = this.data['totale_positivi'];
          var totalePositivi_ieri = this.dataIeri['totale_positivi'];
          this.differenzaPositivi = this.calcolaDifferenza(totalePositivi_ieri, this.totalePositivi);
          // =================

          // Nuovi positivi ======
          this.nuoviPositivi = this.data['nuovi_positivi'];
          var positivi_ieri = this.dataIeri['nuovi_positivi'];
          this.differenza_nc = this.calcolaDifferenza(positivi_ieri, this.nuoviPositivi);
        // =====================

        // Terapia intansiva =====
        this.terapiaIntensiva = this.data['terapia_intensiva'];
        var terapiaIntensiva_ieri = this.dataIeri['terapia_intensiva'];
        this.differenza_terapiaIntensiva = this.calcolaDifferenza(terapiaIntensiva_ieri, this.terapiaIntensiva);
        //====================

        // Guariti =========================
        this.guariti = this.data['dimessi_guariti'];
        var guariti_ieri = this.dataIeri['dimessi_guariti'];
        this.differenza_guariti = this.calcolaDifferenza(guariti_ieri, this.guariti);
        //==================================

        // Deceduti ========================
        this.morti = this.data['deceduti'];
        var morti_ieri = this.dataIeri['deceduti'];
        this.differenza_morti = this.calcolaDifferenza(morti_ieri, this.morti);
        //=============

        // Isolamento domestico =======
        this.isolamento_domestico = this.data['isolamento_domiciliare'];
        var isolamento_ieri = this.dataIeri['isolamento_domiciliare'];
        this.differenza_id = this.calcolaDifferenza(isolamento_ieri, this.isolamento_domestico);
        // ============================

        // Tamponi ====================
        this.tamponi = this.data['tamponi'];
        var tamponi_ieri = this.dataIeri['tamponi'];
        this.differenza_tamponi = this.calcolaDifferenza(tamponi_ieri, this.tamponi);
        //=============================

        // Ospidalizzati con sintomi
        this.osped_con_sintomi = this.data['ricoverati_con_sintomi'];
        var ocs_ieri = this.dataIeri['ricoverati_con_sintomi'];
        this.differenza_ocs = this.calcolaDifferenza(ocs_ieri, this.osped_con_sintomi);
       // =================

       // Totale ospedalizzati
       this.totale_osp = this.data['totale_ospedalizzati'];
       var tocs_ieri = this.dataIeri['totale_ospedalizzati'];
       this.differenza_to = this.calcolaDifferenza(tocs_ieri, this.totale_osp);
      // =================

       // Casi totali ==========
       this.casiTotali = this.data['totale_casi'];
       var casiTotali_ieri = this.dataIeri['totale_casi'];
       this.differrenza_ct = this.calcolaDifferenza(casiTotali_ieri, this.casiTotali);

      // ===================

          // Preparo data source per material table

         /* var icona = 'thumb_up'
          var tclasse = 'false';*/
          this.dataSource_italia.push({ graph: true, name: 'Tamponi', numero: this.tamponi, differenza: this.differenza_tamponi, stato: '', classe: ''});

          this.dataSource_italia.push( this.preparoArray(true,'Nuovi positivi', this.nuoviPositivi, this.differenza_nc, '+') );
          this.dataSource_italia.push( this.preparoArray(true,'Totale positivi', this.totalePositivi, this.differenzaPositivi, '+') );
          this.dataSource_italia.push( this.preparoArray(true,'Terapia intensiva', this.terapiaIntensiva, this.differenza_terapiaIntensiva, '+') );
          this.dataSource_italia.push( this.preparoArray(true,'Ospedalizzati con sintomi', this.osped_con_sintomi, this.differenza_ocs, '+') );
          this.dataSource_italia.push( this.preparoArray(true,'Totale ospedalizzati', this.totale_osp, this.differenza_to, '+') );
          this.dataSource_italia.push( this.preparoArray(true,'Isolamento domestico', this.isolamento_domestico, this.differenza_id, '+') );
          this.dataSource_italia.push( this.preparoArray(true,'Guariti', this.guariti, this.differenza_guariti, '-') );
          this.dataSource_italia.push( this.preparoArray(true,'Deceduti', this.morti, this.differenza_morti, '+') );

          //console.log(this.dataSource_italia);
          //this.router.navigate(['covid']);
           //window.location.reload();
           this.global.datasource = this.dataSource_italia;
           this.global.hide_covid = true;

          // ======================================


    }, err => {
      console.log(err);
      console.log("Error occured");
      this.openAlert('Problema di ricezione dati dal server. Ricarica la pagina');
       }
    );

  }

  cercavalore(stringa:string, valore:string){
    if (stringa.includes(valore)){
      return true;
    } else {
      return false;
    }
  }

  calcolaDifferenza(vecchio:number, nuovo:number){
    var valore_ritorno:string;
    var differenza = nuovo - vecchio;
    if (differenza < 1){
      valore_ritorno = differenza.toString();
     } else {
       valore_ritorno = "+" + differenza;
    }
    return valore_ritorno;
  }

  preparoArray( graph:boolean, nome:string, numero:number, differenza:string, ricerca:string){

    var numero_str = "" + numero;
    var jsonRitorno = {};
    var icona = 'pi pi-thumbs-up'
    var tclasse = 'true';
    if( this.cercavalore(differenza, ricerca) ){
     var icona = 'pi pi-thumbs-down'
     var tclasse = 'false';
    }
    jsonRitorno = { graph: graph, name: nome, numero: numero_str, differenza: differenza, stato: icona,classe: tclasse };

    return jsonRitorno;
  }

  ngOnDestroy() {
    this.ref?.destroy;
    this.richiestaDati.unsubscribe();
  }



}
