import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FondoComponent } from './fondo/fondo.component';
import { TestataComponent } from './testata/testata.component';
import {MenubarModule} from 'primeng/menubar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GalleryComponent } from './gallery/gallery.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Gallery3dComponent } from './gallery3d/gallery3d.component';
import { ButtonModule } from 'primeng/button';
import { StazioneMeteoComponent } from './stazione-meteo/stazione-meteo.component';
import { CompassGaugeComponent } from './stazione-meteo/compass-gauge/compass-gauge.component';
import { DialogModule } from 'primeng/dialog';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CovidSitComponent } from './covid-sit/covid-sit.component';
import { CovidChartComponent } from './covid-chart/covid-chart.component';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { CustomDatePipe } from './custom.datepipe';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';

// lingua italiana data (custom.datepipe) =============================
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { NewsComponent } from './news/news.component';
import { FilterNewsPipe } from './filter_news_pipe';
import { NomeVincitoreComponent } from './nome-vincitore/nome-vincitore.component';
import { IstruzionigamesComponent } from './istruzionigames/istruzionigames.component';
import { HanoitowerComponent } from './hanoitower/hanoitower.component';
import { ClassificagiochiComponent } from './classificagiochi/classificagiochi.component';
import { DropletgameComponent } from './dropletgame/dropletgame.component';
import { SolitarioComponent } from './solitario/solitario.component';
import { RisparmioComponent } from './risparmio/risparmio.component';
import { SokobanComponent } from './sokoban/sokoban.component';
import { SokoModalComponent } from './soko-modal/soko-modal.component';
// ====================================================


registerLocaleData(localeIt);


@NgModule({
  declarations: [
    CustomDatePipe,
    AppComponent,
    WelcomeComponent,
    FondoComponent,
    TestataComponent,
    GalleryComponent,
    Gallery3dComponent,
    StazioneMeteoComponent,
    CompassGaugeComponent,
    CovidSitComponent,
    CovidChartComponent,
    NewsComponent,
    FilterNewsPipe,
    NomeVincitoreComponent,
    IstruzionigamesComponent,
    HanoitowerComponent,
    ClassificagiochiComponent,
    DropletgameComponent,
    SolitarioComponent,
    RisparmioComponent,
    SokobanComponent,
    SokoModalComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MenubarModule,
    FormsModule,
    ReactiveFormsModule,
    HammerModule,
    NgImageSliderModule,
    ProgressSpinnerModule,
    ButtonModule,
    DialogModule,
    HttpClientModule,
    DropdownModule,
    DynamicDialogModule,
    TableModule,
    ChartModule,
    MenuModule,
    SplitButtonModule,
    RadioButtonModule,
    DynamicDialogModule,
    InputTextareaModule,
    InputTextModule,
    CheckboxModule
  ],
  providers: [DialogService,{provide: LOCALE_ID, useValue: 'it'}],
  bootstrap: [AppComponent],
  entryComponents:[
    CovidChartComponent,
    NomeVincitoreComponent,
    IstruzionigamesComponent,
    ClassificagiochiComponent,
    SokoModalComponent
  ]
})
export class AppModule { }
