import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as levels from './levels';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader.js';


const AudioContextClass = (window.AudioContext || (window as any).webkitAudioContext);

interface Node {
  row: number;
  col: number;
}

import {
  trigger,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { Vector } from 'three';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SokoModalComponent } from '../soko-modal/soko-modal.component';

interface levelInterface {
  player_start: Array<number[]>;
  diamonds: Array<number[]>;
  crates: Array<number[]>;
  arrayLevel: Array<string[]>
  walls: Array<number[]>
}

@Component({
  selector: 'app-sokoban',
  templateUrl: './sokoban.component.html',
  styleUrls: ['./sokoban.component.scss'],
  animations: [
    // animation triggers go here
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({opacity: 1}))
      ])
    ])
  ]
})

export class SokobanComponent implements OnInit, OnDestroy{


  animationId: number | null = null;
  arrayLevel: Array<string[]> = [];
  public canvas:any;
  public loader = new OBJLoader;
  public renderer:any;
  public root:any;
  public scene:any;
  public camera:any;
  public oldCH:number =0;
  public oldCW:number =0;
  public controls:any;
  public raycaster = new THREE.Raycaster();
  public mouse = new THREE.Vector2(0,0);
  public mtlLoader:any;
  public level!: levelInterface;
   public oggetto3D_intercettato:any
  public oggetto_intercettato = '';
  public procedi_con_animazione = false;
  public vittoria = false
  public emissive_pav:any;
  public val = '';
  public ref: DynamicDialogRef | null = null;
  public currentLevel:number = 0;
  public levelcompleteSound:any;
  public sokoCratesSound:any;
  public audioContext: AudioContext = new AudioContextClass();
  public cratesBuffer: AudioBuffer | null = null;
  public levelCompleteBuffer: AudioBuffer | null = null;

  constructor(
    private cd:ChangeDetectorRef,
    public dialogService: DialogService
  ) { }


  levelCodex = [
    'XvBF', '6jvU', '2ffz', 'uhxL', 'yfU1', '1Tgg', 'xFpV', 'ZdnY', 'PVQL', 'tLsa',
    'RWmS', 'KzpF', 'sS1r', 'RHtn', 'vugT', '8ymk', 'ra4t', '2MrB', 'nDpa', '0YhF',
    'fXxY', 'XF3B', 'R6Jk', 'uHZO', 'aBZ6', '6dLN', 'p1k2', 'f0ME', '2RhJ', 'xrUM',
    '5MjC', '7Fd8', 'Uo2X', 'HE3i', 'uo5m', 'ofnp', 'hVuD', 'mmC7', 'cLUW', 'Zg4o',
    'dYFa', 'o4xb', 'VNcj', 'qxR8', 'Rv5E', 'Hca8', 'Paoh', 'NUQe', 'Z83P', 'QHrK',
    'Zwel', 'CsNx', 'XXCU', 'hGba', 'FsOs', 'LDoh', 'cdF5', 'oGPA', 'hDVx', '3g3n','6hTy'
   ] // 0-60

  procedi_animazione = false;
  percorso = [];

  // Definizione della mappa dei box
boxPositionMap: { [key: string]: [number, number] } = {};

  // Variabili per gestire l'animazione
    animationIndex = 0;
    animationStep = 15; // velocità di spostamento
    targetPosition: THREE.Vector3 | null = null;
    currentPath: number[][] = [];

  ngOnInit(): void {
    this.canvas = document.getElementById('canvas_sokoban');

    /*this.levelcompleteSound = new Audio();
    this.levelcompleteSound.src = '../assets/sound/soko-levelcompleted.mp3';
    this.levelcompleteSound.preload = 'auto';
    this.levelcompleteSound.load();

    this.sokoCratesSound = new Audio();
    this.sokoCratesSound.src = '../assets/sound/soko-crates.mp3';
    this.sokoCratesSound.preload = 'auto';
    this.sokoCratesSound.load();*/

    // Carica i suoni
  this.loadSound('../assets/sound/soko-crates.mp3').then(buffer => this.cratesBuffer = buffer);
  this.loadSound('../assets/sound/soko-levelcompleted.mp3').then(buffer => this.levelCompleteBuffer = buffer);

    this.initPartita(0); // 0 -> 60
  }

  // Funzione per caricare i file audio
loadSound(url: string): Promise<AudioBuffer>{
  return fetch(url)
    .then(response => response.arrayBuffer())
    .then(arrayBuffer => this.audioContext.decodeAudioData(arrayBuffer));
}

// Funzione per riprodurre il suono
playSound(buffer: AudioBuffer | null) {
  if (buffer && this.audioContext) {
    const source = this.audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(this.audioContext.destination);
    source.start(0);
  }
}

  caricaLivelloDaCodice(){
     const level = this.findIndexOfCode(this.levelCodex, this.val);
     if (level !== -1){
      //console.log(level+1);
      this.initPartita(level);
      this.currentLevel = level;
     } else {
      //console.log('level not found');
     }
  }

  initPartita(level: number){
    //this.canvas = document.getElementById('canvas_sokoban');
    this.level =  this.prepareLevel(level);
    this.carica_partita();
    //console.log(this.level);
  }

  findIndexOfCode(codeArray: string[], targetCode: string): number {
    const index = codeArray.indexOf(targetCode);
    return index !== -1 ? index : -1;
  }

  /* prepara il livello levelN
      5 = cassa su diamante
      4 = player
      2 = diamond
      3 = cassa
      1 = muro
  */

  prepareLevel(levelN: number){
    this.arrayLevel = [];
     let array_global = levels.levels.split(',_');
     let level_array = array_global[levelN].split(',');
     //console.log(level_array);
     let init = 0
     let end = 6
     let line:string[];
     let pos_player:Array<number[]>;
     let pos_crates:Array<number[]>;
     let pos_diamonds:Array<number[]>;
     let walls_pos: Array<number[]>;

     pos_player = this.findElementsStart(level_array, '4');
     pos_diamonds = this.findElementsStart(level_array, ['2', '5']);
     pos_crates = this.findElementsStart(level_array, ['3', '5']);
     walls_pos = this.findElementsStart(level_array, '1');

     this.replaceFiveWithThree(level_array)

     do{
      line = level_array.slice(init,end);
      this.arrayLevel.push(line);
      init = init + 6;
      end = end + 6;
     }  while(end < 42);

     return {
       player_start: pos_player,
       walls: walls_pos,
       diamonds: pos_diamonds,
       crates: pos_crates,
       arrayLevel: this.arrayLevel
     }
  }

  replaceFiveWithThree(array: string[]): string[] {
    for (let i = 0; i < array.length; i++) {
      if (array[i] === '5') {
        array[i] = '3'; // Sostituisci '5' con '3'
      }
    }
    return array;
  }

  findElementsStart(array: string[], element: string | string[]): Array<number[]> {
    let elements: Array<number[]> = [];
    let row = 0;
    let col = 0;

    for (let i = 0; i < array.length; i++) {
      // Controlla se element è un array di stringhe o una singola stringa
      if (Array.isArray(element)) {
        // Se è un array, controlla se l'elemento corrente è incluso in `element`
        if (element.includes(array[i])) {
          elements.push([row, col]);
        }
      } else {
        // Se è una stringa, confronta direttamente
        if (array[i] === element) {
          elements.push([row, col]);
        }
      }

      col++;
      if (col > 5) {
        col = 0;
        row++;
      }
    }

    return elements;
  }

  reset(){
    this.initPartita(this.currentLevel);
  }

  openModal() {
    this.ref = this.dialogService.open(SokoModalComponent, {
      header: 'VITTORIA!!!!!!',
      width: '50%',
      contentStyle: {"max-height": "500px", "overflow": "auto"},
      closable: false,
      baseZIndex: 10000,
      data: {
        message: 'HAI PASSATO IL LIVELLO!',
        codeLevel: this.levelCodex[this.currentLevel + 1],
        currentLevel: this.currentLevel
      }
    });

    // Ascolta la chiusura della modale
    this.ref.onClose.subscribe((result) => {
      if (this.currentLevel < 60){
        this.initPartita(this.currentLevel + 1);
        this.currentLevel = this.currentLevel+1;
      } else {
        this.initPartita(0);
        this.currentLevel = 0
      }

    });
  }


  carica_partita():void{
    if (this.canvas != null){
      this.canvas.hidden = true;
    }

    this.rimuovi_contenuto_precedente();

    if (this.canvas != null){
      this.canvas.hidden = false;
    }

    if (this.renderer){
      this.renderer.forceContextLoss();
    }

    this.loader = new OBJLoader();
    this.mtlLoader = new MTLLoader();
    this.renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true } );
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.scene = new THREE.Scene();
    //this.bgScene = new THREE.Scene();
    //this.prepara_sfondo();
    this.loadObject('sokoban_2', 2, true, 2, 3)
  }



  posiziono_oggetto(nomeOggetto: string, nome_pezzo_clonato: string, nomeDestinazione: string, add_material = false, finale = true, up = 0){

    const th = this;
    const matrice = th.scene.getObjectByName( nomeOggetto );
   // matrice.position.set({x:0, y:0, z:0});
    let pezzo_da_spostare = matrice.clone(false);
    pezzo_da_spostare.name = nome_pezzo_clonato;

    if (add_material){
      const phongMaterial = new THREE.MeshPhongMaterial;
      phongMaterial.name = 'mat_' + nome_pezzo_clonato;
      phongMaterial.color = pezzo_da_spostare.material.color;
      pezzo_da_spostare.material = phongMaterial;
    }

    th.scene.add(pezzo_da_spostare);

    const da_spostare = this.scene.getObjectByName( nome_pezzo_clonato );
    const destinazione = this.scene.getObjectByName( nomeDestinazione  );
    const vettore1 = new THREE.Vector3();
    const vettore2 = new THREE.Vector3();

    destinazione.geometry.computeBoundingBox();
    destinazione.geometry.boundingBox.getCenter(vettore1);
    da_spostare.geometry.computeBoundingBox();
    da_spostare.geometry.boundingBox.getCenter(vettore2);

   da_spostare.position.copy(vettore1);
   da_spostare.position.y = da_spostare.position.y + up;
   //da_spostare.castShadow = true;
   //da_spostare.receiveShadow = true;
   // in caso di oggetti multipli devo aspettare che li cloni tutti

   if (nome_pezzo_clonato.includes('box_')){
    const destination = nomeDestinazione.split('_'); // es. 'base_1_1' -> ['base', '1', '1']
    const r = parseInt(destination[1]);
    const c = parseInt(destination[2]);
    this.boxPositionMap[nome_pezzo_clonato] = [r, c];
   }

   if (finale){
    matrice.position.y = matrice.position.y + 20000; // sposta il pezzo originale fuori scena
    //console.log(this.boxPositionMap);
   }

   // console.log(vettore1);
   // console.log(vettore2);

   // console.log(this.scene.getObjectByName( nome_pezzo_clonato ));
  }

  degrees_to_radians(degrees: number)
      {
        var pi = Math.PI;
        return degrees * (pi/180);
      }

  posiziona_oggetti_in_campo(){

     // player
      const pos_giocatore = this.level.player_start[0];
      const dest_name = 'base_' + pos_giocatore[0] + "_" + pos_giocatore[1];
      this.posiziono_oggetto('robot', 'robo_pl', dest_name, false, true, 100);
      const player = this.scene.getObjectByName( 'robo_pl' );
      // -90 destra - 90 sinistra
      player.rotateY(this.degrees_to_radians(0));

      // muri
      let i = 0
      this.level.walls.forEach(element => {
        const dest_name = 'base_' + element[0] + "_" + element[1];
        if (i < this.level.walls.length - 1){
          this.posiziono_oggetto('wall_m', 'wall_' + i, dest_name, false, false, 145); // prima posiziona fino al penultimo
        } else {
          this.posiziono_oggetto('wall_m', 'wall_' + i, dest_name,false,true,145); // sposta la matric fuori scena all'ultimo
        }
        i++
      });

      //diamonds
      this.level.diamonds.forEach(element => {
        //console.log(element);
        const dest_name = 'base_' + element[0] + "_" + element[1];
        const diamond = this.scene.getObjectByName( dest_name );
        var colore = diamond.material.color;
        const material = new THREE.MeshStandardMaterial();
        diamond.material = material;
        diamond.material.color = {b:1,g:1,isColoe:true,r:0}
        //diamond.material.emissive = 0xe6f604;
        diamond.material.emissiveIntensity = 8;
      });

      // boxes
      let b = 0
      this.level.crates.forEach(element => {
        const dest_name = 'base_' + element[0] + "_" + element[1];
        if (b < this.level.crates.length - 1){
          this.posiziono_oggetto('box', 'box_' + b, dest_name, false, false, 145); // prima posiziona fino al penultimo
        } else {
          this.posiziono_oggetto('box', 'box_' + b, dest_name,false,true,145); // sposta la matrice fuori scena all'ultimo
        }
        b++;
      });
  }


  onClick(event:any):void{
    event.preventDefault();
    if (!this.procedi_animazione ){
      if (event.type == "click"){
        this.intercetta(event.clientX, event.clientY);
      } else if (event.type=="touchstart"){
        this.intercetta(event.touches[0].clientX, event.touches[0].clientY);
      }
    }
  }

  intercetta(x:number, y:number):void{
    if (!this.vittoria){
      if (!this.procedi_con_animazione){
        var canvas = document.getElementById('canvas_sokoban');
        let canvasBounds = canvas?.getBoundingClientRect();
        if (canvasBounds != undefined){
            this.mouse.x = ( ( x - canvasBounds.left ) / ( canvasBounds.right - canvasBounds.left ) ) * 2 - 1;
            this.mouse.y = - ( ( y - canvasBounds.top ) / ( canvasBounds.bottom - canvasBounds.top) ) * 2 + 1;
            this.raycaster.setFromCamera(this.mouse, this.camera);
            var result:any = [];
            this.raycaster.intersectObjects(this.scene.children, true, result);
           var intersects:any
           intersects = (result.length ) > 0 ? result[0] : null;
           if (intersects !== null){
              if (intersects != undefined){
                this.oggetto_intercettato = intersects.object.name;
                this.oggetto3D_intercettato = intersects.object;
              }
           }
        }
      } //
      this.gestionegioco();
    }//
   }

   ngOnDestroy(): void {
    this.stopAnimationLoop();
    this.renderer.forceContextLoss();
  }

  /* Rimuovo il contenuto precedente del canvas */
  rimuovi_contenuto_precedente(){
    const list = document.getElementById('canvas_sokoban');
    if (list){
      if (list.hasChildNodes()) {
        list.removeChild(list.childNodes[0]);
    }
   }
 }

 gestionegioco():void{
    //console.log(this.oggetto_intercettato);
    //console.log(this.oggetto3D_intercettato);

    // spostamento robot
    if (this.oggetto_intercettato.includes('base')){
      const da_spostare = this.scene.getObjectByName('robo_pl');
      const destinazione = this.scene.getObjectByName(this.oggetto_intercettato);
      const vettore1 = new THREE.Vector3();
      const vettore2 = new THREE.Vector3();
      destinazione.geometry.computeBoundingBox();
      destinazione.geometry.boundingBox.getCenter(vettore1);
      da_spostare.geometry.computeBoundingBox();
      da_spostare.geometry.boundingBox.getCenter(vettore2);
      //da_spostare.position.copy(vettore1);
      //da_spostare.position.y = da_spostare.position.y + 145;
      this.calcola_percorso(vettore1, da_spostare);
    }

    if (this.oggetto_intercettato.includes('box_')) {
        const robotPos = this.getPlayerPosition();
        const [robotRow, robotCol] = robotPos;

        const boxName = this.oggetto_intercettato;
        const box = this.scene.getObjectByName(boxName);
        if (!box) return;

        const boxPos = this.findBoxPosition(boxName);
        const [boxRow, boxCol] = boxPos;
        //console.log('Posizione box:', boxPos);

        // Controllo se il robot è esattamente adiacente al box
        const isAdjacent =
            (robotRow === boxRow && Math.abs(robotCol - boxCol) === 1) || // Stesso row, colonna adiacente
            (robotCol === boxCol && Math.abs(robotRow - boxRow) === 1);   // Stesso col, riga adiacente

        //console.log('Il robot è adiacente al box?', isAdjacent);

        if (!isAdjacent) return; // Se non è adiacente, esci dalla funzione

        let newBoxRow = boxRow;
        let newBoxCol = boxCol;

        // Calcolo della nuova posizione del box
        if (robotRow === boxRow) { // Robot e box sulla stessa riga
            if (robotCol < boxCol && this.isCellFree(boxRow, boxCol + 1)) { // Robot a sinistra del box, sposta il box a destra
                newBoxCol += 1;
            } else if (robotCol > boxCol && this.isCellFree(boxRow, boxCol - 1)) { // Robot a destra del box, sposta il box a sinistra
                newBoxCol -= 1;
            }
        } else if (robotCol === boxCol) { // Robot e box sulla stessa colonna
            if (robotRow < boxRow && this.isCellFree(boxRow + 1, boxCol)) { // Robot sopra il box, sposta il box in basso
                newBoxRow += 1;
            } else if (robotRow > boxRow && this.isCellFree(boxRow - 1, boxCol)) { // Robot sotto il box, sposta il box in alto
                newBoxRow -= 1;
            }
        }

        //console.log('Nuova posizione proposta per il box:', [newBoxRow, newBoxCol]);

        // Se c'è una nuova posizione valida per il box, spostalo
        if ((newBoxRow !== boxRow || newBoxCol !== boxCol) && this.isCellFree(newBoxRow, newBoxCol)) {
            //console.log('Cella libera, procedo con lo spostamento');
            /*this.sokoCratesSound.pause();
            this.sokoCratesSound.currentTime = 0;*/

            //this.sokoCratesSound.play();
            this.playSound(this.cratesBuffer);

            // Prima di muovere il box, svuota la vecchia posizione del robot
            this.updateMatrixPosition(robotRow, robotCol, newBoxRow, newBoxCol, '0'); // Svuota la posizione del robot

            this.moveBox(boxName,box, newBoxRow, newBoxCol);
            this.updateMatrixPosition(boxRow, boxCol, newBoxRow, newBoxCol, '3'); // Aggiorna la matrice per il box

            // Muovi il robot alla posizione precedente del box
            this.moveRobot(boxRow, boxCol, robotRow, robotCol);

            this.restoreDiamonds();  // Ripristina i diamanti, ignorando il robot
            this.ensureRobotPosition();  // Mantieni la posizione del robot

            //this.openModal(); // DEBUG

            if (this.areAllDiamondsCovered()) {
                //console.log("Tutti i diamanti sono coperti dai box! Hai vinto!");
                //this.levelcompleteSound.play();
                this.playSound(this.levelCompleteBuffer);
                this.openModal();
            } else {
                //console.log("Ci sono ancora diamanti da coprire.");
            }

        } else {
            //console.log('La cella non è libera, non posso spostare il box');
        }
    }

 }

 // Funzione per assicurarsi che la posizione del robot rimanga invariata
ensureRobotPosition(): void {
  const [robotRow, robotCol] = this.getPlayerPosition();
  this.arrayLevel[robotRow][robotCol] = '4'; // Mantieni il robot nella sua posizione
}

 // Funzione per ripristinare i diamanti, ma ignorando il robot
restoreDiamonds(): void {
  for (const [row, col] of this.level.diamonds) {
      if (this.arrayLevel[row][col] !== '3' && this.arrayLevel[row][col] !== '4') {
          this.arrayLevel[row][col] = '2';
      }
  }
}

// Funzione per controllare se tutti i diamanti sono coperti da box
areAllDiamondsCovered(): boolean {
  for (const [row, col] of this.level.diamonds) {
      if (this.arrayLevel[row][col] !== '3') {
          return false; // Trovato un diamante non coperto da un box
      }
  }
  return true; // Tutti i diamanti sono coperti da box
}

 // Sposta il box alla nuova posizione
moveBox(boxName:string, box: any, newRow: number, newCol: number): void {
  const dest_name = `base_${newRow}_${newCol}`;
  const destination = this.scene.getObjectByName(dest_name);

  if (destination) {
      const destinationVector = new THREE.Vector3();
      destination.geometry.computeBoundingBox();
      destination.geometry.boundingBox.getCenter(destinationVector);

      destinationVector.y = 100; // Mantieni l'altezza del box
     // console.log('mappazzone:  ',destinationVector);
      box.position.copy(destinationVector); // Sposta il box alla nuova posizione

      // Anima il movimento del box
    //this.animateMovement(box, destinationVector, () => {
      const [oldRow, oldCol] = this.boxPositionMap[boxName];
      this.arrayLevel[oldRow][oldCol] = '0'; // Rimuove il box dalla posizione precedente
      this.arrayLevel[newRow][newCol] = '3'; // Pone il box nella nuova posizione

      // Aggiorna la mappa dei box
      this.boxPositionMap[boxName] = [newRow, newCol];
   // });
  }
}

// Aggiorna la matrice del livello per riflettere lo spostamento
updateMatrixPosition(oldRow: number, oldCol: number, newRow: number, newCol: number, element: string): void {
  this.arrayLevel[oldRow][oldCol] = '0'; // Svuota la vecchia posizione
  this.arrayLevel[newRow][newCol] = element; // Posiziona l'elemento nella nuova posizione

  //console.log(this.arrayLevel);
}


// Sposta il robot alla nuova posizione
moveRobot(newRobotRow: number, newRobotCol: number, oldRobotRow: number, oldRobotCol: number): void {
  const robot = this.scene.getObjectByName('robo_pl');
  if (!robot) return;

  // Prima svuotiamo la vecchia posizione nella matrice
  this.arrayLevel[oldRobotRow][oldRobotCol] = '0';

  const dest_name = `base_${newRobotRow}_${newRobotCol}`;
  const destination = this.scene.getObjectByName(dest_name);

  if (destination) {
      const destinationVector = new THREE.Vector3();
      destination.geometry.computeBoundingBox();
      destination.geometry.boundingBox.getCenter(destinationVector);

      destinationVector.y = 50; // Mantieni l'altezza del robot
      //robot.position.copy(destinationVector); // Sposta il robot alla nuova posizione

      // Ora aggiorniamo la nuova posizione del robot nella matrice
      //this.arrayLevel[newRobotRow][newRobotCol] = '4';

      // Calcola la rotazione del robot in base alla direzione del movimento
      const direction = new THREE.Vector3().subVectors(destinationVector, robot.position).normalize();
      //const angle = Math.atan2(direction.x, direction.z) + Math.PI;

      // **Calcolo della rotazione per il robot**
      const angle = Math.atan2(direction.x, direction.z) + Math.PI;
      robot.rotation.y = angle;  // applica la rotazione sull'asse Y

      robot.position.copy(destinationVector); // Sposta il robot alla nuova posizione

      // Anima il movimento e la rotazione del robot
      this.arrayLevel[newRobotRow][newRobotCol] = '4'; // Posiziona il robot nella nuova posizione
      // Anima il movimento e la rotazione del robot

  }
}


    // Funzione di animazione per spostamento fluido di un oggetto (non implementata)
    animateMovement(object: any, targetPosition: THREE.Vector3, onComplete: () => void) {
      const duration = 500; // Durata dell'animazione in millisecondi
      const startPosition = object.position.clone();
      const startTime = performance.now();

      const animate = (time: number) => {
        const elapsedTime = time - startTime;
        const progress = Math.min(elapsedTime / duration, 1); // Valore tra 0 e 1

        // Interpolazione lineare tra la posizione di partenza e la destinazione
        object.position.lerpVectors(startPosition, targetPosition, progress);

        if (progress < 1) {
          requestAnimationFrame(animate); // Continua l'animazione
        } else {
          onComplete(); // Esegui il callback alla fine dell'animazione
        }
      };
      requestAnimationFrame(animate); // Inizio dell'animazione
    }


    // Funzione per ottenere la posizione del box dalla mappa
    findBoxPosition(boxName: string): [number, number] {
      return this.boxPositionMap[boxName] || [-1, -1];
    }

  // Trova la posizione del box nell'arrayLevel
  findElementPosition(boxName: string): [number, number] {
    for (let r = 0; r < this.arrayLevel.length; r++) {
        for (let c = 0; c < this.arrayLevel[r].length; c++) {
            if (this.arrayLevel[r][c] === '3') {
                return [r, c];
            }
        }
    }
    return [-1, -1];
  }

  // Verifica se una cella è libera (vuota '0' o contiene un diamante '2')
  isCellFree(row: number, col: number): boolean {
    return this.arrayLevel[row][col] === '0' || this.arrayLevel[row][col] === '2';
  }



 calcola_percorso(coord_p: any, object: any) {
  if (this.oggetto_intercettato.includes('base')) {
    const end = this.oggetto_intercettato.split("_").slice(1).map(Number);
    const start = this.getPlayerPosition();

    this.currentPath = this.findWay(this.arrayLevel, start, end);
    this.animationIndex = 0;

    if (this.currentPath && this.currentPath.length > 0) {
      this.targetPosition = new THREE.Vector3(coord_p.x, coord_p.y, coord_p.z);
      this.procedi_animazione = true;
    }
  }
}

updatePlayerPositionInMatrix(newPos: number[]) {
  // Rimuovi il vecchio riferimento del giocatore
  for (let i = 0; i < this.arrayLevel.length; i++) {
    for (let j = 0; j < this.arrayLevel[i].length; j++) {
      if (this.arrayLevel[i][j] === '4') {
        this.arrayLevel[i][j] = '0'; // Sostituisci con '0' o un altro valore che rappresenta una cella vuota
        break;
      }
    }
  }
  // Aggiorna con la nuova posizione del giocatore
  this.arrayLevel[newPos[0]][newPos[1]] = '4'; // '4' rappresenta il giocatore
}

 // inizio calcolo percorso
 findWay(matrix: any, position:any, end:any):[]
{
   var queue = [];
   var validpaths = [];

   // New points, where we did not check the surroundings:
   // remember the position and how we got there
   // initially our starting point and a path containing only this point
   queue.push({pos: position, path: [position]});

    // as long as we have unchecked points
    while(queue.length > 0){

      // get next position to check viable directions
      var obj:any = queue.shift();
      var pos:any = obj.pos;
      var path:any = obj.path;

      // all points in each direction
      var direction:any = [ [ pos[0] + 1, pos[1] ], [ pos[0], pos[1] + 1 ],
                   [ pos[0] - 1, pos[1] ], [ pos[0], pos[1] - 1 ] ];

      for(var i = 0; i < direction.length; i++){

          // check if out of bounds or in a "wall"
          if (direction[i][0] < 0 || direction[i][0] >= matrix[0].length) continue;
          if (direction[i][1] < 0 || direction[i][1] >= matrix[0].length) continue;
          if (matrix[direction[i][0]][direction[i][1]] != '0' && matrix[direction[i][0]][direction[i][1]] != '2') continue;

          // check if we were at this point with this path already:
          var visited = false;
          for (var j = 0; j < path.length; j ++) {
               if ((path[j][0] == direction[i][0] && path[j][1] == direction[i][1])) {
                   visited = true;
                   break;
              }
          }
          if (visited) continue;

          // copy path
          var newpath:any = path.slice(0);
          // add new point
          newpath.push(direction[i]);

          // check if we are at end
          if (direction[i][0] != end[0] || direction[i][1] != end[1]) {
             // remember position and the path to it
             queue.push({pos: direction[i], path: newpath});
          } else {
            // remember this path from start to end
            validpaths.push(newpath);
            // break here if you want only one shortest path
          }

      }
    }
    // solo il primo
    return validpaths[0];
}
// fine calcolo percorso


 //rilevo la posizione del player
 getPlayerPosition(){
  let res=[0,0];
  for(let l = 0; l < this.arrayLevel.length; l++) {
    for(let ci = 0; ci < this.arrayLevel[l].length; ci++) {
      if (this.arrayLevel[l][ci]== '4'){
             res = [l, ci];
      }
    }
  }
  return res;
 }

 loadObject(url:string, poszcamera:number, materiale:boolean, int_luce:number, int_lightamb:number){

  this.renderer.shadowMap.enabled = true;
  this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
  this.renderer.setSize( 300, 300 );
  this.renderer.setPixelRatio( window.devicePixelRatio );


  this.camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 10000 );

  const pointLight = new THREE.PointLight( 0xffffff, int_luce );
  pointLight.castShadow = true;
  pointLight.shadow.bias = -0.0005;
  pointLight.position.y = 1154523.05;
  this.camera.add( pointLight );
  pointLight.position.z = 10;
  pointLight.position.x = 10;

  const light = new THREE.SpotLight(0xcccccc, 2.5);
  light.castShadow = true;
  light.position.y = 2000;
  light.position.x = 2000;
  light.position.z = 1000;
  light.shadow.mapSize.width = 1024;
  light.shadow.mapSize.height = 1024;
  light.shadow.camera.near = 500;
  light.shadow.camera.far = 6000;
  light.angle = Math.PI/5;
  this.scene.add(light);
  const helper = new THREE.SpotLightHelper( light, 20 );
  //this.scene.add( helper );

  //const axesHelper = new THREE.AxesHelper(500); // La lunghezza degli assi (500 unità in questo caso)
  //this.scene.add(axesHelper);

  this.camera.position.set(0, 0, poszcamera);
  var ambientLight = new THREE.AmbientLight( 0xcccccc, int_lightamb );
  this.scene.add( ambientLight );
  this.scene.add( this.camera );
  this.camera.position.y =  -1623.7835;
  this.camera.position.x = -1602.7419;
  this.camera.position.z =  807.2137;

  this.controls = new OrbitControls( this.camera, this.renderer.domElement );
  this.controls.enableDamping = true;
  this.controls.dampingFactor = 1;
  this.controls.enableZoom = true;
  this.controls.enablePan = false;
  this.controls.autoRotate = false;

  this.controls.maxPolarAngle = Math.PI * 0.4;
  this.controls.minDistance = 1400;
  this.controls.maxDistance = 2000;

  // Mouse =======================
  this.raycaster = new THREE.Raycaster();
  this.mouse = new THREE.Vector2(0,0);
// ================

     // Caricamento materiale
    if (materiale){
      this.mtlLoader.setPath('assets/3dobject/');
      this.mtlLoader.load(
        url + '.mtl',
        (mtlParseResult:any)=>{
          mtlParseResult.preload();
          this.loader.setMaterials(mtlParseResult);
          this.loadmodels(url);
        },
        (progress:any)=>{
            //
        },
        (err:any)=>{
          console.log(err);
        }
      )
    } else {
      this.loadmodels(url);
    }

 }

  // Ferma il ciclo di animazione corrente
  stopAnimationLoop(): void {
    if (this.animationId !== null) {
      cancelAnimationFrame(this.animationId); // Ferma l'animazione precedente
      this.animationId = null;
    }
  }

  loadmodels(url:string){
    // Caricamento oggetto
    this.loader.setPath('assets/3dobject/');
    this.loader.load(
      url + '.obj',
      (object3d:any)=> {

         this.root = object3d;
         //console.log(this.root); // debug

         this.root.traverse(function(child:any){child.castShadow = true;});
         this.root.traverse(function(child:any){child.receiveShadow = true;});
         this.scene.add(this.root);

         this.renderer.setClearColor( 0x40214a, 1 );

         const pav = this.scene.getObjectByName( 'base_0_0' );
         this.emissive_pav = pav.material.emissive;

         if (this.canvas){
          this.rimuovi_contenuto_precedente();
          this.canvas.appendChild( this.renderer.domElement );
         }

         this.posiziona_oggetti_in_campo();
         this.animate();

      },
      (progress:any)=>{
       if (this.canvas){
         if (this.canvas){
            this.canvas.innerHTML = '' + Math.floor((progress.loaded / progress.total * 100 )) + '% loaded';
         }
         this.cd.detectChanges();
       }
      },
      (err:any) => {
         // error
         console.log(err);
         console.log('errore');
      }
    );

}

animate(): void {
  // Ferma qualsiasi ciclo di animazione precedente prima di iniziare un nuovo ciclo
  this.stopAnimationLoop();

  const animateFrame = () => {
    if (this.renderer.domElement.parentElement) {
      var container = this.renderer.domElement.parentElement;
      this.camera.lookAt(this.scene.position);
      this.camera.updateMatrixWorld();

      var box = container.getBoundingClientRect();
      if (box.width !== this.oldCH || box.height !== this.oldCH) {
        this.renderer.setSize(box.width - 8, box.height - 8);
        this.renderer.render(this.scene, this.camera);
        this.camera.aspect = box.width / box.height;
        this.camera.lookAt(this.scene.position);
        this.camera.updateProjectionMatrix();
        this.oldCH = box.width;
        this.oldCW = box.height;
      }

      this.controls.update();
    }

    if (this.procedi_animazione) {
      this.animazione3d_pezzo();
    }

    // Mantieni l'animazione in loop finché è attiva
    this.animationId = requestAnimationFrame(animateFrame);
  };

  this.animationId = requestAnimationFrame(animateFrame); // Avvia un nuovo ciclo di animazione
}



animazione3d_pezzo() {
  if (!this.procedi_animazione || !this.currentPath.length) return;

  const da_spostare = this.scene.getObjectByName('robo_pl');

  if (this.animationIndex < this.currentPath.length) {
    const nextPos = this.currentPath[this.animationIndex];
    const destinationName = `base_${nextPos[0]}_${nextPos[1]}`;

    const destination = this.scene.getObjectByName(destinationName);

    if (destination && da_spostare) {
      const destinationVector = new THREE.Vector3();
      destination.geometry.computeBoundingBox();
      destination.geometry.boundingBox.getCenter(destinationVector);

      destinationVector.y = 50;

      // Calcolo del vettore direzionale e passo fisso
      const direction = new THREE.Vector3().subVectors(destinationVector, da_spostare.position).normalize();
      const step = direction.multiplyScalar(this.animationStep);

      // **Calcolo della rotazione per il robot**
      const angle = Math.atan2(direction.x, direction.z) + Math.PI;
      da_spostare.rotation.y = angle;  // applica la rotazione sull'asse Y

      // Movimento verso il prossimo punto
      da_spostare.position.add(step);

      // Verifica se l'oggetto ha raggiunto la posizione di destinazione
      if (da_spostare.position.distanceTo(destinationVector) < this.animationStep) {
        this.animationIndex++;
        da_spostare.position.copy(destinationVector); // Allinea perfettamente alla destinazione

        // Mantieni la rotazione finale dopo aver raggiunto la destinazione
        da_spostare.rotation.y = angle;
      }
    }
  } else {
    // Ferma l'animazione una volta raggiunta la posizione finale
    this.procedi_animazione = false;

    // Aggiorna la posizione del giocatore nella matrice
    if (this.currentPath.length > 0) {
      const finalPos = this.currentPath[this.currentPath.length - 1];
      this.updatePlayerPositionInMatrix(finalPos);

      // Aggiorna anche la posizione effettiva dell'oggetto 'robo_pl' nella scena 3D
      const finalDestinationName = `base_${finalPos[0]}_${finalPos[1]}`;
      const finalDestination = this.scene.getObjectByName(finalDestinationName);

      if (finalDestination && da_spostare) {
        const finalDestinationVector = new THREE.Vector3();
        finalDestination.geometry.computeBoundingBox();
        finalDestination.geometry.boundingBox.getCenter(finalDestinationVector);

        finalDestinationVector.y = 50;
        da_spostare.position.copy(finalDestinationVector); // Imposta la posizione finale esatta

        // Mantieni la rotazione finale
        //const finalAngle = Math.atan2(finalDestinationVector.x - da_spostare.position.x, finalDestinationVector.z - da_spostare.position.z) + Math.PI;
        //da_spostare.rotation.y = finalAngle;
        this.restoreDiamonds();  // Ripristina i diamanti, ignorando il robot

       // console.log(this.level);
      }
    }
  }
}



}
