<p-dialog [header]="titolo"
[(visible)]="dialog"
[modal] = true
>
  <div class="messaggio">{{testo_messaggio}}</div>
</p-dialog>

<!--<div class='game' *ngIf="global.isMobile" @fade>

  <div class="testo_pr">
    Spiacente ma questo gioco è disponibile solo per tablet o desktop.<br>
  </div>

</div>-->


<div class='game' @fade>

    <div *ngIf="!viualizza_pulsante_classifica" class='avviso' >
        <p-progressSpinner
            [style]="{width: '40px', height: '40px'}"
            styleClass="custom-spinner"
            strokeWidth="5"
            animationDuration=".5s"
        ></p-progressSpinner>
        <div class='testo_avviso'>LOADING....</div>
    </div>

      <div style='font-size: x-large; color: white;' class='avviso' >
        {{record}}
        <div class='butt'>
          <p-button
            class='p-button-rounded'
            *ngIf='viualizza_pulsante_classifica'
            (onClick)='openDialogClassifica()'
            >Classifica
          </p-button>
        </div>
      </div>

    <div>
        <iframe scrolling="no" type="text/html" src="assets/game2d/raingame/index.html" width="800px" height="600px" >
        </iframe>
    </div>

    <div class='butt'>
        <p-button class='p-button-rounded' (onClick)='openIstruzioni()'>Istruzioni</p-button>
    </div>

</div>


<!--<div>
   <object type="text/html" data="http://127.0.0.1:8888" width="800px" height="600px" style="overflow:auto;border:5px ridge blue">
   </object>
</div>-->





