import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader.js';
import { Globals } from 'src/app/globals';
import { IstruzionigamesComponent } from '../istruzionigames/istruzionigames.component';
import { NomeVincitoreComponent } from '../nome-vincitore/nome-vincitore.component';

import { HttpService } from '../services/http.service';
import {
  trigger,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ClassificagiochiComponent } from '../classificagiochi/classificagiochi.component';

@Component({
  selector: 'app-hanoitower',
  templateUrl: './hanoitower.component.html',
  styleUrls: ['./hanoitower.component.scss'],
  providers:[DynamicDialogRef,DynamicDialogConfig],
  animations: [
    // animation triggers go here
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({opacity: 1}))
      ])
    ])
  ]
})


export class HanoitowerComponent implements OnInit, OnDestroy, AfterViewInit {

  public datiGioco:any;
  public mosse_totali:number = 0;
  public scritta_testata: string = "";
  public record:string = 'pippo';
  public viualizza_pulsante_classifica = false;
  public hide_avviso:boolean = true;

  public renderer:any;
  public camera:any;
  public loader = new OBJLoader;
  public mtlLoader = new MTLLoader();
  public controls:any;
  public mouse = new THREE.Vector2(0,0);
  public canvas:any;


  public dialogRef: any;

  public nascondi_canvas = false;
  public nascondi_istruzioni = false;
  public situazione_colonne  = ['1234567', '0000000', '0000000'];
  public emissive_pezzo:any;
  public emissive_colonna:any;

  public colonna_selezionata:any;
  public pezzo_selezionato:any;

  //public posizioni_iniziali = [];
  public scene:any;
  public raycaster = new THREE.Raycaster();

  public oldCH:number =0;
  public oldCW:number =0;

  public audio_ground:any;
  public pedina_sound:any;

  public checked:boolean = false;
  public controlli_audio = false;
  public oggetto3D_intercettato:any
  public oggetto_intercettato = '';
  public root:any;
  public indice_libero_colonna_arrivo:number = 0;
  public posizione_arrivo_y:number = 0;
  public altezzapezzo:number = 0.4000005126;
  public y_pezzo_base:number = -1.3702840209007263;
  public procedi_con_animazione:boolean = false;
  public vittoria:boolean = false;

  dialog = false;
  titolo='';
  testo_messaggio = ''

  constructor(
    public globals:Globals,
    public http: HttpService,
    private cd:ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
   // public loader: OBJLoader
    ) {
      THREE.Cache.enabled = true;

    }


  openDialogClassifica(){
    var dialogConfig = {
      width: '80%',
      header: 'Classifica',
      data: {name: 'torre'}
    }
    this.ref = this.dialogService.open(ClassificagiochiComponent , dialogConfig);
  }

  openDialog() {
    var dialogConfig = {
      width: '80%',
      header: 'Istruzioni',
      data: {hanoi: true}
    }
    this.ref = this.dialogService.open(IstruzionigamesComponent , dialogConfig);
  }


  openAlert(testo:string, titolo:string='') {
    this.testo_messaggio = testo;
    this.dialog = true
  }


  riceviDati_gioco(){
    this.datiGioco = this.http.ricezioneDati('torre').subscribe ((data:any) => {
       //console.log(data);
       if (data.length > 0){
        data.sort(function (a:any, b:any) {
          return a.punteggio - b.punteggio;
        });
         this.record = "Record: " + data[0].nome + " con " + data[0].punteggio + " mosse";
         this.viualizza_pulsante_classifica = true;
       } else {
        this.record = 'Record: nessun record registrato';
       }
       this.hide_avviso = true;
     }, (err:any) => {
       console.log(err);
       console.log("Error occured");
       this.openAlert('Problema di connessione. Ricarica la pagina');
        }
     );
  }

  inizializza(){
    this.hide_avviso = false;
    this.carica_partita();
    this.riceviDati_gioco();
  }


  onClick(event:any):void{
   //console.log(event.type);
     event.preventDefault();
    if (event.type == "click"){
        this.intercetta(event.clientX, event.clientY);
    } else if (event.type=="touchstart"){
      this.intercetta(event.touches[0].clientX, event.touches[0].clientY);
    }
   }


   intercetta(x:number, y:number):void{
    if (!this.vittoria){
      if (!this.procedi_con_animazione){
        var canvas = document.getElementById('canvas_game');
        let canvasBounds = canvas?.getBoundingClientRect();
        if (canvasBounds != undefined){
            this.mouse.x = ( ( x - canvasBounds.left ) / ( canvasBounds.right - canvasBounds.left ) ) * 2 - 1;
            this.mouse.y = - ( ( y - canvasBounds.top ) / ( canvasBounds.bottom - canvasBounds.top) ) * 2 + 1;
            this.raycaster.setFromCamera(this.mouse, this.camera);
            var result:any = [];
            this.raycaster.intersectObjects(this.scene.children, true, result);
           // console.log(result);
           var intersects:any
           intersects = (result.length ) > 0 ? result[0] : null;
           if (intersects !== null){
              if (intersects != undefined){
                this.oggetto_intercettato = intersects.object.name;
                this.oggetto3D_intercettato = intersects.object;
              }
           }
        }
      } //
      this.gestionegioco();
    }//
   }

   gestionegioco():void{
    if (this.oggetto_intercettato.includes('pezzo') || this.oggetto_intercettato.includes('colonna')){

      if (this.oggetto_intercettato.includes('pezzo')){
             this.procedura_pezzo();
      }
      /*  if (th.oggetto_intercettato.includes('colonna')){
            procedura_colonna_2();
      }*/
      if (this.oggetto_intercettato.includes('colonna') && this.pezzo_selezionato){
            this.procedura_colonna();
      }
      } else {
        // Se non è un pezzo o una colonna allora tolgo le selezioni
        //resetta_selezioni();
      }

   }

   procedura_pezzo():void{
    if (this.oggetto3D_intercettato.material.emissive != this.oggetto3D_intercettato.material.color){
      if (this.pezzo_selezionato){
        this.pezzo_selezionato.material.emissive = this.emissive_pezzo;
      }
      this.emissive_pezzo = this.oggetto3D_intercettato.material.emissive
      var colore = this.oggetto3D_intercettato.material.color;
      this.oggetto3D_intercettato.material.emissive = colore;
      this.oggetto3D_intercettato.material.emissiveIntensity = 4;
      this.pezzo_selezionato = this.oggetto3D_intercettato;
    }

   }

   procedura_colonna():void{
    if (this.oggetto3D_intercettato.material.emissive != this.oggetto3D_intercettato.material.color){
      if (this.colonna_selezionata){
        this.colonna_selezionata.material.emissive = this.emissive_colonna;
      }
      this.emissive_colonna = this.oggetto3D_intercettato.material.emissive
      var colore = this.oggetto3D_intercettato.material.color;
      this.oggetto3D_intercettato.material.emissive = colore;
      this.oggetto3D_intercettato.material.emissiveIntensity = 4;
      this.colonna_selezionata = this.oggetto3D_intercettato;
      this.sposta_pezzo();
      //resetta_selezioni();
    }
   }


   sposta_pezzo():void{

    var nome_c = this.colonna_selezionata.name;
    var nome_p = this.pezzo_selezionato.name;
    var indice_colonna_di_arrivo = parseInt((nome_c.split('_'))[1])-1;
    var numero_p = (nome_p.split('_'))[1];

    // devo trovare in che colonna si trova il pezzo che devo spostare
     var indice_colonna_di_partenza = this.situazione_colonne.findIndex ((value, index) => {
       return value.includes(numero_p);
    });



    var array_c_partenza = this.situazione_colonne[indice_colonna_di_partenza].split('');
    var array_c_arrivo = this.situazione_colonne[indice_colonna_di_arrivo].split('');

    var indice_pezzo_colonna_partenza = array_c_partenza.findIndex ((value, index) => { // globale
     return value.includes(numero_p);
    });

    var procedi = false;
    if (indice_pezzo_colonna_partenza == 0){ // se è il primo pezzo in alto
        procedi = true;
    } else
    if (indice_pezzo_colonna_partenza > 0){ // se è sotto la prima posizione in altezza
      if (array_c_partenza[indice_pezzo_colonna_partenza-1] != '0'){
       //resetta_selezioni();
        this.resetta_selezioni(false);
       //  sel_pezzo();
      } else {
       procedi = true;
      }
    }

    // ================================================================

       if (procedi){
       // analisi colonna di arrivo ======================================
         // Trovo la prima posizione libera della colonna di arrivo
         this.indice_libero_colonna_arrivo = array_c_arrivo.lastIndexOf('0'); //globale
         // ==========================================================

         if (this.indice_libero_colonna_arrivo == 6){
            this.spostamento_in_array_colonne(array_c_partenza, indice_pezzo_colonna_partenza, array_c_arrivo, numero_p, indice_colonna_di_partenza, indice_colonna_di_arrivo );
            this.sposta_3D(6 - this.indice_libero_colonna_arrivo);
         } else
         if (this.indice_libero_colonna_arrivo < 6) {
           // ho un'altro pezzo sotto
           if (parseInt(array_c_arrivo[this.indice_libero_colonna_arrivo + 1]) > parseInt(array_c_partenza[indice_pezzo_colonna_partenza])){
             this.spostamento_in_array_colonne(array_c_partenza, indice_pezzo_colonna_partenza, array_c_arrivo, numero_p, indice_colonna_di_partenza, indice_colonna_di_arrivo );
             this.sposta_3D(6 - this.indice_libero_colonna_arrivo);
           } else {
             this.resetta_selezioni(false);
            // sel_pezzo();
           }

         }
     // =======================================================
       }


      // ================================================================

      // console.log (situazione_colonne);
   }

   sposta_3D(conto_pezzi: number):void{

    var vettore1:any = new THREE.Vector3();
    var vettore2:any = new THREE.Vector3();

    this.pezzo_selezionato.geometry.computeBoundingBox();
    var vettore_ps = this.pezzo_selezionato.geometry.boundingBox.getCenter(vettore1);
   // console.log( 'pezzo:', vettore_ps);
    this.colonna_selezionata.geometry.computeBoundingBox();
    var vettore_cs = this.colonna_selezionata.geometry.boundingBox.getCenter(vettore2);
   // console.log('colonna',vettore_cs);

    // sposto pezzo sopra la colonna ========================
      //pezzo_selezionato.position.y = 0.4000005126;
    // coordinate pezzo base = Vector3 {x: -8.141551971435547, y: -1.3702840209007263, z: -0.02570497989654541}

    //console.log(vettore_ps.x + '/' + vettore_cs.x);
    var valore_spostamento_x = vettore_cs.x - vettore_ps.x;
    //console.log( 'valore spostamento x', valore_spostamento_x);
    this.pezzo_selezionato.position.x = valore_spostamento_x;

    this.posizione_arrivo_y = -(vettore_ps.y - this.y_pezzo_base) + (this.altezzapezzo * conto_pezzi);
    this.pezzo_selezionato.position.y = this.posizione_arrivo_y + 5;
    //console.log( 'valore spostamento y', pezzo_selezionato.position.y);

    this.procedi_con_animazione = true;
    this.pedina_sound.play();
    //======================================================

    vettore1 = null;
    vettore2 = null;
    this.pezzo_selezionato.geometry.boundingBox = null;
    this.colonna_selezionata.geometry.boundingBox = null;

    this.mosse_totali += 1;
    this.scritta_testata = 'Mosse: ' + this.mosse_totali;
    //document.getElementById('punt').innerHTML = 'Mosse: '+mosse_totali;

    this.verifica_vittoria();
   }

   verifica_vittoria():any{
    if (this.situazione_colonne[1] == "1234567" || this.situazione_colonne[2] == "1234567"){
      //document.getElementById('punt').innerHTML = 'Vittoria in '+ mosse_totali + ' mosse';
      this.scritta_testata = 'Vittoria in '+ this.mosse_totali + ' mosse';
      this.vittoria = true;
      // apro finestra modale per inserimento nome vincitore
        this.s_vittoria(this.mosse_totali);
     // ===================================================
     }
     this.cd.detectChanges();
   }


   spostamento_in_array_colonne(array_c_partenza:any, indice_pezzo_colonna_partenza:number, array_c_arrivo:any, numero_p:number, indice_colonna_di_partenza:number, indice_colonna_di_arrivo:number):void{
    array_c_partenza[indice_pezzo_colonna_partenza] = array_c_arrivo[this.indice_libero_colonna_arrivo];
    array_c_arrivo[this.indice_libero_colonna_arrivo] = numero_p;
    array_c_partenza[indice_pezzo_colonna_partenza] = '0';

    var str_c_p = '';
    array_c_partenza.forEach((element:any) => {
      str_c_p += element;
    });
    var str_c_a = '';
    array_c_arrivo.forEach((element:any) => {
      str_c_a += element;
    });
    this.situazione_colonne[indice_colonna_di_partenza] = str_c_p;
    this.situazione_colonne[indice_colonna_di_arrivo] = str_c_a;
   }

   resetta_selezioni(pezzo:boolean=true):void{

    if (this.colonna_selezionata){
      this.colonna_selezionata.material.emissive = this.emissive_colonna;
      this.colonna_selezionata = undefined;
     }

     if (pezzo){
      if (this.pezzo_selezionato){
        this.pezzo_selezionato.material.emissive = this.emissive_pezzo;
        this.pezzo_selezionato = undefined;
      }
    }

  }

  ngAfterViewInit(): void {
      this.canvas = document.getElementById('canvas_game');
      let width = this.canvas.style.width;
      let height = this.canvas.style.height;
      this.canvas.style.width  = '10px';
      this.canvas.style.height  = '10px';
      setTimeout(() => {
        this.canvas.style.height  = height;
        this.canvas.style.width  = width;
      }, 200);

  }

  ngOnInit(): void {
      this.audio_ground = new Audio();
      this.audio_ground.src = '../assets/sound/ground_hanoi.mp3';
      this.audio_ground.load();
      this.audio_ground.addEventListener('loadeddata', () => {
        this.controlli_audio = true;
      });
      this.audio_ground.volume = 1.0;
      this.audio_ground.loop = true;
      this.audio_ground.volume = 1.0;
      //this.audio_ground.play();
      this.pedina_sound = new Audio();
      this.pedina_sound.load();
      //this.pedina_sound.volume = 0.5;
      this.pedina_sound.src = '../assets/sound/hanoi_effect.mp3';

      this.inizializza();
  }

  music(e:Event){
    if (!this.checked){
       this.cd.detectChanges();
       this.audio_ground.pause();
     } else {
       this.cd.detectChanges();
       this.audio_ground.play();
     }
   }

      ngOnDestroy(){
        // if (this.procedi_suono){
          this.audio_ground.pause();
        //  }
        this.renderer.forceContextLoss();

        //this.canvas?.removeEventListener( 'touchstart', this.onClick, false);
        //this.canvas?.removeEventListener( 'click', this.onClick, false);

       // delete this.loader;
       // delete this.mtlLoader;

        if (this.datiGioco){
            this.datiGioco.unsubscribe();
        }
      }

      nuova_partita(){
        this.mosse_totali = 0;
        this.scritta_testata = 'Mosse: ' + this.mosse_totali;
        this.situazione_colonne  = ['1234567', '0000000', '0000000'];
        this.colonna_selezionata = undefined;
        this.pezzo_selezionato = undefined;

        var num_ch = this.scene.children.length - 1;
        var scena = this.scene.children[num_ch];
        this.oggetto_intercettato = '';

      var ogg = scena.children.length - 1;
      //console.log(ogg);
      while(ogg > 0){
          var pezzo  = this.scene.children[num_ch].children[ogg];
          if (pezzo.name.includes('pezzo')){
            //var vector = new THREE.Vector3();
            pezzo.geometry.computeBoundingBox();
            pezzo.position.x = 0;
            pezzo.position.y = 0;
            pezzo.position.z = 0;
            pezzo.material.emissive = this.emissive_pezzo;
          }
          ogg-=1;
        };
      }

      prepara_sfondo(){
        const loa = new THREE.CubeTextureLoader();

        const texture = loa.load([
          'assets/img/lake-cube-Map/px.png',
          'assets/img/lake-cube-Map/nx.png',
          'assets/img/lake-cube-Map/py.png',
          'assets/img/lake-cube-Map/ny.png',
          'assets/img/lake-cube-Map/pz.png',
          'assets/img/lake-cube-Map/nz.png',
        ]);
          this.scene.background = texture;
    }

    carica_partita(){
      //var canv = document.getElementById('canvas_game');
      if (this.canvas !=null){
        this.canvas.hidden = true;
      }
      this.rimuovi_contenuto_precedente();
      this.mosse_totali = 0;
      this.scritta_testata = 'Mosse: ' + this.mosse_totali;
      //var canv = document.getElementById('canvas_game');
      /* if (canv != null){
          canv.addEventListener('click', ($event:Event)=>{console.log("ok")}, true);
      }*/
      if (this.canvas != null){
        this.canvas.hidden = false;
      }
      if (this.renderer){
        this.renderer.forceContextLoss();
      }
      this.loader = new OBJLoader();
      this.mtlLoader = new MTLLoader();
      this.renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true } );
      this.scene = new THREE.Scene();
      this.raycaster = new THREE.Raycaster();

      this.prepara_sfondo();
      //this.canvas?.addEventListener( 'touchstart', this.onClick, false);
      //this.canvas?.addEventListener( 'click', this.onClick, false);
      //this.canvas?.addEventListener( 'mousemove', this.onmousemove );
      //this.loadObject('torrehanoi', 30, true, 1, 0.1);
      this.loadObject('torrehanoi', 30, true, 4, 0.1);
    }

    cancella_record_punteggio_maggiore(){
      this.datiGioco = this.http.ricezioneDati('torre').subscribe ((data:any) => {
        //console.log(data);
        if (data.length > 0){
         data.sort(function (a:any, b:any) {
           return b.punteggio - a.punteggio;
         });
         var codice = data[0]._id;
         this.http.eliminaDato(codice, 'torre');
        }
      }, (err:any) => {
        console.log(err);
        console.log("Error occured");
        this.openAlert('Problema di connessione. Ricarica la pagina');
         }
      );
    }

    rimuovi_contenuto_precedente(){
      //var list = document.getElementById('canvas_game');
      if (this.canvas != null){
          if (this.canvas.hasChildNodes()) {
          this.canvas.removeChild(this.canvas.childNodes[0]);
        }
      }
   }

   s_vittoria(punteggio:number){
    this.datiGioco = this.http.ricezioneDati('torre').subscribe ((data:any) => {
      //console.log(data);
      if (data.length == 10){
       data.sort(function (a:any, b:any) {
         return a.punteggio - b.punteggio;
       });
        if (data[data.length-1].punteggio >= punteggio){
          this.inserisci_nome(punteggio, true);
        } else {
          // non in clssifica
          this.openAlert('Spiacente, non sei entrato in classifica');
        }
      } else {
        this.inserisci_nome(punteggio, false);
      }
    }, (err:any) => {
      console.log(err);
      console.log("Error occured");
      this.openAlert('Problema di connessione. Ricarica la pagina');
       }
    );
  }


  inserisci_nome(punti:number, elimina:boolean){

      const ref = this.dialogService.open(NomeVincitoreComponent, {
        header: "Inserire il nome",
        width: '300px'
       });

       ref.onClose.subscribe((result:any) => {
          if (result !== undefined) {
            // se l'array è di 10 elementi devo cancellare quello con il punteggio più alto
          if (elimina){
            this.cancella_record_punteggio_maggiore();
          }
          this.http.aggiungiDato({nome: result, punteggio: punti}, 'torre');
          //document.body.style.backgroundColor = colore;
          this.inizializza();
        }
    });

    }



  loadObject(url:string, poszcamera:number, materiale:boolean, int_luce:number, int_lightamb:number){

      //var root;
      this.mouse = new THREE.Vector2(0,0);
      //console.log({w: window.innerWidth, h:window.innerHeight});
      this.camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 10000 );
      this.renderer.setPixelRatio( window.devicePixelRatio );
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
     // this.renderer.setSize( 300, 300 );
      var pointLight = new THREE.PointLight( 0xffffff, int_luce );

      //pointLight.castShadow = true;
      pointLight.position.y = 15;
      this.camera.add( pointLight );
      pointLight.position.z = 10;
      pointLight.position.x = 10;

      const light = new THREE.SpotLight(0xcccccc);
      light.castShadow = true;
      light.position.y = 15;
      light.position.x = -3;
      light.position.z = -2;
      light.shadow.mapSize.width = 1024;
      light.shadow.mapSize.height = 1024;
      // light.shadow.camera.near = 0.5;
      // light.shadow.camera.far = 100
      this.scene.add(light);

      this.camera.position.set(0, 0, poszcamera);
      var ambientLight = new THREE.AmbientLight( 0xcccccc, int_lightamb );
      this.scene.add( ambientLight );
      this.scene.add( this.camera );
      this.camera.position.y = 15;

      this.controls = new OrbitControls(this.camera, this.renderer.domElement );
      this.controls.maxPolarAngle = Math.PI * 0.45;
      this.controls.minDistance = 20;
      this.controls.maxDistance = 40;
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 1;
      this.controls.enableZoom = true;
      this.controls.enablePan = false;
      this.controls.autoRotate = false;


      // Caricamento materiale
      if (materiale){
        this.mtlLoader.setPath('assets/3dobject/');
        this.mtlLoader.load(
          url + '.mtl',
          (mtlParseResult:any)=>{
            this.loader.setMaterials(mtlParseResult);
            this.loadmodels(url);
          },
          (progress:any)=>{
              //
          },
          (err:any)=>{
            console.log(err);
          }
        )
      } else {
        this.loadmodels(url);
      }


  }



  loadmodels(url:string){
     // Caricamento oggetto
     this.loader.setPath('assets/3dobject/');
     this.loader.load(
       url + '.obj',
       (object3d:any)=> {
          // callback on load
          if (this.canvas){
           this.rimuovi_contenuto_precedente();
           //var canv = document.getElementById('canvas_game');
           if (this.canvas != null){
             this.canvas.appendChild( this.renderer.domElement );
           }

           this.root = object3d;
           this.root.traverse(function(child:any){child.castShadow = true;});
           this.root.traverse(function(child:any){child.receiveShadow = true;});
           this.scene.add(this.root);

           var lung = this.scene.children.length;
           var ogg = this.scene.children[lung-1].children;
           var item = ogg.find((item:any) => item.name === 'pezzo_1');
           this.emissive_pezzo = item.material.emissive;
           this.renderer.setClearColor( 0x40214a, 1 );

           this.animate();
         }
       },
       (progress:any)=>{
                // durante caricamento
                //var canv = document.getElementById('canvas_game');
                if (this.canvas != null){
                this.canvas.innerHTML = Math.floor((progress.loaded / progress.total * 100 )) + '% loaded';
                this.cd.detectChanges();
                }
            },
            (err:any) => {
                // error
                console.log(err);
                console.log('errore');
           }
        );
      }


  animate():void {
    this.renderer.setAnimationLoop(() => {
      if (this.renderer.domElement.parentElement){
        var container = this.renderer.domElement.parentElement;
        this.camera.lookAt(this.scene.position);
        this.camera.updateMatrixWorld();

        var box = container.getBoundingClientRect();
        if (box.width != this.oldCH || box.height != this.oldCH){
          var box = container.getBoundingClientRect();
          this.renderer.setSize(box.width - 8, box.height - 8);
          this.renderer.render( this.scene, this.camera );
          this.camera.aspect = box.width/box.height;
          this.camera.lookAt(this.scene.position);
          this.camera.updateProjectionMatrix();
          this.oldCH = box.width;
          this.oldCW = box.height;
        }
        this.controls.update();
      }
      if (this.procedi_con_animazione){
        this.animazione_spostamento();
      }
    });
  }

  animazione_spostamento():void{
    var vector:any = new THREE.Vector3();
      this.pezzo_selezionato.geometry.computeBoundingBox();
      var vettore_ps = this.pezzo_selezionato.geometry.boundingBox.getCenter(vector);
     // console.log(this.pezzo_selezionato.position.y, "-", this.posizione_arrivo_y);
        if (this.pezzo_selezionato.position.y > this.posizione_arrivo_y){
          this.pezzo_selezionato.position.y -= 0.2;
          vector = null;
          this.pezzo_selezionato.geometry.boundingBox = null;
        } else {
          this.pezzo_selezionato.position.y =  this.posizione_arrivo_y
          this.procedi_con_animazione = false;
          vector = null;
          this.resetta_selezioni();

        };

  }






}
