<p-dialog
[header]="titolo"
[visible]="dialog"
[modal] = true
>
  <div class="messaggio">{{testo_messaggio}}</div>
</p-dialog>



<div *ngIf="!showSpinner">

  <h1>Risparmio famiglia : {{somma}}€</h1>


  <p-table [value]="arraySett" styleClass="p-datatable-gridlines" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th>Settimana</th>
        <th>Busta 1</th>
        <th>Busta 2</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-risparmio>
      <tr>
        <td>{{risparmio.settimana}}</td>
        <td>{{risparmio.cifra_1}}</td>
        <td>{{risparmio.cifra_2}}</td>
      </tr>
    </ng-template>

  </p-table>


  <br>

  <div style = 'margin-bottom: 40px;' >
    <p-button class='p-button-rounded' *ngIf="getBusteTot() > 0" (onClick)='aggiungiNew()' label="Aggiungi settimana" ></p-button>
  </div>



</div>



<div class='avviso' *ngIf="showSpinner">
  <div><p-progressSpinner
    strokeWidth = 5
    ></p-progressSpinner></div>
  <div class='testo_avviso'>LOADING....</div>
</div>
