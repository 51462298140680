import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';


@Component({
  selector: 'app-gallery3d',
  templateUrl: './gallery3d.component.html',
  styleUrls: ['./gallery3d.component.scss'],
  animations: [
    // animation triggers go here
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({opacity: 1}))
      ])
    ])
  ]
})
export class Gallery3dComponent implements OnInit {

  public descrizione:string = "";
  public linkoggetto: SafeResourceUrl
  public inizio = false;

  public oggetti3d : { [key: string]: string } = {
    "lifter": "https://p3d.in/e/nXdVp",
    "bono": "https://p3d.in/e/oVBki",
    "sting": "https://p3d.in/e/pFsY9",
    "crepuscolo": "https://p3d.in/e/gRJQn",
    "fungo": "https://p3d.in/e/XgoQC"
  }

  constructor(private sanitizer: DomSanitizer) {
     this.linkoggetto = "";
   }

  ngOnInit(): void {
  }

  caricaOggetto(nome: string, desc: string){
    this.inizio = true;
    //this.linkoggetto = this.oggetti3d[nome]
    this.linkoggetto = this.sanitizer.bypassSecurityTrustResourceUrl(this.oggetti3d[nome]);
    this.descrizione = desc;

 }


}
