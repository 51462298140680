import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Funzioni_meteo } from "./meteo_functions";
import { HttpService } from '../services/http.service';
import { environment } from 'src/environments/environment';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

interface cities {
  value: number,
  viewValue: string,
  nomenclatura: string
}

@Component({
  selector: 'app-stazione-meteo',
  templateUrl: './stazione-meteo.component.html',
  styleUrls: ['./stazione-meteo.component.scss'],
  animations: [
    // animation triggers go here
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({opacity: 1}))
      ])
    ])
  ],
  providers: [Funzioni_meteo]
})
export class StazioneMeteoComponent implements OnInit, AfterViewInit, OnDestroy{

  private richiestaDati_att: any;
  //private richiestaListacitta: any;
  //private proxy = "https://cors-anywhere.herokuapp.com/";
  public weatherapi_key= "3925716c1d2840dc9fb204852211601";
  public key_geo = "1029ea0d458a4b179faaaa1d86fd3cae";

  public nascondi_nome = false;
  public citta = 'Bologna' //default
  public blocco_click = false;
  //public old_citta = 'Bologna';
  public coord = "";
  public old_coord = "";
  public visibile = false;


  public situazione_att: any;
  public situazione_att_icon: any;
  public temp_att: any;
  public temp_att_max: number = 0;
  public temp_att_min: number = 0;
  public temp_att_percepita: any;
  public vento_att: any; // mph
  public gradi_dir_vento: number = 0;
  public umidita_att: any;
  public sorge: string = "";
  public tramonta: string = "";
  public last_updated:string = "";
  public regione:string = "";
  public stato:string = "";
  public mm_pioggia:number = 0;
  //public possibilita_neve:number = 0;

  public nomenclatura: string = "";

  public data_odierna: string = "";

  public previsione_data_1:string = "";
  public previsione_data_2:string = "";
  public previsione_testo_1:string = "";
  public previsione_testo_2:string = "";
  public previsione_icona_1:string = "";
  public previsione_icona_2:string = "";
  public previsione_min_1:number = 0;
  public previsione_min_2:number = 0;
  public previsione_max_1:number = 0;
  public previsione_max_2:number = 0;

  public cities_options:cities[] = []
  public selected_city = 0;
  public lista_coord:string[] = [];
  public risultati:any[] = [];
  public qualita_aria:string[] = ["BUONA", "DISCRETA", "MEDIOCRE", "POCO SALUBRE", "INSALUBRE", "MOLTO INSALUBRE"];
  public indice_qualita_aria = 0;
  public path_ico_qa = "";

  dialog = false;
  titolo='';
  testo_messaggio = ''

  datamodal = {};


  constructor(public funzioni:Funzioni_meteo, public http: HttpService) { }

  ngOnInit(): void {
    const d = new Date();
    //console.log(d);
      this.data_odierna = d.getFullYear() + "-" + d.getMonth()+1 + "-" + d.getDate();
      this.riempi_cities_options("44.50379943847656,11.342700004577637/Bologna", "init");
      //this.getLocation(); // temporaneamente disattivato
  }

  ngAfterViewInit(): void {
  }

  openAlert(testo:string, titolo:string='') {
    this.testo_messaggio = testo;
    this.dialog = true;
  }

  prepareDropdown(){

  }

  // inizio geo localizazione ============================================
   /* getLocation(): void{
      this.richiestaDati_att = this.http.getData2("https://api64.ipify.org?format=json").subscribe(
        data => {
          console.log(data);
          var ip = data.ip;
          //console.log(ip);
          this.geoLocalization(ip);
        },
        error => {
          this.geoLocalization("2.34.132.199");
        }
      )
   }*/

  /* geoLocalization(myip:string){
    //const access_key = "462ba56677e050bf5f1db3e1931d1850";
    this.richiestaDati_att = this.http.getData2("https://ipapi.co/"+ myip +"/json/").subscribe(  data => {
    //this.richiestaDati_att = this.http.getData2("http://api.ipstack.com/" + myip +"?access_key=" + access_key).subscribe(  data => {
      //console.log(data);
      var citta = data.city;
      var coord = data.latitude + "," + data.longitude;
      //console.log(coord);
      this.riempi_cities_options(coord + "/" + citta, "init");
    },
      error => {
        this.riempi_cities_options("44.50379943847656,11.342700004577637/Bologna", "init");
      }
    );
   }*/
   // fine geo localizazione ============================================

   riempi_cities_options(citta:string, flag?: string){
    const th = this;
    let vett:string[];

    if (flag){
      vett = citta.split("/");
      citta = vett[1];
    }

    citta = this.funzioni.formatta_citta(citta); // maiuscole e minuscole

    if ((citta.trim()).length > 1){
      this.citta = citta;
      //this.citta = this.funzioni.formatta_citta(citta);
    this.richiestaDati_att = this.http.getData2("https://api.opencagedata.com/geocode/v1/json?q="+ citta +"&key=" + this.key_geo + "&language=it").subscribe(  data => {
      //console.log("dati generali");
      //console.log(data);
      th.lista_coord = [];
      th.cities_options = [];

      if (data.results.length > 0){

        let indice = 0;
        this.risultati = []; // reset


        for (let i = 0; i < data.results.length; i++) {
          if (data.results[i].components["_category"] == "place" && data.results[i].components["_type"] != "neighbourhood" && data.results[i]["formatted"].includes(citta) /*&& !this.funzioni.hasNumber(data.results[i]["formatted"])*/ /*&& data.results[i].components["_type"] != "state"*/) {

            this.risultati.push(data.results[i]);

            const lat =data.results[i].geometry["lat"];
            const lng =data.results[i].geometry["lng"];
            const coord = lat + "," + lng;
            th.lista_coord.push(coord);

            let  nome_citta = "";

            if (data.results[i].components[data.results[i].components["_type"]]){
              nome_citta = data.results[i].components[data.results[i].components["_type"]];
            } else if ( data.results[i].components["hamlet"]) {
              nome_citta = data.results[i].components["hamlet"];
            } else if (data.results[i].components["town"]) {
              nome_citta = data.results[i].components["town"];
            } else if (data.results[i].components["city"]) {
              nome_citta = data.results[i].components["city"];
            }

            let nome_stato = ""

            if ( data.results[i].components["state"]){
              nome_stato = data.results[i].components["state"];
            } else if (data.results[i].components["country"]){
              nome_stato = data.results[i].components["country"];
            }

            th.cities_options.push({ value: indice, viewValue: nome_citta + ", " + data.results[i].components["_type"] + ", " + nome_stato, nomenclatura: nome_citta});

            indice ++
          }
        }

         //console.log(this.risultati);

        if (th.cities_options.length > 0){
          th.selected_city =  0;
        }
        if (flag){
          //console.log(vett);
          this.richiesta_dati_forecast(vett[0], this.data_odierna, vett[1], 0);
          //this.richiesta_dati_forecast(this.lista_coord[0], this.data_odierna, this.cities_options[0].nomenclatura, 0);
        }
      }
    }, err => {
      //console.log(err);
    });
  }

}

  richiesta_dati_forecast(coord: string, data_g:string, nomenclatura:string, indice: number){

    // l'indice serve per eventuali informazioni che si possono trarre dal servizio opencagedata (this.risultati[indice])

   this.visibile=false;
   this.richiestaDati_att = this.http.getData_weather(environment.url_weatherapi, this.weatherapi_key, coord).subscribe(  (data:any) => {

   //console.log(data);

   try {

       this.sorge = this.funzioni.converti_orario(data.forecast.forecastday[0].astro["sunrise"]);
       this.tramonta = this.funzioni.converti_orario(data.forecast.forecastday[0].astro["sunset"]);

       let codeLang = data.current.condition["code"];

       //console.log(codeLang);

       var day_night = data.current["is_day"];
       //codeLang = 1264 // DEBUG - da eliminare!
       this.situazione_att = this.funzioni.traduci(parseInt(codeLang), day_night);
       //this.situazione_att_icon = data.current.condition["icon"];
       this.situazione_att_icon = this.funzioni.trova_icona(data.current.condition["icon"]);
       //this.situazione_att = data.current.condition["text"];

       this.temp_att = data.current["temp_c"];
       this.temp_att_percepita = Math.floor(data.current["feelslike_c"]);
       this.vento_att = Math.floor(parseInt(data.current["wind_mph"]) * 1.609344);
       this.gradi_dir_vento = data.current["wind_degree"];
       this.umidita_att = data.current["humidity"];
       this.mm_pioggia = data.current["precip_mm"];

       this.last_updated = this.formatta_data(data.current["last_updated"], true);

       this.temp_att_min = Math.floor(data.forecast.forecastday[0].day["mintemp_c"]);
       this.temp_att_max =  Math.floor(data.forecast.forecastday[0].day["maxtemp_c"]);
       this.regione = data.location["region"];
       this.stato = data.location["country"];

       const pm10 = data.current.air_quality["pm10"];
       const no2 = data.current.air_quality["no2"];
       const o3 = data.current.air_quality["o3"];

       this.indice_qualita_aria = this.funzioni.calcolo_indice_qualita_aria([pm10, no2, o3]);

       //this.indice_qualita_aria = data.current.air_quality["us-epa-index"];
       this.path_ico_qa = "assets/img/air-quality/aqi-"+ this.indice_qualita_aria +".png";

       // Previsione
       this.previsione_data_1 = this.formatta_data(data.forecast.forecastday[1]["date"], false);
       this.previsione_data_2 = this.formatta_data(data.forecast.forecastday[2]["date"], false);
       this.previsione_icona_1 = this.funzioni.trova_icona(data.forecast.forecastday[1].day.condition["icon"]);
       this.previsione_icona_2 = this.funzioni.trova_icona(data.forecast.forecastday[2].day.condition["icon"]);
       codeLang = data.forecast.forecastday[1].day.condition["code"];
       this.previsione_testo_1 = this.funzioni.traduci(parseInt(codeLang), "1");
       codeLang = data.forecast.forecastday[2].day.condition["code"];
       this.previsione_testo_2 = this.funzioni.traduci(parseInt(codeLang), "2");
       this.previsione_min_1 = Math.floor(data.forecast.forecastday[1].day["mintemp_c"]);
       this.previsione_max_1 = Math.floor(data.forecast.forecastday[1].day["maxtemp_c"]);
       this.previsione_min_2 = Math.floor(data.forecast.forecastday[2].day["mintemp_c"]);
       this.previsione_max_2 = Math.floor(data.forecast.forecastday[2].day["maxtemp_c"]);
       // =====

       this.nomenclatura = nomenclatura;
       this.nascondi_nome = false;
       this.visibile=true;

   }catch (e:any) {
       //console.log(e);
       this.openAlert('Problema di ricezione dati dal server. Ricarica la pagina');
   }

  }, (err:any) => {
      this.openAlert('Problema di ricezione dati dal server. Ricarica la pagina');
  });
}


formatta_data(data:string, flag:boolean):string{
  //"2021-01-02 14:45"
  let data_di_ritorno = ""
  if (flag){
   let bl = data.split(" ");
   let orario = bl[1];
   let dl = bl[0].split("-");
   let giorno = dl[2] + "/" + dl[1] + "/" + dl[0];
    data_di_ritorno = giorno + " alle ore " + orario;
  } else {
    let dl = data.split("-");
    data_di_ritorno = dl[2] + "/" + dl[1] + "/" + dl[0];
  }
  return data_di_ritorno;
}


mostra_select(){
if (!this.blocco_click){
  this.nascondi_nome = true;
} else {
  this.blocco_click = false;
}
}

ngOnDestroy(){
  // this.richiestaListacitta.unsubscribe();
   this.richiestaDati_att.unsubscribe();
 }

}
