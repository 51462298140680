<div>
  <p-menubar [model]="itemsbg">

  <ng-template pTemplate="start">
    <b><h3 style="color:white;margin-left:40px;cursor:pointer;" (click)="vaia('/')">BGHOME</h3></b>
  </ng-template>

  <ng-template pTemplate="end">

  </ng-template>

  </p-menubar>
</div>

