import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Globals } from './globals';
import {tap} from 'rxjs/operators'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'bghome';
  phone = false;
  http: any;

  constructor(private deviceService: DeviceDetectorService, public global: Globals){}

  ngOnInit(): void {
    //titlebar fissa inmodalità landscape nel mobile
    if(this.deviceService.isMobile()){
        this.global.isMobile = true
    }

    //this.getTextFile('./app.component.html').subscribe( (data:any) => console.log(data))
  }

 /* getTextFile(filename: string) {
    // The Observable returned by get() is of type Observable<string>
    // because a text response was specified.
    // There's no need to pass a <string> type parameter to get().
    return this.http.get(filename, {responseType: 'text'})
      .pipe(
        tap( // Log the result or error
        {
          next: (data) => console.log(data),
          error: (error) => console.log(filename, error)
        }
        )
      );
  }*/
}

