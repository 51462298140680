// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sitedb : 'https://testtower-98bd.restdb.io/rest/punteggi',
  xapi: '5eb6c94fa020071c9ca8140b',
  sitedb_droplet : 'https://testdroplet-5f5c.restdb.io/rest/punteggio',
  xapi_droplet: '5f4a402d3abd4e679e244d9f',
  //mail_form_url:'https://formspree.io/mzbjzjjz', //mail a vurda69
  url_weatherapi: "https://api.weatherapi.com/v1/forecast.json"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
