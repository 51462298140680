import { Injectable } from '@angular/core';
//import { allSettled } from 'q';


@Injectable({
  providedIn: 'root'
})

export class Globals {


    isMobile = false;

    public hide_covid = false;
    public hide_news = false;
    public istanzia_oggetto_3d = true;
    public datasource:any[] = [];

    public vaccinazioni = {
      vaccinatiTotali: 0,
      giornoVaccinati: '',
      errorVaccinati: false,
      percentualeVaccinati: 0,
    }

    public datiStat = {
      anagrafica_anni: {},
      sesso_maschile: 0,
      sesso_femminile: 0,
      /*operatori_sanitari: 0,
      personale_nonsanitario: 0,*/
      prima_dose: 0,
      seconda_dose: 0,
      pregressa_inf: 0
    };

    public news:any;


    constructor(){

    }


}
