<div @fade class='benvenuti'>

  <div  class='nomeg'>Giampaolo Bottega</div>

 <div>
   <img class='miafoto' src="assets/img/Bottega.jpg" alt="Avatar">
 </div>

 <div id='canvas'></div>

 <div class="testo_pr">
   Questo sito, da me realizzato, vuole essere una semplice vetrina di materiale dimostrativo delle mie capacità grafiche e di programmazione.<br>
 </div>
</div>
<br>
