import { Injectable } from '@angular/core';
import {SolitarioComponent} from './solitario.component';

@Injectable()

export class Funzioni_solitario {
// =============================================================================
// === MOSSE RIMASTE ===========================================================
// =============================================================================

dammi_mosse_rimaste(campogioco:any){
    let mosse = 0;
    for ( let colonna = 0; colonna < campogioco.length; colonna++) {
        for ( let riga = 0; riga < campogioco[colonna].length; riga++) {

            //console.log(colonna, '-', riga);

            if(campogioco[colonna][riga]==0){

                if(campogioco[colonna][riga-2]){
                    if (campogioco[colonna][riga-1] == 1 && campogioco[colonna][riga-2] == 1){
                        mosse++;
                    }
                }
                if(campogioco[colonna][riga+2]){
                    if (campogioco[colonna][riga+1] == 1 && campogioco[colonna][riga+2] == 1){
                        mosse++;
                    }
                }

                if(campogioco[colonna+2]){
                    if (campogioco[colonna+1][riga] == 1 && campogioco[colonna+2][riga] == 1){
                        mosse++;
                    }
                }
                if(campogioco[colonna-2]){
                    if (campogioco[colonna-1][riga] == 1 && campogioco[colonna-2][riga] == 1){
                        mosse++;
                    }
                }

            }
       }
    }

    return mosse;
  }

    // ===========================================================
    // ===========================================================
    // ===========================================================

    verifica_mossa(campo_gioco:any, pez_sel:string, col_sel:string){
        let valutazione_mossa:any =  [];
        let mossa_consentita = false;
        let orizzontale = false;
        let positivo = false;

       //console.log(campo_gioco, '-' , pez_sel, col_sel);

       let ped_riga = parseInt((pez_sel.split('_'))[1]);
       let ped_colonna = parseInt((pez_sel.split('_'))[2]);

       let col_riga = parseInt((col_sel.split('_'))[1]);
       let col_colonna = parseInt((col_sel.split('_'))[2]);

       if (ped_riga == col_riga){
           // mossa orrizontale
           if (ped_colonna == col_colonna + 2){
             if (campo_gioco[ped_riga][col_colonna+1]==1){
                 mossa_consentita = true;
                 positivo = false;
             }
           }
           if (ped_colonna == col_colonna - 2){
            if (campo_gioco[ped_riga][col_colonna-1]==1){
                mossa_consentita = true;
                positivo = true;
            }
           }
           orizzontale = true;
       }

       if (ped_colonna == col_colonna){
          // mossa verticale
          if (ped_riga == col_riga + 2){
            if (campo_gioco[ped_riga-1][ped_colonna]==1){
                mossa_consentita = true;
                positivo = false;
            }
          }
          if (ped_riga == col_riga - 2){
           if (campo_gioco[ped_riga+1][col_colonna]==1){
               mossa_consentita = true;
               positivo = true;
           }
          }
          orizzontale = false;
       }

        valutazione_mossa.push(mossa_consentita);
        valutazione_mossa.push(orizzontale);
        valutazione_mossa.push(positivo);

        return valutazione_mossa;
    }

    // ===========================================================
    // ===========================================================
    // ===========================================================

    muovi_array(campo_gioco:any, pez_sel:string, col_sel:string, valutazione:any){
       let array_campo = campo_gioco;

       let ped_riga = parseInt((pez_sel.split('_'))[1]);
       let ped_colonna = parseInt((pez_sel.split('_'))[2]);

       let col_riga = parseInt((col_sel.split('_'))[1]);
       let col_colonna = parseInt((col_sel.split('_'))[2]);

       array_campo[ped_riga][ped_colonna] = 0;

       if (valutazione[1]){ // orizzontale

            if (valutazione[2]){ //positivo
              array_campo[ped_riga][ped_colonna+2] = 1;
              array_campo[ped_riga][ped_colonna+1] = 0;
            } else { // negativo
              array_campo[ped_riga][ped_colonna-2] = 1;
              array_campo[ped_riga][ped_colonna-1] = 0;
            }

       } else { // verticale

            if (valutazione[2]){ //positivo
               array_campo[ped_riga+2][ped_colonna] = 1;
               array_campo[ped_riga+1][ped_colonna] = 0;
            } else { // negativo
                array_campo[ped_riga-2][ped_colonna] = 1;
                array_campo[ped_riga-1][ped_colonna] = 0;
            }
       }
       return array_campo;
    }


}
