import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { HttpService } from '../services/http.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subscriber } from 'rxjs';



@Component({
  selector: 'app-classificagiochi',
  templateUrl: './classificagiochi.component.html',
  styleUrls: ['./classificagiochi.component.scss']
})
export class ClassificagiochiComponent implements OnInit, OnDestroy {

  private datiGioco:any;
  public datasource:any = [];
  public hide_table = false;
  public nome_campo_punti = '';
  public data:any;
  dialog = false;
  titolo='';
  testo_messaggio = ''


  displayedColumns: string[] = ['posizione', 'nome', 'punteggio'];

  constructor(public http: HttpService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.data = this.config.data;

    if (this.data.name == "torre"){
      this.nome_campo_punti = 'Mosse';
    } else
    if (this.data.name == 'droplet'){
      this.nome_campo_punti = 'Punti';
    }

    this.datiGioco = this.http.ricezioneDati(this.data.name).subscribe ((data: any) => {

      var th = this;

      if (data.length > 0){
      if (th.data.name == 'torre'){
        data.sort(function (a:any, b:any) {
          return a.punteggio - b.punteggio;
        });
      } else
      if (th.data.name == 'droplet'){
        data.sort(function (a:any, b:any) {
          return b.punteggio - a.punteggio;
        });
      }

       var posizione = 1;
       data.forEach((element:any) => {
         th.datasource.push({ posizione: posizione, nome: element.nome, punteggio: element.punteggio});
         posizione ++
       });
      }


      this.hide_table = true;

    }, (err:any) => {
      console.log(err);
      console.log("Error occured");
      this.openAlert('Problema di connessione. Ricarica la pagina');
       }
    );
  }

 /* openAlert(testo:string) {
    this.dialog.open(AlertComponent, {data: { testo_messaggio : testo}, autoFocus: true, disableClose : false});
  }*/

  openAlert(testo:string, titolo:string='') {
    this.testo_messaggio = testo;
    this.dialog = true
  }

  ngOnDestroy():void{
    this.datiGioco.unsubscribe();
  }

}
