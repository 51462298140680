<p-dialog [header]="titolo"
[(visible)]="dialog"
[modal] = true
>
  <div class="messaggio">{{testo_messaggio}}</div>
</p-dialog>

<div @fade *ngIf="hide_news">


<div class="datiNews">

   <div class='titolo'>News</div>

  </div>

  <!--<div class="searchbar">
    <div style="margin-right: 10px;">
      <button mat-raised-button color="primary" (click)="ricerca()">Ricerca</button>
    </div>
    <div>
      <mat-form-field class="example-form-field">
        <input matInput type="text"  [(ngModel)]="valore_campo_ricerca" height="100px">
      </mat-form-field>
    </div>
  </div>-->

  <div class="panel-c">
    <div style="color:white;font-size: x-large;">Categoria: </div>
    <div>
      <p-dropdown [options]="categorie" [(ngModel)]="sel" (onChange)="resetScrollPos()" [style]="{'width':'160px'}"></p-dropdown>
    </div>
  </div>


  <div class="contenuto_notizie">

   <!--<div class="argomenti"-->

   <!-- <mat-radio-group
    aria-labelledby="news"
    class="example-radio-group"
    [(ngModel)]="sel">
    <mat-radio-button class="example-radio-button" *ngFor="let cat of categorie; let index = index" [value]="cat" (click)="resetScrollPos()">
      {{traduci(cat)}}
    </mat-radio-button>
  </mat-radio-group>-->

  <!--<div *ngFor="let cat of categorie; let index = index" >
    <p-radioButton name="sel" [inputId]="cat" [value]="cat" [(ngModel)]="sel" (onClick)="resetScrollPos()"></p-radioButton>
    <label [for]="cat">{{traduci(cat)}}</label>
  </div>-->


  <!--</div>-->

    <div id="cont" class="contenuti">
      <div *ngFor="let data of global_data | filterNews: traduci_ita_eng(this.sel)" class="contenuto_notizia">

        <div class="notizia">

            <div class="contenuto_notizia">
                  <div class="title">
                    {{getTitle(data.title)}}
                  </div>

                  <div class="image" *ngIf="data.image != 'None'">
                    <img #image [src]="data.image" (error)="image.hidden = true" [hidden]="image.hidden">
                </div>

                  <div class="descrizione">
                    {{getTitle(data.description)}}
                </div>

                <div class="pubblicazione">
                    {{formattaData(data.published)}}
                </div>

                <div class="pubblicazione">
                  Autore: {{data.author}}
                </div>


                <div class="paginaweb">
                  <a [href]="data.url" target="_blank" rel="noopener noreferrer">leggi...</a>
                </div>
            </div>

        </div>

      </div>

    </div>

  </div>

</div>


  <div class='avviso' *ngIf="!hide_news">
    <div><p-progressSpinner
      strokeWidth = 5
      ></p-progressSpinner></div>
    <div class='testo_avviso'>{{testo_loading}}</div>
  </div>










