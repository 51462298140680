<p-dialog [header]="titolo"
[(visible)]="dialog"
[modal] = true
>
  <div class="messaggio">{{testo_messaggio}}</div>
</p-dialog>

<!--<p-button label="test" (onClick)="openAlert({testo_messaggio: 'prova testo', titolo: 'prova'})"></p-button>-->


<div @fade class='meteo' *ngIf=visibile>

  <div class='titolo'>Meteo</div>

    <div class='w-testata'>

      <div style="cursor: pointer;" [class.citta]='!nascondi_nome' [class.citta_op]='nascondi_nome' (click)='mostra_select()'>

        <div *ngIf='!nascondi_nome' class="spazio_citta">

          <div style="display:flex;align-items: center;flex-direction: column;margin-top: 10px;">
            <div  style="margin-bottom: 15px;margin-top: 1px; height: 30px" class='testo'>{{nomenclatura}}</div>
            <div *ngIf="regione" style="font-size: small;margin-top: 5px;">({{regione}})</div>
            <div *ngIf="stato" style="font-size: small;margin-top: 2px;">({{stato}})</div>
          </div>

          <div  class="testo_scritta_sub"> Premi qui per cambiare città </div>
        </div>


        <div *ngIf='nascondi_nome'>
          <div style="display:flex;flex-direction: row;align-items: center;">

           <div class="input-group">

              <span class="input-citta">
                <label for="input_citta" style="font-size:12px;">Inserire il nome della città</label>
                <input id="input_citta" class="input-citta-field" type="text" pInputText [(ngModel)]='citta' [placeholder]='citta'>
              </span>

              <p-dropdown [options]="cities_options" [(ngModel)]="selected_city" optionLabel="viewValue" optionValue="value"></p-dropdown>

           </div>

          <div class="buttons-group">

             <div style="margin-left: 20px;margin-top:5px;">
               <p-button
                   label="Cerca"
                   (onClick)="riempi_cities_options(citta)">
               </p-button>
            </div>

             <div style="margin-left: 20px;margin-top:5px;">
                <p-button
                   [disabled]="cities_options.length === 0"
                   (onClick)="richiesta_dati_forecast(lista_coord[selected_city], data_odierna, this.cities_options[selected_city].nomenclatura, selected_city)" label="Carica"
                   >
                </p-button>
            </div>

          </div>

          </div>

        </div>


      </div>

      <!-- // -->
      <div class='icona'>
         <div> <img class='ico_t' [src]='situazione_att_icon'> </div>
         <div class='scritte sit'>{{situazione_att}}</div>
      </div>
      <!-- // -->

   </div>

   <div class='w-testata'>
      <div class='icona'>
          <div> <img class='ico_temp' src='assets/img/thermometer-2.png'> </div>
          <div class='valori'>{{temp_att}}&deg;</div>
          <div class='dati_temp'>

            <div class="col_nomenclatura_temp">
              <div class='src_min scritte'>Min </div>
              <div class='scritte src_max'>Max </div>
              <div class='scritte src_perc'>Perc </div>
            </div>

            <div class="col_nomenclatura_temp">
              <div class='scritte_temp'>
                <div style="width:fit-content;">{{temp_att_min}}&deg;</div>
              </div>
              <div class='scritte_temp'> {{temp_att_max}}&deg;</div>
              <div class='scritte_temp'> {{temp_att_percepita}}&deg;</div>
            </div>

           </div>
      </div>
      <div class='icona'>
         <div style="display:flex; flex-direction: column; align-items: center;">
            <div>Qualità aria</div>
            <div><img [src]="path_ico_qa" style="width: 50px;margin-top:-2px;margin-bottom:5px;"></div>
            <div style="margin-top:-10px">{{qualita_aria[indice_qualita_aria-1]}}</div>
         </div>


          <div> <img class='ico_wind' src='assets/img/wind.png'> </div>
       <div class = 'vv'>
          <div  class='valori v_vento'>{{vento_att}}</div>
          <div style='font-size: small;margin-top:15px;margin-left:2px;'> km/h</div>
          <div style="margin-left: 15px;"><app-compass-gauge [gradi_dir]="gradi_dir_vento"></app-compass-gauge></div>
       </div>
      </div>
      <div class='icona' style="flex-direction: column;">

        <div style="display:flex;align-items: center;justify-content: space-between;width:100px;">
          <div> <img class='ico_umidita' src='assets/img/humidity.png'> </div>
          <div style="display: flex;flex-direction: row;align-items: baseline;flex: auto;">
          <div  class='valori_um'>{{umidita_att}}</div>
          <div> %</div>
          </div>
        </div>

          <div style="display:flex;align-items: center;justify-content: space-between;width:100px;">
            <div> <img class='ico_umidita' src='assets/img/rainy.png'> </div>
            <div style="display: flex;flex-direction: row;align-items: baseline;flex: auto;">
              <div  class='valori_um'>{{mm_pioggia}}</div>
              <div>mm</div>
            </div>
          </div>



      </div>
  </div>

  <div class='w-testata'>
      <div class='sole'>
          <div  class='valori_sole'>
              <div class='scr_sole'> Il sole sorge alle {{sorge}} </div>
               <div class='scr_sole'>tramonta alle {{tramonta}}</div>
          </div>
      </div>
  </div>

  <div class='w-testata'>
    <div class='previsione'>
      <div class='scr_previsione'>
        <div style="margin-top:5px">{{previsione_data_1}}</div>
       <div class="prev_t">
        <div style="display:flex;flex-direction: column;justify-content: center;">

         <!-- <div style="display:flex;flex-direction: row;">
            <div style="width:35px;">min</div>
            <div> {{previsione_min_1}}&deg;</div>
          </div>
          <div style="display:flex;flex-direction: row;">
            <div style="width:35px;">max</div>
            <div> {{previsione_max_1}}&deg;</div>
          </div>-->

          <div style="display:flex;flex-direction: row;">

             <div style="display:flex;flex-direction: column;">
              <div style="width:40px;">min</div>
              <div style="width:40px;">max</div>
             </div>

             <div style="display:flex;flex-direction: column;">
              <div style="display:flex;align-self: flex-end;"> {{previsione_min_1}}&deg;</div>
              <div style="display:flex;align-self: flex-end;"> {{previsione_max_1}}&deg;</div>
             </div>

          </div>

         <!-- <div> min : {{previsione_min_1}}&deg;</div>
          <div> max : {{previsione_max_1}}&deg;</div>-->
         </div>
         <div><img class='ico_t' [src]='previsione_icona_1'></div>
      </div>
       <div class="scritte_prev">{{previsione_testo_1}}</div>
      </div>
      <div class='scr_previsione'>
        <div style="margin-top:5px">{{previsione_data_2}}</div>
        <div class="prev_t">
          <div style="display:flex;flex-direction: column;justify-content: center;">

            <div style="display:flex;flex-direction: row;">

              <div style="display:flex;flex-direction: column;">
               <div style="width:40px;">min</div>
               <div style="width:40px;">max</div>
              </div>

              <div style="display:flex;flex-direction: column;">
               <div style="display:flex;align-self: flex-end;"> {{previsione_min_2}}&deg;</div>
               <div style="display:flex;align-self: flex-end;"> {{previsione_max_2}}&deg;</div>
              </div>

           </div>

           <!-- <div style="display:flex;flex-direction: row;">
              <div style="width:35px;">min</div>
              <div> {{previsione_min_2}}&deg;</div>
            </div>
            <div style="display:flex;flex-direction: row;">
              <div style="width:35px;">max</div>
              <div> {{previsione_max_2}}&deg;</div>
            </div>-->

            <!--<div> min : {{previsione_min_2}}&deg;</div>
            <div> max : {{previsione_max_2}}&deg;</div>-->
       </div>
          <div><img class='ico_t' [src]='previsione_icona_2'></div>
        </div>
           <div class="scritte_prev">{{previsione_testo_2}}</div>
        </div>
    </div>
  </div>


  <div class='didascalia'>
    <div>Aggiornato il giorno {{last_updated}}</div>
    <div>Powered by <a href="https://www.weatherapi.com/" title="Free Weather API">WeatherAPI.com</a></div>
  </div>

</div>


<div class='avviso' *ngIf="!visibile">
  <div><p-progressSpinner
    strokeWidth = 5
    ></p-progressSpinner></div>
  <div class='testo_avviso'>LOADING....</div>
</div>
