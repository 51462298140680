
<div @fade style="margin-bottom: 50px;">
      <div class="gallery">
          <div class='titolo'>GALLERY</div>
          <div class='testo_pr'>
            Vari lavori grafici realizzati a titolo di esempio.
          </div>
          <br>
      </div>


    <div style="text-align:center;margin:0; padding:0;background-color:black;padding-top:20px; padding-bottom:20px;">
      <ng-image-slider [images]="imageObject" #nav
      [manageImageRatio] = true
      [imageSize]="{width: '250px', height: ''}"
      [animationSpeed] = "0.5"
      [infinite] = "true"
      [autoSlide] = "false"
      [videoAutoPlay]="true"
      ></ng-image-slider>
    </div>

</div>

<!--<div class='avviso' *ngIf="showspinner">
  <div><p-progressSpinner></p-progressSpinner></div>
  <div class='testo_avviso'>LOADING....</div>
</div>-->
