<!--<div class='master_game'>-->

  <p-dialog [header]="titolo"
  [(visible)]="dialog"
  [modal] = true
  >
    <div class="messaggio">{{testo_messaggio}}</div>
  </p-dialog>


  <div class='game' @fade>

    <div>Torre di Hanoi</div>

    <div *ngIf="!hide_avviso" class='avviso' >
        <p-progressSpinner
            [style]="{width: '40px', height: '40px'}"
            styleClass="custom-spinner"
            strokeWidth="5"
            animationDuration=".5s"
        ></p-progressSpinner>
        <div class='testo_avviso'>LOADING....</div>
    </div>

    <div *ngIf="hide_avviso" style='font-size: large;'>{{record}}</div>

    <div class="butt">
      <p-button *ngIf='viualizza_pulsante_classifica' class=' p-button-rounded' (onClick)='openDialogClassifica()'>Classifica</p-button>
    </div>

    <div class='punteggio'>{{scritta_testata}}</div>


    <div id='canvas_game' (click)="onClick($event)" (touchstart)="onClick($event)"></div>


    <div class='pulsanti'>
    <div class="butt"><p-button #btb class='p-button-rounded'  (onClick)='nuova_partita()'>Nuova partita</p-button></div>
    <div class="butt"><p-button class='p-button-rounded'  (onClick)='openDialog()'>Istruzioni</p-button></div>

    <div class='check' *ngIf='controlli_audio'>
            <p-checkbox
              (onChange)="music($event)"
              label="Music"
              [binary]="true"
              name="fieldMusic"
              ngDefaultControl
              [(ngModel)]="checked"
              ></p-checkbox>
     </div>

    </div>

</div>

<!--</div>-->


