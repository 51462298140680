<div >
  <div class='contenitore'>
    <div  style="overflow-y: auto; height: 100%;">

      <div *ngIf='hanoi' class='istruzioni'>

        <p>TORRE DI HANOI<br></p>
        <p>Scopo del gioco è spostare la piramide, dalla colonna di partenza, a una delle altre due presenti sulla piattaforma.<br>
          La regola è che non è possibile appoggiare un pezzo su un'altro di dimensione inferiore.<br>
          Per spostare, selezionare prima il pezzo e poi la colonna di destinazione.<br>
          Spostati nell'ambiente 3D ruotando la scena con il mouse o usando il touch del cellulare o del pad.<br>
          Avvicinati o allontanati usando il 'pinch' (gesto di ingrandimento con due dita) o la rotellina del mouse.

          <br>
          <br>
            The aim of the game is to move the pyramid from the starting column to one of the other two on the platform.<br>
            The rule is that it is not possible to place one piece on another of a smaller size.<br>
            To move, first select the part and then the target column.<br>
            Move into the 3D environment by rotating the scene with the mouse or using the touch of your mobile phone or pad.<br>
            Approach or move away using the pinch (two-finger zoom gesture) or mouse wheel.<br>
        </p>

      </div>


      <div *ngIf='solitario' class='istruzioni'>

        <p>Il <b>SOLITARIO DELLA BASTIGLIA</b> &egrave; un
          gioco di logica. Non se ne conosce con sicurezza l'inventore,
          ma diverse fonti attribuiscono l'origine del gioco ad un prigioniero
          della Bastiglia.<br>
          Si sa che fu molto popolare e diffuso nell'Europa
          del 1800, era conosciuto col nome di &quot;piolo solitario&quot;, in
          quanto si giocava su una tavola forata in cui venivano spostati
          e infilati dei piccoli pioli di legno.<br>
          Il gioco consiste nel muovere una pedina alla volta lungo le
          linee orizzontali o verticali, in modo da &quot;saltare&quot; la pedina vicina,
          che cos&igrave; viene eliminata dal tavolo di gioco.<br>
          Il salto della pedina pu&ograve; essere eseguito se il posto di destinazione &egrave; libero.<br>

          La partita termina quando si arriva ad un punto in cui non &egrave; possibile
          eseguire altre mosse.<br>
          Se sulla scacchiera &egrave; presente una sola
          pedina il giocatore ha vinto.<br>
          Una ulteriore sfida consiste nel terminare
          il gioco con l'ultima pedina collocata nella posizione centrale della
          scacchiera.</p>

          <br>
          <br>

          <p>BASTILLE SOLITAIRE is a logic game. The inventor is not known for certain, but several sources attribute the origin
            of the game to a prisoner from the Bastille.<br>
         It is known that it was very popular and widespread in Europe in the 1800s,
         it was known by the name of "solitary peg", as it was played on a perforated table where small wooden pegs were moved and inserted.<br>

        The game consists in moving one piece at a time along the horizontal or vertical lines, in order to "jump"
        the neighboring piece, which is thus eliminated from the game table. The pawn jump can be performed if the target place is free.<br>
        The game ends when you get to a point where no more moves can be made. If there is only one pawn on the board,
        the player has won. A further challenge is to end the game with the last piece placed in the central position of the board.<br>

      </div>

      <div *ngIf='droplet' class='istruzioni'>

        <p>DROPLET GAME</p>
        <br>
        <p>Aiutate la goccia di pioggia a scendere e raggiungere il lago.<br>
        Durante il percorso, diviso in tre fasi, dovrete affrontare degli ostacoli.<br>
        Attenzione alle nuvole, al ghiaccio e alle bolle.<br>
        Se colpite una nuvola la goccia si ingrossa e perdete una vita ma potete recuperarle tutte
          con le pillole che incontrerete,<br>
        Il ghiaccio vi imprigionerà aumentando la velocità di discesa mentre la bolla vi rallenterà gli spostamenti  ma tutto per 10 secondi.<br>
        Non dimenticate di prendere le stelline che valgono 5 punti.<br>
        Se arriverete al lago riceverete un bonus di 500 punti.<br>
        <br>
        Buon divertimento :)

          <br>
          <br>

        <p>DROPLET GAME
        <br>
        Help the raindrop to go down and reach the lake.<br>
        During the course, divided into three phases, you will have to face obstacles.<br>
        Beware of clouds, ice and bubbles.<br>
        If you hit a cloud the drop gets bigger and you lose one life but you can recover them all
    with the pills you will meet,<br>
    The ice will imprison you by increasing the descent speed while the bubble will slow you down but all for 10 seconds.<br>
    Don't forget to take the stars which are worth 5 points.<br>
    If you arrive at the lake you will receive a 500-point bonus.<br>
    <br>
    Have fun :)</p>

    <br>
          <br>



      </div>


    </div>
    </div>
    </div>

