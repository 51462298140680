import { Component, OnDestroy, OnInit } from '@angular/core';
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  animations: [
    // animation triggers go here
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({opacity: 1}))
      ])
    ])
  ]
})
export class WelcomeComponent implements OnInit, OnDestroy{

  public loader:any = new OBJLoader();
  public renderer:any = new THREE.WebGLRenderer( { alpha: true, antialias: true } );

  constructor() { }

  ngOnInit(): void {
    this.caricascritta();
  }

  ngOnDestroy(){
    this.renderer.forceContextLoss();
    delete this.loader;
  }

  rimuovi_contenuto_precedente(){
     var list: any
     list = document.getElementById('canvas');
     if (list.hasChildNodes()) {
     list.removeChild(list.childNodes[0]);
   }
 }

 caricascritta(){
  var th = this;
  var scene = new THREE.Scene();
  var loader = th.loader;
  var renderer = th.renderer;
  var camera = new THREE.PerspectiveCamera( 55, 400/150, 0.1, 1000 );

  var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.4 );
  scene.add( ambientLight );

  var pointLight = new THREE.PointLight( 0xffffff, 0.8 );
  camera.add( pointLight );
  scene.add( camera );

  renderer.setSize( 400, 150 );
  renderer.setPixelRatio( window.devicePixelRatio );

  var root: any;
  function callbackOnLoad ( object3d: any ) {
   var canv: any;
   canv = document.getElementById('canvas');
   if ( document.getElementById('canvas')){
    canv.innerHTML = '';
    th.rimuovi_contenuto_precedente();
    canv.appendChild( renderer.domElement );
   }
    root = object3d;
    root.position.set(0,0,0);
    root.name = 'scritta';
    scene.add(root);
    animate();
  }
  function checkload( object3d: any ) {
    var canv: any;
    canv = document.getElementById('canvas');
    var accapo = '<br><br><br>';
    if (document.getElementById('canvas')){
     canv.innerHTML = accapo + Math.floor((object3d.loaded / object3d.total * 100 )) + '% loaded';
    }
  }
  function erroreload( object3d: any ) {
    console.log('errore');
  }

  loader.load( 'assets/3dobject/welcome.obj', callbackOnLoad, checkload, erroreload );

  camera.position.set(0, 0, 3);

  var animate = function () {
    requestAnimationFrame( animate );
    root.rotation.y += 0.01;
    renderer.render( scene, camera );
  };

}


}
