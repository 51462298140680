import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
  selector: 'soko-modal',
  templateUrl: './soko-modal.component.html',
  styleUrls: ['./soko-modal.component.scss']
})

export class SokoModalComponent implements OnInit {

  public message = "";
  public code = "";
  public current = 0;
  public buttonTxt = "NEXT LEVEL"

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.message = this.config.data.message;
    this.code = this.config.data.codeLevel;
    this.current = this.config.data.currentLevel

    if (this.current === 60){
      this.buttonTxt = "RESTART GAME"
    }
  }

}
