import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, AfterContentInit, AfterContentChecked, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ClassificagiochiComponent } from '../classificagiochi/classificagiochi.component';
import { HttpService } from 'src/app/services/http.service';
import { NomeVincitoreComponent } from '../nome-vincitore/nome-vincitore.component';
import { IstruzionigamesComponent } from '../istruzionigames/istruzionigames.component';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Globals } from '../globals';

@Component({
  selector: 'app-dropletgame',
  templateUrl: './dropletgame.component.html',
  styleUrls: ['./dropletgame.component.scss'],
  providers:[DynamicDialogRef,DynamicDialogConfig],
  animations: [
    // animation triggers go here
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({opacity: 1}))
      ])
    ])
  ]
})
export class DropletgameComponent implements OnInit, OnDestroy, AfterViewInit {

  public punteggio: string = '0';
  public pulsante_visibile = true;
  public datiGioco:any;
  public record:string = '';
  public viualizza_pulsante_classifica = false;
  public dialogRef: any;

  constructor(
    public sanitizer:DomSanitizer,
    public http: HttpService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public global: Globals
    ) { }

  dialog = false;
  titolo='';
  testo_messaggio = ''

  ngOnInit(): void {
    var th = this;

    this.riceviDati_gioco();
    this.punteggio = '0';
    window.addEventListener("resize", this.resize, false);
    // prendo il punteggio ====================
    //var eventMethod = window.addEventListener? "addEventListener" : "attachEvent";
    var eventMethod = "addEventListener"
    var eventer = window.addEventListener;
    var messageEvent = "message";
    var th = this;
    // Listen to message from child window
    eventer(messageEvent,function(e:any) {
       //console.log(e.data);
      var tipo = e.data['type'];
      if (tipo == 'punteggio'){
        //stampa_punti(e.data['punti']);
        // apro la finestra di dialogo inserimento nome!
         th.ins_dati(e.data['punti']);
       }

       /*if (tipo == 'pulsante'){
        th.pulsante_visibile = e.data['visibile'];
       }*/
    },false);

  }


  riceviDati_gioco(){
    this.datiGioco = this.http.ricezioneDati('droplet').subscribe ((data:any) => {
       //console.log(data);
       if (data.length > 0){
        data.sort(function (a:any, b:any) {
          return b.punteggio - a.punteggio;
        });
         this.record = "Record: " + data[0].nome + " con " + data[0].punteggio + " punti";
         this.viualizza_pulsante_classifica = true;
       } else {
        this.record = 'Record: nessun record registrato';
       }
      // this.hide_avviso = true;
     }, (err:any) => {
       console.log(err);
       console.log("Error occured");
       this.openAlert('Problema di connessione. Ricarica la pagina');
        }
     );
  }

 /*openAlert(testo:string) {
    //this.dialog.open(AlertComponent, {data: { testo_messaggio : testo}, autoFocus: true, disableClose : false});
  }*/

  openAlert(testo:string, titolo:string='') {
    this.testo_messaggio = testo;
    this.dialog = true
  }

  ins_dati(punteggio:number){
    this.datiGioco = this.http.ricezioneDati('droplet').subscribe ((data:any) => {
      //console.log(data);
      if (data.length == 10){
       data.sort(function (a:any, b:any) {
         return b.punteggio - a.punteggio;
       });
        if (data[data.length-1].punteggio <= punteggio){
          this.inserisci_nome(punteggio, true);
        } else {
          // non in clssifica
          this.openAlert('Spiacente, non sei entrato in classifica');
        }
      } else {
        this.inserisci_nome(punteggio, false);
      }
    }, (err:any) => {
      console.log(err);
      console.log("Error occured");
      this.openAlert('Problema di connessione. Ricarica la pagina');
       }
    );
  }


  inserisci_nome(punti:number, elimina:boolean){

     const ref = this.dialogService.open(NomeVincitoreComponent, {
      header: "Inserire il nome",
      width: '300px'
     });

     ref.onClose.subscribe((result:any) => {
        if (result !== undefined) {
        if (elimina){
          this.cancella_record_punteggio_minore();
        }
        this.http.aggiungiDato({nome: result, punteggio: punti}, 'droplet');
        this.riceviDati_gioco();
      }
  });


   }

   cancella_record_punteggio_minore(){
    this.datiGioco = this.http.ricezioneDati('droplet').subscribe ((data:any) => {
      //console.log(data);
      if (data.length > 0){
       data.sort(function (a:any, b:any) {
         return a.punteggio - b.punteggio;
       });
       var codice = data[0]._id;
       this.http.eliminaDato(codice, 'droplet');
      }
    }, (err:any) => {
      console.log(err);
      console.log("Error occured");
      this.openAlert('Problema di connessione. Ricarica la pagina');
       }
    );
  }



  nuova_partita(){
    //document.getElementsByTagName("iframe")[0].contentWindow.postMessage('nuova_partita',"*");
  }

  ngOnDestroy():void{
   // clearInterval(this.interval);
   window.removeEventListener("resize", this.resize, false);

  }

 /* sfondo(percorsoImmagine:string){
     var image = new Image();
     image.src = percorsoImmagine;
     var th = this;
     image.onload = function(){
      th.context.drawImage(image, 0, 0);
     }
  }*/

  openDialogClassifica(){
    var dialogConfig = {
      width: '80%',
      header: 'Classifica',
      data: {name: 'droplet'}
    }
    this.ref = this.dialogService.open(ClassificagiochiComponent , dialogConfig);
   }

   openIstruzioni(){
     var dialogConfig = {
      width: '80%',
      header: 'Istruzioni',
      data: {droplet: true}
    }
    this.ref = this.dialogService.open(IstruzionigamesComponent , dialogConfig);
   }

  ngAfterViewInit(){
  /*  this.context = (this.canvasEl.nativeElement as HTMLCanvasElement).getContext('2d');
    this.canvasEl.nativeElement.height = 480;
    this.canvasEl.nativeElement.width = 640;
    this.sfondo('assets/img/sfondo_game_2d.png');
    */
    this.resize();
  //  this.resize('canvas');
  }

  resize() {
    //var canvas = document.querySelector("canvas");
    var canvas = document.querySelector('iframe');
    var windowWidth = window.innerWidth / 1.5;
    var windowHeight = window.innerHeight / 1.5;
    var windowRatio = windowWidth / windowHeight;
    var gameRatio =  800 / 600;
    if (canvas){
        if(windowRatio < gameRatio){
          canvas.style.width = windowWidth + "px";
          canvas.style.height = (windowWidth / gameRatio) + "px";
      }
      else{
          canvas.style.width = (windowHeight * gameRatio) + "px";
          canvas.style.height = windowHeight + "px";
      }

    }

}


}
