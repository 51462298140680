
<div class="groupControl">



  <div *ngIf="current !== 60" style="margin-bottom:20px">
    {{message}}
  </div>

  <div *ngIf="current !== 60" style="margin-bottom:20px; display: flex;align-items: center;">
    <div>code: </div> <div style="font-size:x-large; font-weight: bold;margin-left: 20px;">{{code}}</div>
  </div>


  <div *ngIf="current === 60" style="margin-bottom:20px">
      Questo era l'ultimo livello
  </div>


  <div style="margin-bottom:20px">
    <p-button [style]="{'width': 'fit-content'}" (click)="ref.close()">{{buttonTxt}}</p-button>
  </div>


</div>


