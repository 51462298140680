import { Component, OnInit,Inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-nome-vincitore',
  templateUrl: './nome-vincitore.component.html',
  styleUrls: ['./nome-vincitore.component.scss']
})
export class NomeVincitoreComponent implements OnInit {

  public val = '';
  public valido = true;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  //TODO: chamare questa finestra impostando l'header
  ngOnInit(): void {}

  controlla(testo: string){
    if (testo != ''){
      //Submit disabilitato
      this.valido = false;
    } else {
      //Submit abilitato
      this.valido = true;
    }
  }

}
