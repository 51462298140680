import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
/*import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';*/
import { environment } from 'src/environments/environment';
/*import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';*/


@Injectable({
  providedIn: 'root'
})

export class HttpService {

  public data:any;
  public proxy = "https://cors-anywhere.herokuapp.com/";
  public rawlist: any;

  constructor(private http: HttpClient, public global:Globals) { }


  //private handleError<T>(operation = 'operation', result?: T) {
    //return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
     // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
     // console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      //return of(result as T);
    //};
  //}

  /*getData2(localUrl:string) {
    return this.http.get<any>(localUrl).pipe(
      catchError(this.handleError<any>('getData2', []))
    );;
  }*/

  getData_news(localUrl:string) {
    return this.http.get<any>(localUrl, {observe: 'response'});
  }

  getData2(localUrl:string) {
    return this.http.get<any>(localUrl);
  }

  getData_weather(localUrl:string, key:string, citta:string) {
    return this.http.get<any>(localUrl, {params: {"key": key, "q": citta, "days": "3", "alerts": "yes", "aqi" :"yes"}});
  }


 getInfoVaccinati(url:string): Observable<string>{
 //const api="https://raw.githubusercontent.com/owid/covid-19-data/master/public/data/vaccinations/country_data/Italy.csv"
  return this.http.get( url,{ responseType: 'text' });
}

/*sendMail(testo_messaggio:JSON){
  return this.http.post(environment.mail_form_url, testo_messaggio);
 }*/

 ricezioneDati(name: string): any{
  if (name == 'torre'){
    return this.http.get<any>(environment.sitedb , { headers: {"Accept": "application/json", "Content-Type": "application/json", "x-apikey": environment.xapi, 'cache-control': 'no-cache' } });
  } else
  if (name == 'droplet'){
    return this.http.get<any>(environment.sitedb_droplet , { headers: {"Accept": "application/json", "Content-Type": "application/json", "x-apikey": environment.xapi_droplet, 'cache-control': 'no-cache' } });
  }
}


 aggiungiDato(data: {}, name: string): void{

  let sitodb: string = "";
  let xapidb: string = ""

  if (name == 'torre'){
  sitodb = environment.sitedb;
  xapidb = environment.xapi;
  } else
  if (name == 'droplet'){
    sitodb = environment.sitedb_droplet;
    xapidb = environment.xapi_droplet;
  }

  const req = this.http.post(sitodb , data, { headers: {"Accept": "application/json", "Content-Type": "application/json", "x-apikey": xapidb, 'cache-control': 'no-cache' } })
  .subscribe(
    res => {
      //return res;
      //console.log(res);
    },
    err => {
      console.log(err);
      console.log("Error occured");
    }
   );
 }

 eliminaDato(iddato: string, name: string){

  let sitodb = "";
  let xapidb = "";

  if (name == 'torre'){
    sitodb = environment.sitedb;
     xapidb = environment.xapi;
    } else
    if (name == 'droplet'){
      sitodb = environment.sitedb_droplet;
      xapidb = environment.xapi_droplet;
    }

  this.http.delete<any>(sitodb+"/"+iddato , { headers: {"Accept": "application/json", "Content-Type": "application/json", "x-apikey": xapidb, 'cache-control': 'no-cache' } }).subscribe(data => {
    // console.log(data);
    }, err => {
      console.log(err);
      console.log("Error occured");
      }
    );
 }


 riceviDatoRisparmio(){
  const sitedb = 'https://risparmio-ac8c.restdb.io/rest/money';
  const xapi = '6366a8d5e9a77f59842206e3'
  return this.http.get<any>(sitedb , { headers: {"Accept": "application/json", "Content-Type": "application/json", "x-apikey": xapi, 'cache-control': 'no-cache' } });
 }

 riceviDatoSetting(){
  const sitedb = 'https://rispsetting-9c2a.restdb.io/rest/setting';
  const xapi = '6366ee1de9a77f59842206ed'
  return this.http.get<any>(sitedb , { headers: {"Accept": "application/json", "Content-Type": "application/json", "x-apikey": xapi, 'cache-control': 'no-cache' } });
 }


 aggiungiDatoRisparmio(cifra1:number, cifra2:number,week: number){
      const sitodb = 'https://risparmio-ac8c.restdb.io/rest/money';
      const xapi = '6366a8d5e9a77f59842206e3'
      const req = this.http.post(sitodb , {cifra_1: cifra1, cifra_2: cifra2, settimana: week}, { headers: {"Accept": "application/json", "Content-Type": "application/json", "x-apikey": xapi, 'cache-control': 'no-cache' } })
  .subscribe(
    res => {
      //return res;
      //console.log(res);
    },
    err => {
      console.log(err);
      console.log("Error occured");
    }
   );
 }

 salvaTotaleSettimane(totale:number){
  const sitodb = 'https://rispsetting-9c2a.restdb.io/rest/setting';
  const xapi = '6366ee1de9a77f59842206ed'
  const req = this.http.post(sitodb , {tot_settimane: totale}, { headers: {"Accept": "application/json", "Content-Type": "application/json", "x-apikey": xapi, 'cache-control': 'no-cache' } })
  .subscribe(
    res => {
      //return res;
      //console.log(res);
    },
    err => {
      console.log(err);
      console.log("Error occured");
    }
   );
 }

 /*resetDbRisparmio(idArray:string[]){
  const sitodb = 'https://risparmio-ac8c.restdb.io/rest/money';
  const xapi = '6366a8d5e9a77f59842206e3'

  let dato = idArray[0];
  this.http.delete<any>(sitodb+"/"+dato,{ headers: {"Accept": "application/json", "Content-Type": "application/json", "x-apikey": xapi, 'cache-control': 'no-cache' } }).subscribe(data => {
    // console.log(data);
    }, err => {
      console.log(err);
      console.log("Error occured");
      }
  )
}*/


}
