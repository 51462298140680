<div @fade class='gallery'>

  <div class='titolo'>
      3D GALLERY
  </div>

  <div></div>
 <!-- <div class='percentuale' id='percentuale'></div>-->
 <div class="descrizione"><div>{{descrizione}}</div></div>

      <div *ngIf="inizio" class="canvas_gallery">
        <iframe allowfullscreen webkitallowfullscreen allowtransparency="true" frameborder="0" seamless [src]="linkoggetto">
       </iframe>
      </div>

      <div *ngIf="!inizio" class="canvas_gallery">

     <div style='font-size: medium;
        color:white;display:flex;
        align-items: center;
        justify-content: center;'>
        <div>Per caricare i modelli 3D usare i pulsanti sottostanti<br>To load the 3D models use the buttons below</div>
      </div>

    </div>

 <div></div>


  <div class="selezione">
    <div><p-button label="Bono" (onClick)='caricaOggetto("bono", "Prova testa Bono")'></p-button></div>
    <div><p-button label="Sting" (onClick)='caricaOggetto("sting", "Prova testa Sting")'></p-button></div>
    <div><p-button label="Crepuscolo" (onClick)='caricaOggetto("crepuscolo", "Crepuscolo di Michelangelo (scan 3d)")'></p-button></div>
    <div><p-button label="lifter" (onClick)='caricaOggetto("lifter" , "Modellazione lifter industriale")'></p-button></div>
    <div><p-button label="Fungo" (onClick)='caricaOggetto("fungo", "Carattere per videogioco")'></p-button></div>
  </div>

</div>
