import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-testata',
  templateUrl: './testata.component.html',
  styleUrls: ['./testata.component.scss']
})
export class TestataComponent implements OnInit {

  //items:MenuItem[] = [];

  itemsbg:MenuItem[] = [];

  constructor(private router:Router) {}

  ngOnInit(): void {

    this.itemsbg = [
         {
          label:'Grafica',
          icon:'pi pi-fw pi-palette',
          items:[
            {
              label:'Gallery',
              icon:'pi pi-fw pi-images',
              routerLink: ['image_gallery']
            },
            {
              label:'Gallery 3D',
              icon:'pi pi-fw pi-box',
              routerLink: ['gallery_3d']
            },
          ]
         },
         {
          label:'Servizi',
          icon:'pi pi-fw pi-desktop',
          items:[
            {
              label:'Meteo',
              icon:'pi pi-fw pi-sun',
              routerLink: ['meteo']
            },
            {
              label:'Covid',
              icon:'pi pi-fw pi-exclamation-circle',
              routerLink: ['covid']
            },
            {
              label:'News',
              icon:'pi pi-fw pi-globe',
              routerLink: ['news']
            }
          ]
         },
         {
          label:'Giochi',
          icon:'pi pi-fw pi-circle',
          items:[
            {
              label:'Torre di Hanoi',
              icon:'',
              routerLink: ['torrehanoi']
            },
            {
              label:'Solitario della Bastiglia',
              icon:'',
              routerLink: ['dama_cinese']
            },
            {
              label:'Sokoban',
              icon:'',
              routerLink: ['sokoban']
            },
            {
              label:'The Droplet Game',
              icon:'',
              routerLink: ['dropletgame']
            }
          ]
         },
         {
          label:'Contatti',
          icon:'',
          items:[
            {
              label:'Facebook',
              icon:'pi pi-fw pi-facebook',
              url: 'https://www.facebook.com/gbottega'
            },
            {
              label:'Linkedin',
              icon:'pi pi-fw pi-linkedin',
              url: 'https://www.linkedin.com/in/giampaolo-bottega-414903189/'
            }

          ]
         }
    ];


  }

  vaia(url:string){
    this.router.navigateByUrl(url);
  }



}
