import { Injectable } from '@angular/core';
//const data: {} = require("./conditions.json");
import data from "./conditions.json";

@Injectable()

export class Funzioni_meteo {


  formatta_citta(citta: string){

    var citta_ret = "";
    var vettCit = (citta.trim()).split(" ");

   if (vettCit.length == 2){
    let strRes = ""
    strRes +=  vettCit[0].charAt(0).toUpperCase() + vettCit[0].slice(1) + " ";
    strRes +=  vettCit[1].charAt(0).toUpperCase() + vettCit[1].slice(1);
    citta_ret = strRes.trim();
   } else if (vettCit.length == 3){
     console.log("ok");
    let strRes = ""
    strRes +=  vettCit[0].charAt(0).toUpperCase() + vettCit[0].slice(1) + " ";
    strRes +=  vettCit[1].charAt(0).toLowerCase() + vettCit[1].slice(1) + " "
    strRes +=  vettCit[2].charAt(0).toUpperCase() + vettCit[2].slice(1);
    citta_ret = strRes.trim();
   } else {
    citta_ret = (citta.charAt(0).toUpperCase() + citta.slice(1)).trim();
   }

   return citta_ret

   }

  hasNumber(myString:string) {
    return /\d/.test(myString);
  }

  converti_orario(time12h:string){
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    let hours_int:number = parseInt(hours);
    if (modifier.includes('PM')){
      hours_int = parseInt(hours) + 12;
    }

    return hours_int + ":" + minutes;
  }

  trova_icona(icona:string){
    let ico_array = icona.split("/");
    let ico_path = "assets/" + ico_array[3] + "/" + ico_array[4] + "/" + ico_array[5] + "/" + ico_array[6];
    return ico_path
  }

  traduci(codice:number, is_day:string){
    let newObj = data;
    let traduzione:string = "";

    /// ANGULAR 8 ===
   /*  newObj.forEach(element => {
      if  (element["code"]==codice){
        if (is_day == "0"){
          traduzione = element.languages[0]["night_text"];
        }else{
          traduzione = element.languages[0]["day_text"];
        }
      }
    });*/
    /// ===============

    for (var i = 0; i < newObj.length; i++){
      if (newObj[i].code == codice){
        if (is_day == "0"){
          traduzione = newObj[i].languages[0]["night_text"];
        }else{
          traduzione = newObj[i].languages[0]["day_text"];
        }
     }
   }

    return traduzione;
  }

  calcolo_indice_qualita_aria(vet_dati:number[]):number{
      var indice = 1
      var valore = 0;

      vet_dati[0] = (vet_dati[0]/50)*100;
      vet_dati[1] = (vet_dati[1]/200)*100;
      vet_dati[2] = (vet_dati[2]/120)*100;

      //console.log(vet_dati);

      var i = vet_dati.indexOf(Math.max(...vet_dati));
      valore = vet_dati[i];


      if (valore <= 50){
        indice = 1;
      } else if (valore > 50 && valore <= 75) {
        indice = 2;
      } else if (valore > 75 && valore <= 100) {
        indice = 3;
      } else if (valore > 100 && valore <= 125) {
        indice = 4;
      } else if (valore > 125 && valore <= 150) {
        indice = 5;
      } else if (valore > 150) {
        indice = 6;
      }

      return indice;
  }

}
