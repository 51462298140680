import { Component, OnInit, OnDestroy,  ChangeDetectorRef, AfterViewInit, NgModule, ViewChild} from '@angular/core';
import { Funzioni_solitario } from './funzioni';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader.js';
import { IstruzionigamesComponent } from '../istruzionigames/istruzionigames.component';
import {
  trigger,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
  selector: 'app-solitario',
  templateUrl: './solitario.component.html',
  styleUrls: ['./solitario.component.scss'],
  providers:[DynamicDialogRef,DynamicDialogConfig,Funzioni_solitario],
  animations: [
    // animation triggers go here
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({opacity: 1}))
      ])
    ])
  ]
})
export class SolitarioComponent implements OnInit, OnDestroy, AfterViewInit {

  //public campogioco:Array<number>[] = []
  public campogioco:number[][] = []
  public pezzi_rimasti:number = 0;
  public mosse_rimaste:number = 0;
  public renderer:any;
  public loader = new OBJLoader;
  public mtlLoader:any;
  public scene:any;
  public bgScene:any
  public canvas:any;
  public raycaster = new THREE.Raycaster();
  public mouse = new THREE.Vector2(0,0);
  public camera:any;

  public emissive_pezzo:any;

  public oldCH:number =0;
  public oldCW:number =0;


  public colonna_selezionata:any;
  public pezzo_selezionato:any;
  public pedine_rimaste:any = 0;

  public partita_terminata:boolean=false;

  public audio_ground:any;
  public pedina_sound:any;
  public controls:any;

  public checked = false;
  public root:any;
  public controlli_audio = false;
  public oggetto3D_intercettato:any
  public oggetto_intercettato:string = '';
  public oggetto_precedente:string = '';
  public t:any;
  public dt:any;
  public inc_y:any;
  public stop:boolean = false;
  public sequenza_animazione:boolean[] = [];
  public procedi_animazione: boolean = false;
  public posizioni_arrivo:any[] = [];


  constructor(
      public funzioni:Funzioni_solitario,
      private cd:ChangeDetectorRef,
      public dialogService: DialogService,
      public ref: DynamicDialogRef,
      public config: DynamicDialogConfig
      )

      { }


  ngAfterViewInit(): void {
      this.canvas = document.getElementById('canvas_dama');
      let width = this.canvas.style.width;
      let height = this.canvas.style.height;
      this.canvas.style.width  = '10px';
      this.canvas.style.height  = '10px';
      setTimeout(() => {
        this.canvas.style.height  = height;
        this.canvas.style.width  = width;
      }, 200);
  }


  prepara_sfondo(){
    const loa = new THREE.CubeTextureLoader();
    const texture = loa.load([
      'assets/img/space-cube-Map/px.png',
      'assets/img/space-cube-Map/nx.png',
      'assets/img/space-cube-Map/py.png',
      'assets/img/space-cube-Map/ny.png',
      'assets/img/space-cube-Map/pz.png',
      'assets/img/space-cube-Map/nz.png',
    ]);
      this.scene.background = texture;
 }

 onClick(event:any):void{
  event.preventDefault();
  //console.log(event);
  if (event.type == "click"){
    this.intercetta(event.clientX, event.clientY);
  } else if (event.type=="touchstart"){
    this.intercetta(event.touches[0].clientX, event.touches[0].clientY);
  }
 }

 intercetta(x:number, y:number):void{
  if (!this.partita_terminata){
    var canvas = document.getElementById('canvas_dama');
    let canvasBounds = canvas?.getBoundingClientRect();
    if (canvasBounds != undefined){
        this.mouse.x = ( ( x - canvasBounds.left ) / ( canvasBounds.right - canvasBounds.left ) ) * 2 - 1;
        this.mouse.y = - ( ( y - canvasBounds.top ) / ( canvasBounds.bottom - canvasBounds.top) ) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);
        let result:any = [];
        this.raycaster.intersectObjects(this.scene.children, true, result);
       let intersects:any
       intersects = (result.length ) > 0 ? result[0] : null;
       if (intersects !== null){
          if (intersects != undefined){
            this.oggetto_intercettato = intersects.object.name;
            this.oggetto3D_intercettato = intersects.object;
          }
       }
    }

    //console.log(this.oggetto_intercettato);
    this.gestioneGioco();

  }

 }

 gestioneGioco(): void{

  if (this.oggetto_intercettato != this.oggetto_precedente){
    this.oggetto_precedente = this.oggetto_intercettato;

    if (this.oggetto_intercettato.includes('ped') || this.oggetto_intercettato.includes('col')){

      if (this.oggetto_intercettato.includes('ped')){
       //resetta_selezioni();
        if (this.oggetto3D_intercettato.material.emissive != this.oggetto3D_intercettato.material.color){
          if (this.pezzo_selezionato){
            this.pezzo_selezionato.material.emissive = this.emissive_pezzo;
          }
          var colore = this.oggetto3D_intercettato.material.color;
          this.oggetto3D_intercettato.material.emissive = colore;
          this.oggetto3D_intercettato.material.emissiveIntensity = 8;
          this.pezzo_selezionato = this.oggetto3D_intercettato;
        }

      }

      if (this.oggetto_intercettato.includes('col') && this.pezzo_selezionato){
          // verifico e sposto il pedone
        this.colonna_selezionata = this.oggetto3D_intercettato;
        var valutazione_mossa = [];
        valutazione_mossa = this.funzioni.verifica_mossa(this.campogioco,  this.pezzo_selezionato.name , this.colonna_selezionata.name );
        //console.log(valutazione_mossa);

       if (valutazione_mossa[0]){
          // procedo
           this.campogioco = this.funzioni.muovi_array(this.campogioco, this.pezzo_selezionato.name , this.colonna_selezionata.name, valutazione_mossa);
           this.determina_posizioni();
           this.t = 0;
           this.dt = 0.04;
           this.inc_y = 0;
           this.stop=false;

           this.sequenza_animazione = [true, false, false];
           this.procedi_animazione = true;

         // if (th.procedi_suono){
            this.pedina_sound.play();
         //

          // th.pedinaSound.play();

           this.mosse_rimaste = this.funzioni.dammi_mosse_rimaste(this.campogioco);
           this.pedine_rimaste = this.conto_pedine();

           if (this.mosse_rimaste == 0){
             this.partita_terminata = true;
           }
          // console.log(th.campogioco);
        }

      }

    } else {
      //resetta_selezioni();
    }

   }

 }

 public determina_posizioni():void{

  let nome_pedina_int;

  let nome_pedina = this.pezzo_selezionato.name;
  let nome_colonna = this.colonna_selezionata.name;

  let vettore1 = new THREE.Vector3();
  this.colonna_selezionata.geometry.computeBoundingBox();
  this.colonna_selezionata.geometry.boundingBox.getCenter(vettore1);

  let ped_riga = parseInt((nome_pedina.split('_'))[1]);
  var ped_colonna = parseInt((nome_pedina.split('_'))[2]);

  let col_riga = parseInt((nome_colonna.split('_'))[1]);
  let col_colonna = parseInt((nome_colonna.split('_'))[2]);

  let pedina_int_col!: number;
  if (ped_riga == col_riga){
     if (ped_colonna > col_colonna){
      pedina_int_col = ped_colonna - 1;
     } else {
      pedina_int_col = ped_colonna + 1;
     }
     nome_pedina_int = 'ped_' + ped_riga + '_' + pedina_int_col;
  }


  let pedina_int_riga!: number;
  if (ped_colonna == col_colonna){
    if (ped_riga > col_riga){
     pedina_int_riga = ped_riga - 1;
    } else {
     pedina_int_riga = ped_riga + 1;
    }
    nome_pedina_int = 'ped_' + pedina_int_riga + '_' + ped_colonna;
 }

    const partenza = {x: this.pezzo_selezionato.position.x, y: this.pezzo_selezionato.position.y, z: this.pezzo_selezionato.position.z};
    const arrivo = {x: vettore1.x, y: this.pezzo_selezionato.position.y, z: vettore1.z};

    this.posizioni_arrivo = [];
    this.posizioni_arrivo.push(partenza);
    this.posizioni_arrivo.push(arrivo);
    this.posizioni_arrivo.push('ped_' + col_riga + '_' + col_colonna);
    this.posizioni_arrivo.push(nome_pedina_int);

 //console.log(posizioni_arrivo);
 //vettore1 = null;

}




  ngOnInit(): void {
      this.canvas = document.getElementById('canvas_dama');
      this.audio_ground = new Audio();
      this.audio_ground.src = '../assets/sound/ground_space.mp3';
      this.audio_ground.load();

      this.audio_ground.addEventListener('loadeddata', () => {
        this.controlli_audio = true;
      });
      this.audio_ground.volume = 1.0;
      //this.audio_ground.play();

      this.audio_ground.loop = true;
      this.pedina_sound = new Audio();
      this.pedina_sound.src = '../assets/sound/boop.mp3';
      this.pedina_sound.load();

    //console.log('mosse rimaste:', this.funzioni.dammi_mosse_rimaste(this.campogioco));
     this.prepara_campo();
     this.pedine_rimaste = this.conto_pedine();
     this.mosse_rimaste = this.funzioni.dammi_mosse_rimaste(this.campogioco);
     this.carica_partita();
  }

  ngOnDestroy(): void{
     this.audio_ground.pause();
     this.renderer.forceContextLoss();
  }

  prepara_campo(){
    this.campogioco = [
      [9,9,1,1,1,9,9],
      [9,9,1,1,1,9,9],
      [1,1,1,1,1,1,1],
      [1,1,1,0,1,1,1],
      [1,1,1,1,1,1,1],
      [9,9,1,1,1,9,9],
      [9,9,1,1,1,9,9]
    ]
  }


   openDialog() {
    var dialogConfig = {
      width: '80%',
      header: 'Istruzioni',
      data: {solitario: true}
    };
    this.ref = this.dialogService.open(IstruzionigamesComponent , dialogConfig);
  }

  conto_pedine():number{
    let pedine = 0;
    this.campogioco.forEach(element => {
      element.forEach(riga => {
         if (riga == 1) pedine++;
      });
    });
    return pedine;
  }



  music(e:Event):void{
    if (!this.checked){
       this.cd.detectChanges();
       this.audio_ground.pause();
     } else {
       this.cd.detectChanges();
       this.audio_ground.play();
     }
   }


   clona_matrice():void{
    const th = this;
    const matrice = th.scene.getObjectByName( "matrice" );
    let id_name = 0;
    th.scene.traverse(function(child:any){

      let oggetto = child.type;
      let nome_c:string;
      let ss:any;
      let pedina:any;

      if (oggetto == 'Mesh'){
        if (child.name.includes('col_')){
          child.visible = false;
          nome_c = child.name;
          ss = nome_c.split('_');
        if ( (ss[1] + '_' + ss[2]) != '3_3' ){
          pedina = matrice.clone(false);
          pedina.name = 'ped_' + ss[1] + '_' + ss[2];

          const phongMaterial = new THREE.MeshPhongMaterial;

          phongMaterial.name = 'mat_' + id_name;
          phongMaterial.color = pedina.material.color;
          pedina.material = phongMaterial;
          //phongMaterial = null;
          id_name ++;
           th.scene.add(pedina);
         }
        }
        if (child.name == ('matrice')){
            if (!th.emissive_pezzo){
               th.emissive_pezzo = child.material.emissive;
            }
        }
      }
    });
  }

  posiziona_pezzi():void{
    const th = this;
    th.scene.traverse(function(child:any){
      let oggetto = child.type;
      let nome_c:string;
      let ss:any;

      if (oggetto == 'Mesh'){
        if (child.name.includes('col_')){
           nome_c= child.name;
           ss = nome_c.split('_');

        if ( (ss[1] + '_' + ss[2]) != '3_3' ){
              let pedina = th.scene.getObjectByName( "ped_" + ss[1] + '_' + ss[2] );
              const vettore1 = new THREE.Vector3();
              const vettore2 = new THREE.Vector3();

              child.geometry.computeBoundingBox();
              child.geometry.boundingBox.getCenter(vettore1);
              pedina.geometry.computeBoundingBox();
              pedina.geometry.boundingBox.getCenter(vettore2);
              pedina.position.copy(vettore1);
              pedina.position.y = pedina.position.y - 0.148;
              //vettore1 = null;
              //vettore2 = null;
            }
          }
        }
      });
        const matrice = th.scene.getObjectByName( "matrice" );
        //console.log(matrice);
        const vettore1 = new THREE.Vector3();
        matrice.geometry.computeBoundingBox();
        matrice.geometry.boundingBox.getCenter(vettore1);
        //console.log(vettore1);
        matrice.position.copy(vettore1);
        matrice.position.y = matrice.position.y + 20000;
        //vettore1 = null;
    }

    nuova_partita():void{

      let array_objects:string[] = [];
      this.partita_terminata = false;

       this.scene.traverse(function(child:any){
        let oggetto = child.type;
          if (oggetto == 'Mesh'){
            if (child.name.includes('ped_')){
              array_objects.push(child.name);
            }
          }
      });

      array_objects.forEach(element => {
        let pezzo_intermedio = this.scene.getObjectByName(element);
        let parent = pezzo_intermedio.parent;
        parent.remove( pezzo_intermedio );
        pezzo_intermedio.geometry.dispose();
        pezzo_intermedio.material.dispose();
        pezzo_intermedio = undefined;
      });

      var matrice = this.scene.getObjectByName('matrice');
      matrice.position.x = 0;
      matrice.position.y = 0;
      matrice.position.z = 0;

      this.prepara_campo();
      this.clona_matrice();
      this.posiziona_pezzi();
      this.mosse_rimaste = this.funzioni.dammi_mosse_rimaste(this.campogioco);
      this.pedine_rimaste = this.conto_pedine();
      //xsconsole.log(this.scene);
    }




    carica_partita():void{
      if (this.canvas != null){
        this.canvas.hidden = true;
      }

      this.rimuovi_contenuto_precedente();

      if (this.canvas != null){
        this.canvas.hidden = false;
      }

      if (this.renderer){
        this.renderer.forceContextLoss();
      }

      this.loader = new OBJLoader();
      this.mtlLoader = new MTLLoader();
      this.renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true } );
      this.scene = new THREE.Scene();
      this.bgScene = new THREE.Scene();


      this.prepara_sfondo();
      this.loadObject('damacinese', 2, true, 4, 0.1);
    }


    rimuovi_contenuto_precedente(){
      const list = document.getElementById('canvas_dama');
      if (list){
        if (list.hasChildNodes()) {
          list.removeChild(list.childNodes[0]);
      }
     }
   }

   loadObject(url:string, poszcamera:number, materiale:boolean, int_luce:number, int_lightamb:number){

    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
    this.renderer.setSize( 300, 300 );
    this.renderer.setPixelRatio( window.devicePixelRatio );

    this.camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 10000 );

    const pointLight = new THREE.PointLight( 0xffffff, int_luce );
    pointLight.position.y = 15;
    this.camera.add( pointLight );
    pointLight.position.z = 10;
    pointLight.position.x = 10;

    const light = new THREE.SpotLight(0xcccccc);
    light.castShadow = true;
    light.position.y = 15;
    light.position.x = -3;
    light.position.z = -2;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    this.scene.add(light);

    this.camera.position.set(0, 0, poszcamera);
    var ambientLight = new THREE.AmbientLight( 0xcccccc, int_lightamb );
    this.scene.add( ambientLight );
    this.scene.add( this.camera );
    this.camera.position.y = 7.92233743341375;
    this.camera.position.x = -2.6012635263384304e-16;
    this.camera.position.z =  11.996210634660057;
     /* Vector3 {x: -1.912167723589971e-16, y: 5.823646002097323, z: 8.818317155767993} */


    this.controls = new OrbitControls( this.camera, this.renderer.domElement );
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 1;
    this.controls.enableZoom = true;
    this.controls.enablePan = false;
    this.controls.autoRotate = false;

    this.controls.maxPolarAngle = Math.PI * 0.4;
    this.controls.minDistance = 10;
    this.controls.maxDistance = 20;

    // Mouse =======================
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2(0,0);
    //document.addEventListener("mousemove", onmousemove , false );
    //document.addEventListener("click", onclick , false );
 // ================

       // Caricamento materiale
      if (materiale){
        this.mtlLoader.setPath('assets/3dobject/');
        this.mtlLoader.load(
          url + '.mtl',
          (mtlParseResult:any)=>{
            this.loader.setMaterials(mtlParseResult);
            this.loadmodels(url);
          },
          (progress:any)=>{
              //
          },
          (err:any)=>{
            console.log(err);
          }
        )
      } else {
        this.loadmodels(url);
      }

   }

   loadmodels(url:string){

       // Caricamento oggetto
       this.loader.setPath('assets/3dobject/');
       this.loader.load(
         url + '.obj',
         (object3d:any)=> {

          if (this.canvas){
            this.rimuovi_contenuto_precedente();
            this.canvas.appendChild( this.renderer.domElement );
           }
            this.root = object3d;
          //console.log(root);
           /*var axesHelper = new THREE.AxesHelper( 5 );
          th.scene.add( axesHelper );*/

            this.root.traverse(function(child:any){child.castShadow = true;});
            this.root.traverse(function(child:any){child.receiveShadow = true;});
            this.scene.add(this.root);

            this.clona_matrice();
            this.posiziona_pezzi();
            this.renderer.setClearColor( 0x40214a, 1 );
            this.animate();

         },
         (progress:any)=>{
          if (this.canvas){
            if (this.canvas){
               this.canvas.innerHTML = '' + Math.floor((progress.loaded / progress.total * 100 )) + '% loaded';
            }
            this.cd.detectChanges();
          }
         },
         (err:any) => {
            // error
            console.log(err);
            console.log('errore');
         }
       );

   }

   animate():void {
    this.renderer.setAnimationLoop(() => {
      if (this.renderer.domElement.parentElement){
        var container = this.renderer.domElement.parentElement;
        this.camera.lookAt(this.scene.position);
        this.camera.updateMatrixWorld()

        var box = container.getBoundingClientRect();
        if (box.width != this.oldCH || box.height != this.oldCH){
          var box = container.getBoundingClientRect();
          this.renderer.setSize(box.width - 8, box.height - 8);
          this.renderer.render( this.scene, this.camera );
          this.camera.aspect = box.width/box.height;
          this.camera.lookAt(this.scene.position);
          this.camera.updateProjectionMatrix();
          this.oldCH = box.width;
          this.oldCW = box.height;
        }
        this.controls.update();
      }
      if (this.procedi_animazione){
        this.animazione3d_pezzo();
    }
    });
  }

  public animazione3d_pezzo():void{
    // animazione spostamento pezzo
    // a -> S1 -> i -> S2 -> b
    this.step_anim_pedina(this.posizioni_arrivo[0], this.posizioni_arrivo[1], 0);
}

public step_anim_pedina(a:any, b:any, id_seq:number){

  const newX = this.lerp(a.x, b.x, this.ease(this.t));   // interpolate between a and b where
  const newY = this.lerp(a.y, b.y, this.ease(this.t));   // t is first passed through a easing
  const newZ = this.lerp(a.z, b.z, this.ease(this.t));   // function in this example.


  if(!this.stop){
    if(this.inc_y <= 0.9){
      this.inc_y += 0.075;
    } else {
      this.stop=true;
    }
    }

    if (this.stop){
      this.inc_y -= 0.075;
    }




  // 0.9 massimo
  this.pezzo_selezionato.position.set(newX, newY + this.inc_y, newZ);  // set new position
  //console.log(newY, inc_y, pippo++);

  this.t += this.dt;
  if (this.t <= 0 || this.t >=1) {
   // sequenza_animazione[id_seq] = false;
    this.procedi_animazione = false;
    this.pezzo_selezionato.position.set(b.x, b.y, b.z);
    this.pezzo_selezionato.name = this.posizioni_arrivo[2];

    // cancello il pezzo intermedio
    var pezzo_intermedio = this.scene.getObjectByName(this.posizioni_arrivo[3]);
   var parent = pezzo_intermedio.parent;
   parent.remove( pezzo_intermedio );
   pezzo_intermedio.geometry.dispose();
   pezzo_intermedio.material.dispose();
   pezzo_intermedio = undefined;

    this.resetta_selezioni();
    this.t = 0;
    if (id_seq < 2){
      this.sequenza_animazione[id_seq+1] = true;
    }
   }
}

public lerp(a:number, b:number, t:number):number {return a + (b - a) * t};
public ease(t:number):number { return t<0.5 ? 2*t*t : -1+(4-2*t)*t};

public resetta_selezioni():void{

  if (this.pezzo_selezionato){
    this.pezzo_selezionato.material.emissive = this.emissive_pezzo;
    this.pezzo_selezionato = null;
  }

}



}
