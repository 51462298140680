import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-compass-gauge',
  templateUrl: './compass-gauge.component.html',
  styleUrls: ['./compass-gauge.component.scss']
})
export class CompassGaugeComponent implements OnInit, AfterViewInit {

  private ctx: any;
  @ViewChild('canvasCompass', {static:true}) canvasCompass!: ElementRef;
  @Input() gradi_dir: number = 0;

  constructor() {}

  ngOnInit(): void {
  }

  ngAfterViewInit():void{
   var th = this
   this.ctx = (this.canvasCompass.nativeElement as HTMLCanvasElement).getContext('2d');

     if (this.ctx){
      var background = new Image();
      background.src = "../../../assets/img/compass.png";

      background.onload = function() {
        if (th.ctx){
          th.ctx.drawImage(background, 0, 0);
          th.draw_pointer();
        }
      }
      //console.log(ctx);
     }
  }

  draw_pointer():void{
    var th = this;
    var pointer = new Image();
    pointer.src = "../../../assets/img/compass_pointer.png";
    pointer.onload = function() {
      if (th.ctx){
        th.ctx.save();
        th.ctx.translate(25,25);
        th.ctx.rotate(th.gradi_dir * (Math.PI / 180));
        th.ctx.drawImage(pointer, -25, -25);
        th.ctx.restore();
      }
    }

  }



}
