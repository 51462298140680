<div class='container'>
    <div>
        <span class="p-float-label">
          <input id="float-input" type="text" (ngModelChange)="controlla(val)" [(ngModel)]="val" pInputText>
          <label for="float-input">Nome/Name</label>
       </span>
    </div>

    <div>
      <p-button [disabled]="valido" (onClick)="ref.close(val)">Ok</p-button>
    </div>

</div>
