<p-dialog [header]="titolo"
[(visible)]="dialog"
[modal] = true
>
  <div class="messaggio">{{testo_messaggio}}</div>
</p-dialog>


<div @fade *ngIf="global.hide_covid" class="datiCovid">

  <div class='titolo'>Covid-19 in Italia</div>


  <div class='comptab'>
    <div style="margin:10px">Totale Casi : {{casiTotali}}</div>
   </div>

      <p-table [value]="global.datasource" styleClass="p-datatable-gridlines" responsiveLayout="scroll">
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th>Dato</th>
                <th>Valore</th>
                <th>Differenza</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-covid>
            <tr>
                <td><i
                  (click)='openGrafico(covid.name)'
                   class="pi pi-chart-line b_gr"
                   style="
                   font-size: 1rem;
                   font-weight: bold;
                   "></i>
                </td>
                <td>{{covid.name}}</td>
                <td>{{covid.numero}}</td>
                <td>{{covid.differenza}}</td>
                <td><i
                   *ngIf="covid.name !== 'Tamponi'"
                   [class]="covid.stato"
                   [ngClass]="{ 'valpositivo': covid.classe==='true', 'valnegativo': covid.classe==='false' }"
                   style="
                   font-size: 1rem;
                   font-weight: bold;
                   "
                   ></i></td>
            </tr>
        </ng-template>
      </p-table>

      <div class='comptab'>
        <div class='didascalia'>Dati della Protezione civile</div>
        <div class='didascalia'>Aggiornati a {{ giorno | customDate}}</div>
      </div>

</div>




<div class='avviso' *ngIf="!global.hide_covid">
  <div><p-progressSpinner
    strokeWidth = 5
    ></p-progressSpinner></div>
  <div class='testo_avviso'>LOADING....</div>
</div>
