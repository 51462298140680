import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';


@Component({
  selector: 'app-covid-chart',
  templateUrl: './covid-chart.component.html',
  styleUrls: ['./covid-chart.component.scss']
})

export class CovidChartComponent implements OnInit {

  data: any;
  dataSet: any;
  typeChart = "line";
  public vis_istruzioni: boolean = false;

  lineChartData = [
    { data: [], label: '' },
    { data: [], label: '' }
  ];
  lineChartLabels = [];
  //

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors = [
    {
      borderColor: 'black',
      backgroundColor: '',
    },
    {
      borderColor: 'black',
      backgroundColor: '',
    },

  ];

  lineChartLegend = false;
  lineChartPlugins = [];

  titolo = '';
  limite_records = 50; // default
 // selected = '50';
  title_menu = "Giorni: 50"

  button_items:MenuItem[] = [];



  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.data = this.config.data;
    //console.log(this.data);

    this.button_items = [
      {label: 'giorni: 50', icon: 'pi pi-filter', command: () => {
          this.cambia(50);
      }},
      {separator:true},
      {label: 'giorni: 150', icon: 'pi pi-filter', command: () => {
        this.cambia(150);
      }},
      {separator:true},
      {label: 'giorni: '+ this.data.chartData.length, icon: 'pi pi-filter', command: () => {
        this.cambia('globale');
      }},
  ];


    this.calcola_grafico();
  }

  cambia(valore: string | number | any){
    if (valore != 'globale'){
    this.limite_records = parseInt(valore);
    this.title_menu = "Giorni: "+ valore;
    this.calcola_grafico();
    } else {
      // gloale
      this.limite_records = this.data.tamponi.length;
      //this.title_menu = "Globale";
      this.title_menu = "Giorni: "+ this.data.tamponi.length;
      this.calcola_grafico();
    }
  }

  calcola_grafico(){
    this.titolo = this.data.dato;
    var dati = this.data.chartData.slice((this.data.chartData.length - this.limite_records),this.data.chartData.length);
    var labels = this.data.chartLabels.slice((this.data.chartLabels.length - this.limite_records),this.data.chartLabels.length);
    var tamponi = this.data.tamponi.slice((this.data.tamponi.length - this.limite_records),this.data.tamponi.length);
    var decessi_g = this.data.dec_giorn.slice((this.data.dec_giorn.length - this.limite_records),this.data.dec_giorn.length);
    var ter_g = this.data.ter_giorn.slice((this.data.ter_giorn.length - this.limite_records),this.data.ter_giorn.length);
    this.lineChartData[0].data = dati;
    this.lineChartData[0].label = this.data.dato;
    this.lineChartLabels = labels;
    this.lineChartColors[0].backgroundColor = this.data.chartColor;
    this.vis_istruzioni = false;
    this.typeChart = "line";

    if (this.titolo !== 'Tamponi' && this.titolo !== 'Deceduti' && this.titolo !== 'Terapia intensiva'){
      this.dataSet = {
        labels: labels,
        datasets: [
          {
             label: this.data.dato,
             data: dati,
             tension: .4,
             fill: true,
             backgroundColor: this.data.chartColor
          }
        ]
     }
    }


    if (this.titolo === 'Tamponi'){
      this.dataSet = {
        labels: labels,
        datasets: [
          {
             label: 'Tamponi giornalieri',
             data: tamponi,
             tension: .4,
             fill: true,
             backgroundColor: '#96c0e3'
          },
          {
            label: 'Nuovi positivi',
            data: dati,
            tension: .4,
            fill: true,
            backgroundColor: this.data.chartColor
         },
        ]
     }
     this.vis_istruzioni = true;
     this.typeChart = "bar";
    }


    if (this.titolo === 'Deceduti'){
      this.dataSet = {
        labels: labels,
        datasets: [
          {
             label: 'Decessi totali',
             data: dati,
             tension: .4,
             fill: true,
             backgroundColor: 'rgba(145, 184, 204, 0.5)'
          },
          {
            label: 'Decessi giornalieri',
            data: decessi_g,
            tension: .4,
            fill: true,
            backgroundColor: this.data.chartColor
         },
        ]
     }
     this.vis_istruzioni = true;
     this.typeChart = "line";
    }



     if (this.titolo === 'Terapia intensiva'){
       this.dataSet = {
          labels: labels,
          datasets: [
            {
               label: 'Terapia totali',
               data: dati,
               tension: .4,
               fill: true,
               backgroundColor: 'rgba(183, 199, 121, 0.5)'
            },
            {
              label: 'Terapia giornalieri',
              data: ter_g,
              tension: .4,
              fill: true,
              backgroundColor: this.data.chartColor
           },
          ]
       }
       this.vis_istruzioni = true;
       this.typeChart = "line";
     }
  }


}
