<p-dialog [header]="titolo"
[(visible)]="dialog"
[modal] = true
>
  <div class="messaggio">{{testo_messaggio}}</div>
</p-dialog>


<div >
  <div class='contenitore'>
    <div  style="overflow-y: auto; height: calc(100vh - 15rem);">


<!--<div class='titolo'>Classifica</div>-->

<div *ngIf="hide_table" >

  <p-table [value]="datasource" styleClass="p-datatable-gridlines" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
          <th>Posizione</th>
          <th>Name</th>
          <th>{{nome_campo_punti}}</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-element>
    <tr>
    <td style="text-align: center;color: black;">{{element.posizione}}</td>
    <td style="text-align: center;color: black;">{{element.nome}}</td>
    <td style="text-align: center;color: black;">{{element.punteggio}}</td>
    </tr>
  </ng-template>
  </p-table>

</div>

<div class='avviso' *ngIf="!hide_table">
  <div><p-progressSpinner
    strokeWidth = 5
    ></p-progressSpinner></div>
  <div class='testo_avviso'>LOADING....</div>
</div>

</div>
</div>
</div>



