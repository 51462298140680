import { Component, OnInit, Inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-istruzionigames',
  templateUrl: './istruzionigames.component.html',
  styleUrls: ['./istruzionigames.component.scss']
})
export class IstruzionigamesComponent implements OnInit {

  public hanoi = false;
  public droplet = false;
  public solitario = false

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    const data = this.config.data;
    this.hanoi= data.hanoi;
    this.droplet= data.droplet;
    this.solitario = data.solitario;
  }

}
