import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterNews'
})

export class FilterNewsPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
  }
    if (filter != "agency news"){
      return items.filter(item => item.category.indexOf(filter) !== -1);
    } else {
      return items.filter(item => item.category.length == 0);
    }
  }
}
