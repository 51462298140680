import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Globals } from 'src/app/globals';
//import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  animations: [
    // animation triggers go here
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({opacity: 1}))
      ])
    ])
  ]
})


export class NewsComponent implements OnInit, OnDestroy {

  public categorie:string[] = [];
  //public contenuti:{} = {};
  public index_current = 0;
  public global_data:any[] = [];
  private richiestaDati_att:any;
  private richiestaSearch_att:any;
  public sel: string = "";
  public testo_loading = "LOADING....";
  public hide_news = false;
  public ricerca_in_corso = false;
  public valore_campo_ricerca = "";

  public tot_pagine = 10;
  public numero_pagina = 1;
  public filter="";

  dialog = false;
  titolo='';
  testo_messaggio = ''

  selectedValue!: string;

  //public test_data:any[] = [];

  /*public traduzione = {
    "regional" : "Nazionali",
    "politics" : "Politica",
    "culture"  : "Cultura",
    "economy"  : "Economia",
    "entertainment" : "Intrattenimento",
    "technology": "Tecnologia",
    "legal": "Legali",
    "agency news": "Agency news",
    "finance" : "Finanza",
    "sports" : "Sports"
  }*/

  public traduzione = [
    "regional/nazionali",
    "politics/politica",
    "culture/cultura",
    "economy/economia",
    "entertainment/intrattenimento",
    "technology/tecnologia",
    "legal/legali",
    "agency news/agency news",
    "finance/finanza",
    "sports/sports",
    "general/generali",
    "business/affari",
    "programming/programmazione",
    "science/scienza",
    "world/mondo",
    "academia/universitò",
    "health/salute",
    "opinion/opinioni",
    "food/alimentazione",
    "game/gioco",
    "fashion/moda",
    "travel/viaggi",
    "environment/ambiente",
    "art/arte",
    "music/musica",
    "education/educazione",
    "redundant/eccessivo",
    "television/televisione",
    "commodity/merci",
    "movie/cinema",
    "entrepreneur/imprenditori",
    "review/tecensione",
    "energy/energia",
    "celebrity/celebrità",
    "medical/medicina",
    "gadgets/congegni",
    "design/progettazione",
    "security/sicurezza",
    "estate/patrimonio",
    "funny/divertente",
    "job/lavoro"
  ]

  //"regional","technology","lifestyle","business","general","programming","science","entertainment","world","sports","finance","academia","politics","health","opinion","food","game","fashion","academic","crap","travel","culture","economy","environment","art","music","notsure","CS","education","redundant","television","commodity","movie","entrepreneur","review","auto","energy","celebrity","medical","gadgets","design","EE","security","mobile","estate","funny"

  private apikey:string = "785r6Q8YTsskxAWmkhtDxBkVQ2gk0EENbZuymJJdd2oqMsRx"


  constructor(
    private http: HttpService,
    public global: Globals,
   // private dialog: MatDialog,
    private cdRef: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    //https://api.currentsapi.services/v1/latest-news?language=it&apiKey=785r6Q8YTsskxAWmkhtDxBkVQ2gk0EENbZuymJJdd2oqMsRx

      this.richiesta_dati();
      //this.ricerca_in_corso = true;
     //this.search_article("berlusconi");

  }

  resetScrollPos() {
    var e = document.getElementById("cont");
    e!.scrollTop = 0;
  }

  traduci_eng_ita(titolo:string): string{
    var titolo_ret = titolo;
    this.traduzione.forEach(element => {
       var vet = element.split("/")
       if (vet[0] == titolo){
         titolo_ret = vet[1];
       }
    });
    return titolo_ret.charAt(0).toUpperCase() + titolo_ret.slice(1);
  }

  traduci_ita_eng(titolo:string): string{
    var titolo_ret = titolo;
    this.traduzione.forEach(element => {
       var vet = element.split("/")
       if (vet[1] == titolo.toLowerCase()){
         titolo_ret = vet[0];
       }
    });
    return titolo_ret;
  }

  join_articles(data:any){
    var vet_ret = new Array;
    if (data.length > 1){
      for(let it = 0; it < data.length; it++ ){
        for(let i = 0; i < data[it].length; i++ ){
          vet_ret.push(data[it][i]);
        }
      }
    }
    return vet_ret;
  }

  getTitle(testohtml:string):string{
    testohtml = this.str_replace("Â", "", testohtml);
    testohtml = this.str_replace("â€™", "'", testohtml);
    testohtml = this.str_replace("â€œ", '"', testohtml);
    testohtml = this.str_replace('â€“', '-', testohtml);
    testohtml = this.str_replace('â€', '"', testohtml);
    testohtml = this.str_replace('â', "'", testohtml);
    testohtml = this.str_replace('', ' ', testohtml);
    testohtml = this.str_replace('Ã²', 'à', testohtml);
    testohtml = this.str_replace('Ã¨', 'è', testohtml);
    testohtml = this.str_replace('Ã', 'à', testohtml);
    testohtml = this.str_replace('à¹', 'ù', testohtml);
    testohtml = this.str_replace("'¦", ' ...', testohtml);
    testohtml = this.str_replace("\x80", '€', testohtml);

    return testohtml;
  }

  str_replace(str:string, to_r:string, stringtotal:string){
    let res ="";
    let regex = new RegExp(str,"g");
     res = stringtotal.replace(regex, to_r);
    return res;
  }


  richiesta_dati():void{
    var th = this;
    this.richiestaDati_att = this.http.getData_news("https://api.currentsapi.services/v1/latest-news?language=it&apiKey=" + this.apikey + "&page_number="+this.numero_pagina).subscribe(  data => {
      //console.log(data);
   if (this.numero_pagina == this.tot_pagine){
      //console.log(data.headers.get('X-RateLimit-Remaining'));
     /* const keys = data.headers.keys();
      var headers = keys.map(key =>
        `${key}: ${data.headers.get(key)}`);
      console.log(headers);*/
    }

     /* console.log(data.news);
      this.test_data.push(data.news);
      this.test_data.push(data.news);
      console.log(this.join_articles(this.test_data));*/

       //this.global_data = data.news;
       this.global_data.push(data.body.news);

       for(let i = 0; i < data.body.news.length; i++ ){
         var cat_vet = data.body.news[i].category;
         if (cat_vet.length > 0){
             if (th.categorie.indexOf(this.traduci_eng_ita(cat_vet[0])) == -1){
              th.categorie.push( this.traduci_eng_ita(cat_vet[0]));
             }
          }
       }

       if (this.numero_pagina == this.tot_pagine){
            this.global_data = this.join_articles(this.global_data);
            this.sel =this.traduci_ita_eng(this.categorie[0]);
            this.filter = this.sel;
            if (this.ricerca_in_corso == false){
            this.hide_news = true;
            }
      } else {
        this.numero_pagina++;
        this.richiesta_dati();
      }

    },
    error => {
       console.log(error);
       this.openAlert('Problema di ricezione dati dal server. Ricarica la pagina');

    });
  }


  /* ricerca():void{
    console.log(this.valore_campo_ricerca);
    if (this.valore_campo_ricerca != ""){
      this.ricerca_in_corso = true;
      this.search_article(this.valore_campo_ricerca);
    }
  }*/

  /*search_article(key_search:string): void{

    this.hide_news = false;
    this.testo_loading = "SEARCH....";


    var th = this;
    this.richiestaSearch_att = this.http.getData2("https://api.currentsapi.services/v1/search?language=it&apiKey=" + this.apikey + "&keywords=" + key_search).subscribe(  data => {
        console.log("dati: " + data);

         this.ricerca_in_corso = false;
         this.hide_news = true;

         console.log(typeof(data))

        if (data){

         this.categorie = [];
         this.global_data = data;

       for(let i = 0; i < data.news.length; i++ ){
          var cat_vet = data.news[i].category;

          if (cat_vet.length > 0){

             if (th.categorie.indexOf(cat_vet[0]) == -1){
              th.categorie.push(cat_vet[0]);
             }
          } else if (cat_vet.length == 1) {
            if (th.categorie.indexOf(cat_vet[0]) == -1){
               th.categorie.push(cat_vet[0]);
            }
          } else if (cat_vet.length == []) {
            if (th.categorie.indexOf("agency news") == -1){
              th.categorie.push("agency news");
            }
          }
       }
       this.sel =this.categorie[0];
      } else {
        this.openAlert(this.valore_campo_ricerca + ' non trovato');
        this.hide_news = true;
        this.testo_loading = "LOADING....";
        this.richiesta_dati();
      }

      this.valore_campo_ricerca = "";


    },error => {
      console.log(error);
      this.openAlert('Problema di ricezione dati dal server. Ricarica la pagina');
   });
  }*/

  formattaData(data_str:string):string{
      var valore_ritorno:string = data_str;
      var st = data_str.split(" ");
      var vet_anno = st[0].split("-");
      var anno_correct = vet_anno[2]+"/"+vet_anno[1]+"/"+vet_anno[0];
      var orario = st[1];
      valore_ritorno = anno_correct + " " + "(" + orario + ")";
      return valore_ritorno;
  }

  selezione(index:number){
    this.index_current = index;
    //console.log(index);
  }

  ngOnDestroy():void{
    this.richiestaDati_att?.unsubscribe();
    this.richiestaSearch_att?.unsubscribe();
  }

  openAlert(testo:string, titolo:string='') {
    this.testo_messaggio = testo;
    this.dialog = true;
  }

}
